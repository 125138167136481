import { selectUnreadMessagesCount } from 'defaultLayout/slice/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const useUnreadMessageCounts = (skipPathCheck?: boolean) => {
  const [isChatPage, setIsChatPage] = useState(false)
  const { pathname } = useLocation()
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount)
  useEffect(() => {
    if (pathname.includes('chat') && !skipPathCheck) {
      setIsChatPage(true)
    } else {
      setIsChatPage(false)
    }
  }, [pathname, skipPathCheck])
  return {
    ...unreadMessagesCount,
    total: !isChatPage ? unreadMessagesCount.total : 0,
  }
}
