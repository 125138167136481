// Core
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ar from 'commonI81n/ar.json'
import en from 'commonI81n/en.json'
// Locales
// import {} from './components/locales';

const resources = {
  en: {
    translation: {},
    global: en,
  },
  ar: {
    translation: {},
    global: ar,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
