//Core
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//Actions
import { therapistActions } from '../../../actions/therapistActions'
import { setIsSelectedOpenZoomModal } from '../../../reducers/zoomSlice'
//Styles
import Styles from './styles.module.scss'
//Components
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { NoDataComponent, CustomPagination } from '../../GeneralComponents'
//MUI
import { InputBase, TablePagination, useMediaQuery } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//Utils
import { paginateArray } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'
import { useAlertController } from 'components/AlertMessage/useAlertController'
import { useTranslation } from 'react-i18next'
import { defaultLayoutActions, useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectAllPatients } from './selectors'
import { MyPatientsCardView } from '../Applications/MyPatientsCardView'
import { Box, CenterRow } from 'components/Core'
import { useSearchValues } from 'hooks/useSearchValues'
import { selectTherapistMyPatientsPageSearchValues } from 'defaultLayout/slice/selectors'
import { SearchInput } from 'components/Core/SearchInput'
import { useGetTherapistMyPatientsQuery } from 'defaultLayout/slice/service'

/**
 * Headers for the table
 */
const headers = [
  { text: 'DATE CREATED', sorting: true, key: 'created_at' },
  { text: 'NAME OF PROFILE' },
  { text: 'PROBLEM' },
  { text: 'BIRTHDAY' },
  { text: 'MEETING TIME' },
  { text: 'DETAILS' },
]
/**
 * Patients component for managing therapist patients.
 * @returns {JSX.Element} Patients component.
 */
export const Patients = () => {
  const { setZoomLeaveUrl, dispatch } = useDefaultLayoutSlice()
  const searchValues = useSelector(selectTherapistMyPatientsPageSearchValues)
  const { changePageSize, goToPage, page, pageSize, handleSearchQuery, validSearchValues, handleSorting, setSearchInputValue, searchInputValue } = useSearchValues(
    defaultLayoutActions.changeTherapistMyPatientsSearchValues,
    searchValues,
  )
  const { data, isFetching } = useGetTherapistMyPatientsQuery({
    ...validSearchValues,
  })
  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)

  /**
   * Component state
   */
  const [patients, setPatients] = useState([])

  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Effect to fetch all patients on initial load and when user token changes.
   */
  // useEffect(() => {
  //   const data = {
  //     token: user.token,
  //   }
  //   dispatch(therapistActions.getAllPatients(data))
  // }, [user.token])

  /**
   * Effect to paginate patients when allPatients state changes or currentPage changes.
   */
  // useEffect(() => {
  //   const paginatedData = paginateArray(allPatients)
  //   if (paginatedData.length) {
  //     setPatients([...(paginatedData[currentPage - 1] || 0)])
  //   } else {
  //     setPatients([])
  //   }
  // }, [allPatients, currentPage])

  const navigate = useNavigate()
  const { setAlert } = useAlertController()
  const { t } = useTranslation()

  /**
   * Handles joining a Zoom meeting.
   * @param {string} calcomSessionId - The ID of the Calcom session for Zoom meeting.
   */
  const handleJoinMeeting = (calcomSessionId) => {
    if (calcomSessionId) {
      dispatch(setIsSelectedOpenZoomModal(calcomSessionId))
      setZoomLeaveUrl()
      navigate(`/session?id=${calcomSessionId}`)
    } else {
      notify(t('alertMessages:zoomMeetingNotFound'), true, false)
    }
  }

  /**
   * Click event handlers for various actions.
   */
  const onClickEvents = {
    joinMeeting: handleJoinMeeting,
  }

  /**
   * Handles input change for search.
   * @param {Object} e - The input change event.
   */
  // const handleChangeInput = (e) => {
  //   const inputValue = e.target.value.toLowerCase()

  //   const filteredArray = allPatients.filter((patient) => patient.full_name.toLowerCase().includes(inputValue))
  //   setPatients(filteredArray)
  // }
  const belowMd = useMediaQuery('(max-width: 992px)')
  return (
    <Box px={[3, 3, 20, 20, 40]} py={[24]} className={Styles.main_container}>
      <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
        <h6>My patients</h6>
        <SearchInput
          setSearchInputValue={setSearchInputValue}
          value={searchInputValue}
          sx={{
            width: ['100%', '90%', '400px', '400px'],
            bgcolor: 'white',
          }}
          getValueChange={handleSearchQuery}
          placeholder="Search by name or email"
          size="small"
        />
      </CenterRow>

      <Box minHeight="auto">
        {belowMd ? (
          <MyPatientsCardView isFetching={isFetching} patients={data?.results ?? []} />
        ) : (
          <Table
            isFetching={isFetching}
            sort_by={searchValues.sort_by}
            sort_direction={searchValues.sort_direction}
            onSort={handleSorting}
            headers={headers}
            data={data?.results ?? []}
            userType={user?.user?.user_type}
            userDataType={'patient'}
            onClickEvents={onClickEvents}
          />
        )}
      </Box>
      {!isFetching && !data?.count && <NoDataComponent text={'There are no patients yet.'} />}
      <CenterRow justifyContent="flex-end" width="100%" pr={[0, 0, 50]}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, value) => goToPage(value)}
          onRowsPerPageChange={(event) => {
            changePageSize(+event.target.value)
            goToPage(0)
          }}
        />
      </CenterRow>
    </Box>
  )
}
