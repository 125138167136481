import { Button, CenterColumn, Container, Flex, Text } from 'components/Core'
import { IconWrapper, NoAccessCard } from './CourseSharedComponents'
import { theme } from 'utils/theme'
import { Lock } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

export const DonNotHaveAccessToCourse = () => {
  const navigate = useNavigate()
  return (
    <Container height="calc(100vh - 80px)">
      <Flex p={[3, 3, 3, 4]} alignItems="center" justifyContent="center" height="100%">
        <NoAccessCard initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
          <IconWrapper initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2, type: 'spring' }}>
            <Lock fontSize="large" sx={{ color: theme.colors.primary[600] }} />
          </IconWrapper>

          <CenterColumn gap={'6px'}>
            <Text variant="heading2" mb={3}>
              {t('course:courseAccessErrorTitle')}
            </Text>

            <Text variant="body1" color="textSecondary" mb={6}>
              {t('course:courseAccessErrorDescription')}
            </Text>

            <Flex flexWrap="wrap" mt={8} gap={8} justifyContent="center">
              {/* <Button width={['auto', '250px', 'auto']} variant="primary" onClick={() => window.history.back()}>
                    Purchase Course
                  </Button> */}
              <Button width={['auto', '250px', 'auto']} variant="secondary" onClick={() => navigate('/profile/parent/courses')}>
                {t('course:courseAccessErrorButton')}
              </Button>
            </Flex>
          </CenterColumn>
        </NoAccessCard>
      </Flex>
    </Container>
  )
}
