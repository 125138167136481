import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AdminClientsState } from './type'
import type { SearchValues, User } from 'utils/types/types'
const initialState: AdminClientsState = {
  searchValues: {
    page: 0,
    page_size: 5,
    search_query: '',
    status: '',
    sort_by: '',
    sort_direction: '',
  },
  bookForUser: {},
}
export const advisorApplicationsSlice = createSlice({
  name: 'adminClients',
  initialState,
  reducers: {
    changeSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.searchValues = action.payload
    },
    setBookForUser: (state, action: PayloadAction<Partial<User>>) => {
      state.bookForUser = action.payload
    },
  },
})

export const adminClientsActions = advisorApplicationsSlice.actions
export const adminClientsReducer = advisorApplicationsSlice.reducer
