import Styles from './styles.module.scss'
import { Table } from '../../Applies/Table'
import { Notifications, NoDataComponent } from '../../GeneralComponents'
import { TherapistInfoModal } from '../Therapists/TherapistsInfoModal'
import { useEffect, useState } from 'react'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
import { TablePagination } from '@mui/material'
import { Box, CenterRow } from 'components/Core'
import { useAuth } from 'hooks/useAuth'
import { SearchInput } from 'components/Core/SearchInput'
import { useGetInitialCallsQuery } from 'defaultLayout/slice/service'
import { useSearchValues } from 'hooks/useSearchValues'
import { advisorProfilesActions } from './slice'
import { useSelector } from 'react-redux'
import { selectAdvisorProfilesRefetch, selectAdvisorProfilesSearchValues } from './slice/selector'
const headers = [
  { text: 'DATE CREATED', key: 'created_at', sorting: true },
  { text: 'NAME OF PROFILE', sorting: false },
  { text: 'PHONE', sorting: false },
  { text: 'STATUS', sorting: false },
  { text: 'ASSIGNED THERAPISTS', sorting: false },
  { text: 'THERAPIST APPROVALS', sorting: false },
  { text: 'CLIENT CONFIRMATION', sorting: false },
  { text: 'ADVISOR', sorting: false },
  { text: 'ACTION', sorting: false },
]
/**
 * AdvisorParents component displays a list of parents with various statuses and functionalities to search, paginate, and view details.
 *
 * @component
 * @returns {JSX.Element} The AdvisorParents component.
 */
export const AdvisorParents = () => {
  // const [search_query, setSearchQuery] = useState('')
  const storeSearchValues = useSelector(selectAdvisorProfilesSearchValues)
  const { searchInputValue, searchValues, validSearchValues, page, pageSize, changePageSize, goToPage, setSearchInputValue, handleSelectTab, handleSearchQuery, handleSorting } =
    useSearchValues(advisorProfilesActions.changeSearchValues, storeSearchValues)
  const shouldRefetch = useSelector(selectAdvisorProfilesRefetch)
  const { data, isFetching, refetch } = useGetInitialCallsQuery(validSearchValues, {
    skip: validSearchValues.page === 0,
  })

  useEffect(() => {
    if (shouldRefetch) {
      refetch()
    }
  }, [shouldRefetch])

  // const user = useSelector((state) => state.auth.user)
  const { user: authUser } = useAuth()
  const user = { user: authUser }

  /**
   * State to store the name of the submitted user.
   * @type {string}
   */
  const [submittedUser, setSubmittedUser] = useState('Emily Konstantantinopolska')

  /**
   * State to display notification.
   * @type {boolean}
   */
  const [displayNotification, setDisplayNotification] = useState(false)

  /**
   * State to manage the visibility of the therapist info modal.
   * @type {boolean}
   */
  const [isOpenTherapistInfo, setIsOpenTherapistInfo] = useState(false)

  /**
   * State to store the selected therapist ID.
   * @type {string|undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState(undefined)

  /**
   * Closes the notification.
   */
  const closeNotification = () => {
    setDisplayNotification(false)
  }

  /**
   * Opens the notification.
   *
   * @param {string} userName - The name of the user.
   */
  const openNotification = (userName) => {
    setSubmittedUser(userName)
    setDisplayNotification(true)
  }

  /**
   * Opens the therapist info modal.
   *
   * @param {string} therapistId - The ID of the therapist.
   */
  const handleOpenTherapistInfo = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenTherapistInfo(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsOpenTherapistInfo(false)

  const onClickEvents = {
    openUserDetails: handleOpenTherapistInfo,
  }

  /**
   * Selects the tab.
   *
   * @param {string} tabName - The name of the tab.
   */
  // const handleSelectTab = (tabName) => {
  //   setSelectedTab(tabName)
  // }

  // const { page, goToPage, pageSize, changePageSize } = usePagination({
  //   initialPageSize: 5,
  //   initialPage: 0,
  // })

  return (
    <Box px={[3, 3, 20, 20, 40]} py={[24]} className={Styles.main_container}>
      {displayNotification && (
        <Notifications icon={<TickIcon />} title={'Submitted'} secondaryText={'Therapists were submitted to'} userName={`${submittedUser}.`} onClose={closeNotification} />
      )}
      <div className={Styles.header}>
        <Box gap="8px" flexWrap={['wrap']} className={Styles.header__first_block}>
          <h6>Profiles</h6>
          <div className={Styles.header__search}>
            <SearchInput
              sx={{
                width: ['100%', '90%', '400px', '400px'],
                bgcolor: 'white',
              }}
              getValueChange={handleSearchQuery}
              setSearchInputValue={setSearchInputValue}
              value={searchInputValue}
              placeholder="Search by name or email or phone"
              size="small"
            />
          </div>
        </Box>
        <div className={Styles.tabs}>
          <p className={searchValues.status === '' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('')}>
            All
          </p>
          <p className={searchValues.status === 'waiting' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('waiting')}>
            Waiting for assignment
          </p>
          <p className={searchValues.status === 'active' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('active')}>
            Active
          </p>
        </div>
      </div>

      <Box minHeight="auto" className={Styles.data_container}>
        <Table
          isFetching={isFetching}
          onSort={handleSorting}
          sort_by={searchValues.sort_by}
          sort_direction={searchValues.sort_direction === 'asc'}
          headers={headers}
          data={data?.results ?? []}
          // refetch={setRefetch}
          userType={user.user.user_type}
          userDataType={'parent'}
          showNotification={openNotification}
          onClickEvents={onClickEvents}
        />
      </Box>

      {!data?.count && !isFetching && <NoDataComponent text={'There are no parents yet.'} />}
      {isOpenTherapistInfo && <TherapistInfoModal open={isOpenTherapistInfo} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />}
      <CenterRow width="100%" justifyContent="flex-end" pr={[0, 0, 50]}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, value) => goToPage(value)}
          onRowsPerPageChange={(event) => {
            changePageSize(+event.target.value)
            goToPage(0)
          }}
        />
      </CenterRow>
    </Box>
  )
}
