import { Facebook } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { Button, CenterColumn, CenterRow, Text } from 'components/Core'
import { Direction } from 'components/Core/common/types'
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth'
import { notify } from 'helpers'
import { requestMessages } from 'helpers/requestMessages'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useState } from 'react'
import { GoogleIcon } from 'theme/assets/icons'
import { authInstance } from 'utils/utils'

type Props = {
  label: string
  direction: Direction
  disabled?: boolean
  color?: string
}
export const AuthWithGoogle = ({ label, direction, disabled, color }: Props) => {
  const [loading, setLoading] = useState(false)
  const [facebookLoading, setFacebookLoading] = useState(false)
  const { isArabic } = useGetLanguage()
  return (
    <CenterColumn gap={[10]} direction={direction} width="100%" justifyContent={['center']}>
      <Button
        disabled={disabled}
        type="button"
        onClick={async () => {
          const googleProvider = new GoogleAuthProvider()
          setLoading(true)
          signInWithPopup(authInstance, googleProvider)
            .then((res) => console.log(res))
            .catch((err) => {
              notify(isArabic ? requestMessages?.googleSignInError?.en : requestMessages?.googleSignInError?.ar)
              console.log(err)
            })
            .finally(() => setLoading(false))
        }}
        variant="secondary"
        width={['100%']}
        borderRadius={10}
        style={{
          color,
          borderColor: color,
        }}
      >
        {loading ? <CircularProgress size="25px" /> : <GoogleIcon width="25px" height="25px" />}
        <Text>{label}</Text>
      </Button>
      {/* <Button
        type="button"
        onClick={async () => {
          const facebookProvider = new FacebookAuthProvider()
          facebookProvider.addScope('public_profile')
          facebookProvider.addScope('email')
          setFacebookLoading(true)
          signInWithPopup(authInstance, facebookProvider)
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
            .finally(() => setFacebookLoading(false))
        }}
        variant="secondary"
        width={['100%']}
        borderRadius={10}
      >
        {facebookLoading ? <CircularProgress size="25px" /> : <Facebook color="info" width="25px" height="25px" />}
        <Text>Sign in with facebook</Text>
      </Button> */}
    </CenterColumn>
  )
}
