import Styles from './Styles.module.scss'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../Applies/Table'
import { NoDataComponent } from '../../GeneralComponents'
import { adminActions } from '../../../actions'
import { useAuth } from 'hooks/useAuth'
import { useGetZoomMeetingsQuery } from 'defaultLayout/slice/service'
import { Box, CenterRow, Container, Flex, Text } from 'components/Core'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { SearchInput } from 'components/Core/SearchInput'
import { TablePagination } from '@mui/material'
import { usePagination } from 'hooks/usePagination'
import { selectAdminZoomMeetingsSearchValues } from './slice/selector'
import { useSearchValues } from 'hooks/useSearchValues'
import { adminClientsActions } from '../Parents/slice'

const headers = [
  { text: 'ORGANIZER', sorting: false },
  { text: 'CLIENT', sorting: false },
  { text: 'MEETING START', sorting: true, key: 'start_time' },
  { text: 'MEETING END', sorting: true, key: 'end_time' },
  { text: 'TYPE', sorting: false },
  { text: 'ACTION', sorting: false },
]
/**
 * AdminMeetingsTab component manages and displays Zoom meetings for administrators.
 * @returns {JSX.Element} AdminMeetingsTab component.
 */
export const AdminMeetingsTab = () => {
  const storeSearchValues = useSelector(selectAdminZoomMeetingsSearchValues)
  const { searchInputValue, searchValues, validSearchValues, page, pageSize, changePageSize, goToPage, setSearchInputValue, handleSelectTab, handleSearchQuery, handleSorting } =
    useSearchValues(adminClientsActions.changeSearchValues, storeSearchValues)
  const { data, isFetching } = useGetZoomMeetingsQuery(validSearchValues, { skip: !validSearchValues.page })
  const dispatch = useDispatch()
  const [search_query, setSearchQuery] = useState('')

  // const user = useSelector((state) => state.auth.user)
  const { user } = useAuth()

  /**
   * Dispatches an action to retry a Zoom meeting with specified `zoomId`.
   * @param {string} zoomId - The ID of the Zoom meeting to retry.
   */
  const handleRetryZoomMeeting = (zoomId) => {
    const dataForRequest = {
      id: zoomId,
      fetchMeetings,
    }

    dispatch(adminActions.retryZoomMeeting(dataForRequest))
  }

  const onClickEvents = {
    retryZoomMeeting: handleRetryZoomMeeting,
  }

  return (
    <Container gap={16} py={16} flexDirection="column" justifyContent="flex-start" p={3} mx="auto" width="100%" maxWidth={'1900px'} height="100%">
      <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
        <h6>Zoom meetings</h6>
        <SearchInput
          setSearchInputValue={setSearchInputValue}
          value={searchInputValue}
          sx={{
            width: ['100%', '90%', '400px', '400px'],
            bgcolor: 'white',
          }}
          getValueChange={handleSearchQuery}
          placeholder="Search by name or email"
          size="small"
        />
      </CenterRow>
      <CenterRow width="100%">
        <Box overflowX="scroll" className={Styles.tabs}>
          <Text minWidth="fit-content" className={searchValues.status === '' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('')}>
            Upcoming
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'completed' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('completed')}>
            Past
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'canceled' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('canceled')}>
            Canceled
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'retry' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('retry')}>
            Retry meeting
          </Text>
        </Box>
      </CenterRow>
      <Flex flexDirection="column" flexGrow={1} gap={2} width="100%">
        <div className={Styles.data_container}>
          <Table
            isFetching={isFetching}
            onSort={handleSorting}
            sort_by={searchValues.sort_by}
            sort_direction={searchValues.sort_direction === 'asc'}
            data={data?.results ?? []}
            headers={headers}
            userType={user?.user_type}
            userDataType={'meetings'}
            onClickEvents={onClickEvents}
          />
        </div>

        {!isFetching && !data?.count && <NoDataComponent text={!searchValues.status ? 'No Upcoming Sessions' : 'There are no meetings yet.'} />}

        <CenterRow width="100%" justifyContent="flex-end" pr={[0, 0, 50]}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={data?.count || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(_, value) => goToPage(value)}
            onRowsPerPageChange={(event) => {
              changePageSize(+event.target.value)
              goToPage(0)
            }}
          />
        </CenterRow>
      </Flex>
    </Container>
  )
}
