import { Box } from 'components/Core'
import { backgroundColor } from 'styled-system'

// eslint-disable-next-line no-undef
export const apiPath = process.env.REACT_APP_API_PATH
export const paymnetPath = process.env.REACT_APP_PAYMENT_PATH
export const meetingSdkKey = process.env.REACT_APP_MEETING_SDK_KEY
export const paymentKey = process.env.REACT_APP_PAYMENT_KEY
export const threeDSecure = process.env.REACT_APP_PAYMENT_3D_SECURE
export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK
export const TERMS_OF_USE_LINK = process.env.REACT_APP_TERMS_OF_USE_LINK
export const CALCOM_ADVISOR_CHILD_LINK = process.env.REACT_APP_CAL_COM_LINK_CHILD
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const APPLE_PAY_MERCHANT_ID = process.env.REACT_APP_APPLE_PAY_MERCHANT_ID
export const APPLE_PAY_MERCHANT_DOMAIN = process.env.REACT_APP_APPLE_PAY_MERCHANT_DOMAIN

export const availableChatTypesForUserRole = {
  parent: [
    { value: 'therapist', name: 'therapist' },
    { value: 'advisor', name: 'advisor' },
  ],
  therapist: [
    { value: 'parent', name: 'client' },
    { value: 'advisor', name: 'advisor' },
  ],
  advisor: [
    { value: 'therapist', name: 'therapist' },
    { value: 'parent', name: 'client' },
  ],
  admin: [{ value: 'advisor', name: 'advisor' }],
}

//Regex
export const EMAIL_REGEX = /^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,}$/

export const userTypes = {
  parent: 'parent',
  therapist: 'therapist',
  advisor: 'advisor',
  admin: 'admin',
}

export const screeningType = {
  discovery: 'discovery',
  therapy: 'therapy',
  membership: 'membership',
}
export const JoinableMeetingStatus = ['In progress', 'Planned']

export const mainStatementAnswers = {
  yes: 'Yes',
  no: 'No',
}

export const sessionStatus = {
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Planned: 'Planned',
  'In progress': 'In progress',
  'Pending Payment': 'Pending Payment',
  'Canceled - Payment Canceled': 'Canceled - Payment Canceled',
}

export const OpenSessionNotsWhen = [sessionStatus.Completed]

export const objectiveTypes = {
  QUANTITATIVE: 'Quantitative',
  QUALITATIVE: 'Qualitative',
}

export const objectivesDropDownList = [{ value: objectiveTypes.QUANTITATIVE }, { value: objectiveTypes.QUALITATIVE }]

export const objectiveStatus = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
}

export const objectiveStatusDropDownList = Object.values(objectiveStatus).map((status) => ({ value: status, label: status }))
export const objectiveInitialValue = {
  id: undefined,
  type: '',
  description: '',
  title: '',
  baseline_percentage: undefined,
  target_percentage: undefined,
  is_deleted: false,
}

export const qualitativeSelectOptions = [{ value: 'No Progress' }, { value: 'Minimal Progress' }, { value: 'Some Progress' }, { value: 'Significant Progress' }]
export const goalStatus = {
  ACCOMPLISHED: 'Accomplished',
  NOT_ACCOMPLISHED: 'Not Accomplished',
}

export const goalStatusDropDownList = [
  {
    value: goalStatus.ACCOMPLISHED,
    label: goalStatus.ACCOMPLISHED,
  },
]

export const ParentTaskStatus = {
  TODO: 'To-Do',
  DONE: 'Done',
  INCOMPLETE: 'Incomplete',
}

export const ParentTaskStatusDropDownList = Object.values(ParentTaskStatus).map((status) => ({ value: status }))

export const statusColor = {
  error: { backgroundColor: '#FFE4E4', color: '#E51616' },
  accepted: { backgroundColor: '#F8FFEB', color: '#3B8400' },
  success: { backgroundColor: '#F8FFEB', color: '#3B8400' },
  warning: { backgroundColor: '#FFFBE4', color: '#B19607' },
  waiting: { backgroundColor: '#FFFBE4', color: '#B19607' },
  info: {
    backgroundColor: '#E0F2FE',
    color: '#0C4A6E',
  },
  default: { backgroundColor: '#F6F0FC', color: '#8450a0' },
}

export const dateFilters = {
  last24Hours: 'Last 24 hours',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  last90Days: 'Last 90 days',
  last180Days: 'Last 180 days',
}

export const filterDropDownList = Object.values(dateFilters).map((filter) => ({ value: filter, label: filter }))

export const goalStatusMap = {
  [goalStatus.ACCOMPLISHED]: 'accomplished',
  [goalStatus.NOT_ACCOMPLISHED]: 'notAccomplished',
}

export const objectiveStatusMap = {
  [objectiveStatus.NOT_STARTED]: 'notStarted',
  [objectiveStatus.IN_PROGRESS]: 'inProgress',
  [objectiveStatus.COMPLETED]: 'completed',
}

export const parentTaskStatusMap = {
  [ParentTaskStatus.DONE]: 'done',
  [ParentTaskStatus.TODO]: 'todo',
  [ParentTaskStatus.INCOMPLETE]: 'incomplete',
}

export const qualitativeValueMap = {
  'No Progress': 'noProgressValue',
  'Minimal Progress': 'minimalProgress',
  'Some Progress': 'someProgress',
  'Significant Progress': 'significantProgress',
}

export const globalChipTypeMapper = {
  Done: 'success',
  Completed: 'success',
  Incomplete: 'warning',
  Accomplished: 'success',
  'Not Accomplished': 'warning',
  'In Progress': 'warning',
}

export const supportedLanguages = {
  en: 'English',
  ar: 'Arabic',
}

export const PaymentStatuses = {
  BOOKED: 'BOOKED',
  INITIATED: 'INITIATED',
  CAPTURED: 'CAPTURED',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
  RESERVED: 'RESERVED',
  EXPIRED: 'EXPIRED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  DECLINED: 'DECLINED',
  UNKNOWN: 'UNKNOWN',
}

export const successfulPaymentStatuses = [PaymentStatuses.CAPTURED, PaymentStatuses.COMPLETED, PaymentStatuses.BOOKED]
export const errorPaymentStatuses = [PaymentStatuses.FAILED, PaymentStatuses.DECLINED, PaymentStatuses.UNKNOWN, PaymentStatuses.CANCELED, PaymentStatuses.EXPIRED]

export const tapPaymentStatusCode = {
  '000': PaymentStatuses.CAPTURED,
  '001': PaymentStatuses.RESERVED,
  100: PaymentStatuses.RESERVED,
  200: PaymentStatuses.BOOKED,
  301: PaymentStatuses.EXPIRED,
  302: PaymentStatuses.CANCELED,
  303: PaymentStatuses.EXPIRED,
  304: PaymentStatuses.EXPIRED,
  401: PaymentStatuses.FAILED,
  402: PaymentStatuses.FAILED,
  403: PaymentStatuses.FAILED,
  404: PaymentStatuses.FAILED,
  405: PaymentStatuses.FAILED,
  406: PaymentStatuses.FAILED,
  407: PaymentStatuses.FAILED,
  408: PaymentStatuses.FAILED,
  501: PaymentStatuses.DECLINED,
  502: PaymentStatuses.DECLINED,
  503: PaymentStatuses.DECLINED,
  504: PaymentStatuses.DECLINED,
  505: PaymentStatuses.DECLINED,
  506: PaymentStatuses.DECLINED,
  507: PaymentStatuses.DECLINED,
  508: PaymentStatuses.DECLINED,
  509: PaymentStatuses.DECLINED,
  510: PaymentStatuses.DECLINED,
  511: PaymentStatuses.DECLINED,
  512: PaymentStatuses.DECLINED,
  513: PaymentStatuses.DECLINED,
  514: PaymentStatuses.DECLINED,
  515: PaymentStatuses.DECLINED,
  516: PaymentStatuses.DECLINED,
  601: PaymentStatuses.CANCELED,
  701: PaymentStatuses.UNKNOWN,
  702: PaymentStatuses.UNKNOWN,
  703: PaymentStatuses.UNKNOWN,
  704: PaymentStatuses.UNKNOWN,
  801: PaymentStatuses.EXPIRED,
  901: PaymentStatuses.UNKNOWN,
}
export const TherapistSessionLength = process.env.REACT_APP_THERAPIST_SESSION_LENGTH

export const SubscriptionType = {
  PAY_AS_YOU_GO: 'pay as you go',
  MEMBERSHIP: 'membership',
}

export const imageFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'ico', 'tif', 'tiff']
export const videoFileExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'mkv', '3gp', 'mpeg', 'mpg', 'm4v']
export const audioFileExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'aiff', 'alac', 'ape', 'opus']
export const videoMimeTypes = [
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/x-flv',
  'video/webm',
  'video/x-matroska',
  'video/3gpp',
  'video/mpeg',
  'video/mpeg2',
  'video/x-m4v',
]
