import { useNavigate } from 'react-router-dom'
import Styles from './styles.module.scss'
import { NoDataComponent } from '../../GeneralComponents'
import { TablePagination } from '@mui/material'
import { Table } from '../../Applies/Table'

import { useAuth } from 'hooks/useAuth'
import { Box, CenterRow, Container, Flex } from 'components/Core'
import { SearchInput } from 'components/Core/SearchInput'
import { useGetParentsAdminQuery } from 'defaultLayout/slice/service'
import { useSearchValues } from 'hooks/useSearchValues'
import { adminClientsActions } from './slice'
import { useSelector } from 'react-redux'
import { selectAdminClientsBookForUser, selectAdminClientsSearchValues } from './slice/selector'

const headers = [
  { text: 'Created at', key: 'created_at', sorting: true },
  { text: 'NAME OF CLIENTS', sorting: false },
  { text: 'PHONE NUMBER', sorting: false },
  { text: 'PROFILES', sorting: false },
  { text: 'ADVISOR', sorting: false },
  { text: 'Receive Promo email', sorting: false },
  { text: 'Promo Code', sorting: false },
  { text: 'Promo code used', sorting: false },
  { text: 'Action', sorting: false },
  { text: ' ', sorting: false },
]
/**
 * Renders the ParentsAdmin component which manages and displays a list of parent clients.
 * @returns {JSX.Element} The rendered JSX element representing the ParentsAdmin component.
 */
export const ParentsAdmin = () => {
  const storeSearchValues = useSelector(selectAdminClientsSearchValues)
  const bookForUser = useSelector(selectAdminClientsBookForUser)
  const { searchInputValue, searchValues, validSearchValues, page, pageSize, dispatch, changePageSize, goToPage, setSearchInputValue, handleSearchQuery, handleSorting } =
    useSearchValues(adminClientsActions.changeSearchValues, storeSearchValues)
  const { data, isFetching } = useGetParentsAdminQuery(validSearchValues, { skip: !validSearchValues.page })
  const navigate = useNavigate()
  // const user = useSelector((state) => state.auth.user)
  const { user: authUser } = useAuth()
  const user = { user: authUser }

  /**
   * Navigates to the profile page of the selected child client.
   * @param {string} childId - The ID of the child client to navigate to.
   */
  const handleGoToProfile = (childId) => {
    navigate(`${childId}`)
  }
  const handleBookForUser = (user) => {
    // dispatch(adminClientsActions.setBookForUser(user))
    navigate(`/admin_panel/admin/book-on-behalf/${user.id}`)
  }

  const onClickEvents = {
    goToProfile: handleGoToProfile,
  }
  console.log(bookForUser)
  return (
    <Container gap={16} py={16} flexDirection="column" justifyContent="flex-start" p={3} mx="auto" width="100%" maxWidth={'1900px'} height="100%">
      <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
        <h6>Clients</h6>
        <SearchInput
          sx={{
            width: ['100%', '90%', '400px', '400px'],
            bgcolor: 'white',
          }}
          getValueChange={handleSearchQuery}
          setSearchInputValue={setSearchInputValue}
          value={searchInputValue}
          placeholder="Search by name or email or phone"
          size="small"
        />
      </CenterRow>

      <Flex flexDirection="column" flexGrow={1} gap={2} width="100%">
        <Box minHeight="auto" className={Styles.data_container}>
          <Table
            isFetching={isFetching}
            onSort={handleSorting}
            sort_by={searchValues.sort_by}
            sort_direction={searchValues.sort_direction === 'asc'}
            data={data?.results ?? []}
            headers={headers}
            userType={user?.user?.user_type}
            userDataType={'parent'}
            onClickEvents={onClickEvents}
            handleBookForUser={handleBookForUser}
          />
          {/* <Table headers={headers} data={data?.results} userType={user?.user_type} userDataType={'meetings'} onClickEvents={onClickEvents} /> */}
        </Box>

        {!isFetching && !data?.count && <NoDataComponent text={'There are no meetings yet.'} />}

        <CenterRow width="100%" justifyContent="flex-end" pr={[0, 0, 50]}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={data?.count || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(_, value) => goToPage(value)}
            onRowsPerPageChange={(event) => {
              changePageSize(+event.target.value)
              goToPage(0)
            }}
          />
        </CenterRow>
      </Flex>
    </Container>
  )
}
