import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Box } from 'components/Core'
import { useState, useMemo } from 'react'

type Order = 'asc' | 'desc'

type Header = {
  field: string
  label: string
  isSortable?: boolean
  counter?: boolean
  render?: (value: any) => React.ReactNode | string | number | undefined
}

type Props<T> = {
  isFetching: boolean
  data: T[] | undefined // Generic data type
  headers: Header[]
  page: number
  pageSize: number
  goToPage: (page: number) => void
  changePageSize: (pageSize: number) => void
  onRowClick?: (value: T) => void
}
const getNestedValue = (obj: any, header: Header) => {
  const value = header.field.split('.').reduce((acc, part) => acc && acc[part], obj)
  if (header.render) {
    return header.render(value)
  }
  if (header.counter) {
    return value?.length
  }
  return value
}
export const CommonTable = <T,>({ data, headers, isFetching, changePageSize, goToPage, onRowClick, page, pageSize }: Props<T>) => {
  const [orderBy, setOrderBy] = useState<string | ''>('')
  const [order, setOrder] = useState<Order>('asc')

  const handleSort = (field: string) => {
    const isAsc = orderBy === field && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(field)
  }

  const sortedData = useMemo(() => {
    if (!orderBy) return data
    return [...(data ?? [])].sort((a, b) => {
      const aValue = a[orderBy as keyof T]
      const bValue = b[orderBy as keyof T]
      if (order === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0
      }
    })
  }, [data, order, orderBy])

  return (
    <>
      <Table sx={{ backgroundColor: 'white' }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>#</TableCell>
            {headers.map((header) => (
              <TableCell key={header.field} sx={{ fontWeight: 'bold', backgroundColor: 'white' }}>
                {header.isSortable ? (
                  <TableSortLabel active={orderBy === header.field} direction={orderBy === header.field ? order : 'asc'} onClick={() => handleSort(header.field)}>
                    {header.label}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching ? (
            sortedData?.map((item, index) => (
              <TableRow
                sx={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={() => onRowClick?.(item)}
                key={index}
              >
                <TableCell>{index + 1 + page * pageSize}</TableCell>
                {headers.map((header) => (
                  <TableCell key={header.field} sx={{ fontSize: 16, maxWidth: '300px' }}>
                    {getNestedValue(item, header)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <Box position="absolute" right={0} left={0} margin="auto">
              <Spinner />
            </Box>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={(_, value) => goToPage(value)}
        onRowsPerPageChange={(event) => {
          changePageSize(+event.target.value)
          goToPage(0)
        }}
      />
    </>
  )
}
