import { useEffect } from 'react'

import Cal, { getCalApi } from '@calcom/embed-react'

import { useSelector } from 'react-redux'
import { selectCalComDuration } from 'components/GeneralComponents/CalCom/slice/selectors'
import { v4 as uuid4 } from 'uuid'
import { useAuth } from 'hooks/useAuth'
import { CircularProgress } from '@mui/material'
import { Box } from 'components/Core'

type Props = {
  link: string | undefined
  metadata: object
  onSuccess?: () => void
  subscription_id?: number | string | null
  profile_id?: number | string | null
  name?: string | null
  email?: string | null
  db_email?: string | null
  phone_number?: string | null
}
export const CalcomBookingView = ({ onSuccess, link, metadata, subscription_id, db_email, email, name, phone_number, profile_id }: Props) => {
  const duration = useSelector(selectCalComDuration)
  const { user } = useAuth()
  const current_profile_id = localStorage.getItem('lastSelectedProfile')
  useEffect(() => {
    ;(async function () {
      const cal = await getCalApi()
      cal('ui', { theme: 'light', styles: { branding: { brandColor: '#D89B95' } }, hideEventTypeDetails: false, layout: 'month_view' })
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          e.stopPropagation()
          onSuccess?.()
        },
      })
    })()
  }, [])

  return Object.keys(metadata).length > 0 ? (
    <Box px={[1, 1, 2]} width={['100%']} maxWidth={['100%', '100%', '100%', '80%']}>
      <Cal
        calLink={String(link)}
        style={{
          width: '100%',
          height: '100%',
        }}
        config={{
          layout: 'month_view',
          name: name || user?.full_name || '',
          email: email || user?.email || '',
          'db-email': db_email || user?.email || '',
          'profile-id': String(profile_id || current_profile_id) || '',
          'phone-number': phone_number || '',
          'recording-consent-metadata': JSON.stringify(metadata || {}),
          session_uuid: uuid4(),
          subscription_id: String(subscription_id),
          duration: String(duration),
        }}
        calOrigin="https://numuw.cal.com"
      />
    </Box>
  ) : null
}
