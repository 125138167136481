// Routing
import history from '../lib/history'
// Actions
import { setLoading, setError, clearError } from '../reducers/generalSlice'
import { setUser, setFileUploadToken, setTherapistFullData, setDocumentsForRegistration } from '../reducers/authSlice'
import { setAvatar, setLoadingForSettings } from '../reducers/profileSlice'
// Api
import { settingsApi } from '../api'
// Utils
import { getCroppedImg, returnLanguagesFromArray, setCookie } from '../utils'
import { notify } from 'helpers'
import { requestMessages } from 'helpers/requestMessages'

export const settingsActions = {
  changeUserInfo: (data) => (dispatch, getState) => {
    const language = localStorage.getItem('language')
    dispatch(setLoadingForSettings(true))
    try {
      settingsApi
        .changeUserInfo(data)
        .then((res) => {
          dispatch(setLoadingForSettings(false))
          const { user } = getState().auth
          const updatedUser = {
            user: {
              ...user.user,
              ...res.data,
            },
            profile: {
              ...user.profile,
            },
            token: user.token,
          }
          dispatch(setUser(updatedUser))
          notify(requestMessages.profile_updated[language], false)
        })
        .catch((error) => {
          const message = error?.response?.data?.message
          // console.log('settingsActions.changeUserInfo', error)
          dispatch(setLoadingForSettings(false))
          notify(requestMessages?.[message]?.[language] ?? requestMessages.profile_updated_error[language])
        })
    } catch (error) {
      // console.log('settingsActions.changeUserInfo', error)
      dispatch(setLoadingForSettings(false))
    }
  },
  changeUserLanguages: (data) => (dispatch, getState) => {
    dispatch(setLoadingForSettings(true))
    try {
      settingsApi
        .changeUserLanguages(data)
        .then((res) => {
          dispatch(setLoadingForSettings(false))
          const { user } = getState().auth
          const updatedUser = {
            user: {
              ...user.user,
            },
            profile: {
              ...user.profile,
              languages: res.data?.languages.map((lang) => returnLanguagesFromArray(lang)),
            },
            token: user.token,
          }
          dispatch(setUser(updatedUser))
          const userForCookies = {
            user: {
              id: user.user.id,
              user_type: user.user.user_type,
              is_email_confirmed: user.user.is_email_confirmed,
              timeZone: user.timeZone,
              email: user?.user?.email,
              notification_channels: user?.user?.notification_channels,
            },
            profile: {
              ...user.profile,
              languages: res.data?.languages.map((lang) => returnLanguagesFromArray(lang)),
            },
            token: user.token,
          }

          setCookie('user', JSON.stringify(userForCookies), {
            secure: true,
            samesite: true,
            'max-age': 31536000,
          })
        })
        .catch((error) => {
          // console.log('settingsActions.changeUserInfo', error)
          dispatch(setLoadingForSettings(false))
        })
    } catch (error) {
      // console.log('settingsActions.changeUserInfo', error)
      dispatch(setLoadingForSettings(false))
    }
  },

  cropUserAvatar: (data) => (dispatch) => {
    dispatch(setLoadingForSettings(true))
    getCroppedImg(data)
      .then((res) => {
        dispatch(setAvatar(res))
        dispatch(setLoadingForSettings(false))
      })
      .catch(() => {
        dispatch(setLoadingForSettings(false))
        dispatch(clearError())
        dispatch(setError('Something went wrong, please try again later!'))
      })
  },

  changeUserAvatar: (data) => (dispatch, getState) => {
    dispatch(setLoadingForSettings(true))
    try {
      settingsApi
        .changeUserAvatar(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoadingForSettings(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            data?.refetchUserData?.()
            const { user } = getState().auth
            const updatedUser = {
              user: {
                ...user.user,
                profile_pic: res.profile_pic,
              },
              profile: user.profile,
              token: user.token,
            }
            dispatch(setUser(updatedUser))
            dispatch(setAvatar(''))
            dispatch(clearError())
            dispatch(setLoadingForSettings(false))
          }
        })
        .catch(() => {
          dispatch(setLoadingForSettings(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoadingForSettings(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },

  deleteUserAvatar: (data) => (dispatch, getState) => {
    dispatch(setLoadingForSettings(true))
    try {
      settingsApi
        .deleteUserAvatar(data)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((res) => {
          if (!res) {
            dispatch(setLoadingForSettings(false))
            dispatch(clearError())
            dispatch(setError('Something went wrong, please try again later!'))
          } else {
            const { user } = getState().auth
            const updatedUser = {
              user: {
                ...user.user,
                profile_pic: res.profile_pic,
              },
              profile: user.profile,
              token: user.token,
            }
            dispatch(setUser(updatedUser))
            dispatch(setAvatar(''))
            dispatch(clearError())
            dispatch(setLoadingForSettings(false))
          }
        })
        .catch(() => {
          dispatch(setLoadingForSettings(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    } catch {
      dispatch(setLoadingForSettings(false))
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))
    }
  },
  uploadFiles: (data) => (dispatch) => {
    dispatch(setLoadingForSettings(true))
    try {
      settingsApi
        .uploadFiles(data)
        .then((res) => {
          dispatch(setLoadingForSettings(false))
        })
        .catch((error) => {
          // console.log('settingsActions.uploadFiles', error)
          dispatch(setLoadingForSettings(false))
        })
    } catch (error) {
      // console.log('settingsActions.uploadFiles', error)
      dispatch(setLoadingForSettings(false))
    }
  },
  deleteFiles: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      settingsApi
        .deleteFiles(data)
        .then((res) => {})
        .catch((error) => {
          // console.log('settingsActions.deleteFiles', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('settingsActions.deleteFiles', error)
      dispatch(setLoading(false))
    }
  },
  secureUploadFiles: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      settingsApi
        .secureUploadFiles(data)
        .then((res) => {
          dispatch(setLoading(false))
          dispatch(setFileUploadToken(''))
          dispatch(setTherapistFullData({}))
          dispatch(setDocumentsForRegistration([]))
          history.push('/signup/verify-email')
        })
        .catch((error) => {
          // console.log('settingsActions.secureUploadFiles', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      // console.log('settingsActions.secureUploadFiles', error)
      dispatch(setLoading(false))
    }
  },
}
