import { Popover, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

// Styles
import Styles from './styles.module.scss'
import StylesNewMessage from '../../MessagesHistory/NewMessageBar/styles.module.scss'
// Components
import { ReactComponent as CheckIcon } from '../../../../../theme/assets/icons/check_updated.svg'
import { Spinner } from '../../Spinner'
//Helpers
import { getFormatedLocalTime } from '../../../../../helpers'

//Utils
import { convertBytes, returnContent } from '../../../../../utils'
//Icons
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'
import { IconButton } from '@mui/material'
import { Check, ChevronLeft, DoneAll, Download, ExpandMore } from '@mui/icons-material'
import axios from 'axios'
import { Box, CenterColumn, Flex, Text } from 'components/Core'
import { useDisclosure } from 'hooks/useDisclosure'
import { theme } from 'utils/theme'
import { getMimeType } from 'utils/utils'
import { imageFileExtensions, videoFileExtensions, audioFileExtensions } from 'constants/index'

export const SentMessageInstance = ({ messageInfo, isGroupChat, participants, id }) => {
  const { isViewed, content, timestamp, attachment, message_viewed_by } = messageInfo
  const [isLoading, setIsLoading] = useState(false)
  const groupChatPartiallyViewed = useMemo(() => {
    if (isGroupChat) {
      // partially view if viewer length is not equal to participants lis not equal and we have at leats one viewr
      return message_viewed_by.length > 0 && message_viewed_by.length !== participants.length
    }
    return false
  }, [message_viewed_by, isGroupChat])

  const groupedMessagesViewedByAll = useMemo(() => {
    if (isGroupChat) {
      return message_viewed_by.length === participants.length
    }
    return false
  }, [message_viewed_by, isGroupChat])

  const user = useSelector((state) => state.auth.user)

  const handleOpenFile = async (fileUrl, filename) => {
    try {
      setIsLoading(true)
      await axios(fileUrl, {
        responseType: 'blob',
      })
        .then((response) => {
          const mimeType = getMimeType(filename)
          const blob = new Blob([response.data], { type: mimeType })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
          URL.revokeObjectURL(url)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      console.error('Error downloading file:', error)
      setIsLoading(false)
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (true) {
      case imageFileExtensions.includes(fileType):
        return (
          <Box width="100%" overflow="hidden" borderRadius="12px">
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={filePreview}
            />
          </Box>
        )
      case videoFileExtensions.includes(fileType):
        return (
          <Box width="100%" maxHeight="300px" borderRadius="12px">
            <video
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
                backgroundColor: '#000',
              }}
              controls
              src={filePreview}
            />
          </Box>
        )
      case audioFileExtensions.includes(fileType):
        return (
          <Box width="100%" padding="10px">
            <audio
              style={{
                width: '100%',
                height: '40px',
              }}
              controls
              src={filePreview}
            />
          </Box>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const thumbs = () => {
    return (
      <Box position="relative" width={['100%']} className={StylesNewMessage.preview__item} key={attachment.name}>
        <Box width="100%" className={StylesNewMessage.preview__item__preview}>
          <CenterColumn width="100%">
            <Box>{getPreviewImg(attachment?.extension, attachment?.url)}</Box>
            <div className={StylesNewMessage.preview__item__preview__description}>
              <p className={StylesNewMessage.preview__item__preview__description__name}>{attachment.filename}</p>
              <p className={StylesNewMessage.preview__item__preview__description__size}>{convertBytes(attachment.size)}</p>
            </div>
          </CenterColumn>
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
          }}
          onClick={() => handleOpenFile(attachment.url, attachment.filename)}
          size="small"
        >
          {isLoading ? <Spinner width="30px" /> : <Download fontSize="medium" />}
        </IconButton>
      </Box>
    )
  }

  const formattedTime = timestamp.toLocaleString('en-US', { timeZone: user.timeZone })
  const checkIcon = useMemo(() => {
    if (isGroupChat) {
      if (groupedMessagesViewedByAll) {
        return <DoneAll color="success" fontSize="small" />
      } else if (groupChatPartiallyViewed) {
        return <DoneAll color="disabled" fontSize="small" />
      } else {
        return <Check color="disabled" fontSize="small" />
      }
    } else {
      return isViewed ? <DoneAll color="success" fontSize="small" /> : <Check color="disabled" fontSize="small" />
    }
  }, [groupChatPartiallyViewed, groupedMessagesViewedByAll, isGroupChat, isViewed])

  const { open, onClose, onOpen } = useDisclosure()

  return (
    <Flex id={id} className={Styles.sentMessageInstance}>
      <Flex
        justifyContent="flex-end"
        width={['95%', '95%', '80%', '75%', '65%', '40%']}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        position="relative"
        className={Styles.sentMessageInstance__content}
      >
        <Box width={attachment ? '100%' : 'max-content'}>
          <CenterColumn gap={'4px'} pb={1} className={Styles.sentMessageInstance__content__message} alignItems="flex-end">
            {attachment ? thumbs() : <Text>{returnContent(content)}</Text>}
            {checkIcon}
          </CenterColumn>
        </Box>
        {isGroupChat && open && (
          <Box cursor="pointer" position="absolute" right={0} top={0}>
            <Readers participants={participants} messageInfo={messageInfo} hideExpandMore={onClose} />
          </Box>
        )}
      </Flex>
      <p className={Styles.sentMessageInstance__time}>{getFormatedLocalTime(formattedTime)}</p>
    </Flex>
  )
}

const Readers = ({ participants, messageInfo, hideExpandMore }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    hideExpandMore()
  }
  const readBy = participants?.filter((p) => messageInfo.message_viewed_by?.includes(p.user_id))
  const remaining = participants?.filter((p) => !messageInfo.message_viewed_by?.includes(p.user_id))
  return (
    <>
      <Box onClick={handleClick} cursor="pointer">
        <ExpandMore sx={{ color: theme.colors.black[600] }} fontSize="medium" />
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List>
          <Box p={8}>
            <Text variant="light">Read by</Text>
            {readBy?.map((reader) => (
              <ListItem key={reader.user_id}>
                <ListItemAvatar>
                  <Avatar src={reader.profile_pic} />
                </ListItemAvatar>
                <ListItemText primary={reader.user_name} />
              </ListItem>
            ))}
          </Box>
          <Box p={8}>
            <Text variant="light">Remaining ({remaining?.length ?? 0})</Text>
            {remaining?.map((reader) => (
              <ListItem key={reader.user_id}>
                <ListItemAvatar>
                  <Avatar src={reader.profile_pic} />
                </ListItemAvatar>
                <ListItemText primary={reader.user_name} />
              </ListItem>
            ))}
          </Box>
        </List>
      </Popover>
    </>
  )
}
