import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Styles from './styles.module.scss'
import logo from '../../theme/assets/icons/logo.svg'
import { AdminNav } from '../AdminPanel'
import { ParentNav } from '../ParentFlow'
import { UserSettings } from '../SettingsPopup'
import { TherapistNav } from '../TherapistFlow'
import { UserMenu } from '../UserMenu'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { ReactComponent as ArrowDownIcon } from '../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as ContactSupport } from '../../theme/assets/icons/contact_support_icon.svg'
import { ReactComponent as GlobeIcon } from '../../theme/assets/icons/globe_icon.svg'
import AR_FLAG_IMG from '../../theme/assets/images/ar-flag.png'
import UK_FLAG_IMG from '../../theme/assets/images/uk-flag.png'
import { setHintModalDetails } from '../../reducers/profileSlice'

import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import ar from './locale/ar.json'
import en from './locale/en.json'
import { Box, Button, CenterRow, Container } from 'components/Core'
import { userTypes } from 'constants/index'
import { Badge, IconButton, useMediaQuery } from '@mui/material'
import { IconWorld } from 'theme/assets/icons'
import { stopImpersonation } from 'helpers'
import { useAuth } from 'hooks/useAuth'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { parentActions } from 'actions'
import { useUnreadMessageCounts } from 'hooks/useUnreadMessageCounts'

export const Header = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { userAuthenticated, user } = useAuth()
  useEffect(() => {
    i18n.addResourceBundle('en', 'Header', en)
    i18n.addResourceBundle('ar', 'Header', ar)
  }, [])

  // const [webSocketInstance, setWebSocketInstance] = useState(null)

  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false)

  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false)

  const [preferableLanguage, setPreferableLanguage] = useState('en')

  const dataForLanguages = {
    en: 'English',
    ar: 'Arabic',
  }

  const languagesArray = [
    { abbr: 'en', value: 'English', imageURL: UK_FLAG_IMG },
    { abbr: 'ar', value: 'Arabic', imageURL: AR_FLAG_IMG },
  ]

  useEffect(() => {
    setPreferableLanguage(localStorage.getItem('language'))
    i18n.changeLanguage(searchParams.get('lng') ?? localStorage.getItem('language'))
  }, [searchParams.get('lng')])

  const handleShowHeaderMenu = () => setIsOpenHeaderMenu((prev) => !prev)

  const handleOpenSettings = () => setIsOpenSettingsModal(true)
  const handleCloseSettings = () => setIsOpenSettingsModal(false)

  const location = useLocation()
  const headerMobileRef = useRef(null)

  const getHomePage = () => {
    switch (user?.user_type) {
      case 'therapist':
        return '/profile/therapist/applications'
      case 'parent':
        return '/profile/parent/therapy'
      case 'advisor':
        return '/admin_panel/advisor/applications'
      case 'admin':
        return '/admin_panel/admin/advisors'
      default:
        return '/login'
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerMobileRef.current && !headerMobileRef.current.contains(event.target) && !event.target.closest(`.${Styles.login_mobile}`)) {
        setIsOpenHeaderMenu(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleOpenHintModal = () => {
    dispatch(setHintModalDetails({ client_onboarding_step: '0', isOpen: true }))
  }

  const handleContactSupport = (supportType) => {
    let message
    let phoneNumber = process.env.REACT_APP_CONTACT_SUPPORT_NUMBER

    switch (supportType) {
      case 'base':
        message = process.env.REACT_APP_CONTACT_SUPPORT_MSG
        break
      case 'questionnaire':
        message = process.env.REACT_APP_CONTACT_SUPPORT_MSG_QUESTIONNAIRE
    }
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`, '_blank')
  }

  const handleOpenLanguageSelector = () => {
    setIsLanguageSelectorOpen((prev) => !prev)
  }

  const handleSelectLanguage = (value) => {
    localStorage.setItem('language', value)
    setPreferableLanguage(value)
    setIsLanguageSelectorOpen(false)
    searchParams.set('lng', value)
    setSearchParams(searchParams)
    i18n.changeLanguage(value)
  }
  const impersonated = localStorage.getItem('impersonated')
  // const belowMd = useMediaQuery((theme) => theme.breakpoints.down('md'))
  // const isHomePage = useMemo(() => {
  //   switch (user?.user_type) {
  //     case 'admin':
  //       return location.pathname === '/admin_panel/admin/parents'

  //     case 'advisor':
  //       return location.pathname === '/admin_panel/advisor/applications'

  //     case 'therapist':
  //       return location.pathname === '/profile/therapist/applications'

  //     case 'parent':
  //       return location.pathname === '/profile/parent/therapy'
  //   }
  // }, [user.user_type, location.pathname])
  // const navigate = useNavigate()
  const { allProfiles, assignedAdvisor } = useSelector((state) => state.parent)
  const userHasProfile = useMemo(() => {
    if (user.user_type !== userTypes.parent) true
    if (allProfiles && allProfiles?.length > 0 && assignedAdvisor) return true
    return false
  }, [user.user_type, JSON.stringify(assignedAdvisor), JSON.stringify(allProfiles)])

  useEffect(() => {
    if (user.id && user.user_type === userTypes.parent) {
      dispatch(parentActions.getTherapyInfo())
    }
  }, [user.id])

  const { language, setLanguage } = useGetLanguage()
  const hideDesktopNavbar = useMediaQuery('(max-width: 1100px)')
  const { total } = useUnreadMessageCounts()
  const isChatPage = useMemo(() => location.pathname.includes('/chat'), [location.pathname])
  return (
    <Container zIndex={100} alignItems="flex-start" width="100%" flexDirection="column">
      <header style={{ width: '100%' }} className={Styles.header}>
        <div className={Styles.container}>
          {/* {belowMd && !isHomePage ? (
            <IconButton
              size="medium"
              onClick={() => {
                navigate(-1)
              }}
            >
              <ArrowBack fontSize="large" />
            </IconButton>
          ) : ( */}
          <Link to={getHomePage()} className={Styles.logo_wrap}>
            <Box width={['80px', '80px', 'auto']}>
              <img style={{ width: '100%' }} className={Styles.logo} src={logo} alt="logo image" />
            </Box>
          </Link>
          {/* )} */}
          {userAuthenticated && user?.id && (
            <div className={Styles.login_burger}>
              {/* {user?.user_type === 'parent' && <HelpIcon onClick={handleOpenHintModal} />} */}
              {user?.user_type === 'parent' && <GlobeIcon className={Styles.globe_icon} onClick={() => handleSelectLanguage(preferableLanguage === 'en' ? 'ar' : 'en')} />}
              <Badge badgeContent={isOpenHeaderMenu ? 0 : total ?? 0} color="secondary">
                <div ref={headerMobileRef} className={Styles.menu_bar} onClick={handleShowHeaderMenu}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div key={index} className={Styles.menu_line}></div>
                  ))}
                </div>
              </Badge>
            </div>
          )}
          {isOpenHeaderMenu && (
            <div className={Styles.mobile_content}>
              <div>
                {user?.user_type === 'parent' && userAuthenticated && user?.is_email_confirmed && location.pathname !== '/success' && <ParentNav userHasProfile={userHasProfile} />}
                {user?.user_type === 'therapist' && userAuthenticated && user?.is_email_confirmed && <TherapistNav />}
                {user?.user_type !== 'parent' && user?.user_type !== 'therapist' && userAuthenticated && user?.is_email_confirmed && <AdminNav />}
                <div className={Styles.login_mobile}>
                  {location.pathname !== '/success' && (
                    <div className={Styles.login_wrap}>{userAuthenticated && user?.is_email_confirmed && <UserMenu openSettings={handleOpenSettings} />}</div>
                  )}
                </div>
                {user?.user_type === 'parent' && (
                  <p onClick={() => handleContactSupport('base')} className={Styles.support}>
                    {t('Header:support')}
                  </p>
                )}
              </div>
            </div>
          )}
          {!hideDesktopNavbar && (
            <Container px={[0, 0, 1, !userHasProfile ? 3 : 0]} flexGrow={1} className={Styles.content}>
              {user?.user_type === 'parent' && userAuthenticated && location.pathname !== '/success' ? <ParentNav userHasProfile={userHasProfile} /> : null}
              {user?.user_type === 'therapist' && userAuthenticated && <TherapistNav />}
              {user?.user_type !== 'parent' && user?.user_type !== 'therapist' && userAuthenticated && user?.id && <AdminNav />}
            </Container>
          )}
          <div className={Styles.login}>
            {location.pathname !== '/success' && (
              <div className={Styles.login_wrap}>
                {/* {user?.user_type === 'parent' && <HelpIcon onClick={handleOpenHintModal} />} */}
                {user?.user_type === 'parent' && userAuthenticated && (
                  <div style={{ position: 'relative', width: '150px', height: '40px' }}>
                    <div className={Styles.language}>
                      <div onClick={handleOpenLanguageSelector} className={Styles.language__selected}>
                        <div>
                          <img src={preferableLanguage === 'en' ? UK_FLAG_IMG : AR_FLAG_IMG} />
                          <p>{dataForLanguages[preferableLanguage]}</p>
                        </div>
                        {isLanguageSelectorOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </div>
                      {isLanguageSelectorOpen &&
                        languagesArray
                          ?.filter((item) => item?.abbr !== preferableLanguage)
                          ?.map((item) => (
                            <div onClick={() => handleSelectLanguage(item?.abbr)} key={item?.abbr} className={Styles.language__selected}>
                              <div>
                                <img src={item?.imageURL} />
                                <p>{item?.value}</p>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                )}
                {user?.user_type === 'parent' && userAuthenticated && (
                  <Button py={8} variant="secondary" borderRadius={'8px'} onClick={() => handleContactSupport('base')} className={Styles.support}>
                    <ContactSupport />
                    <p>{t('Header:support')}</p>
                  </Button>
                )}
                {userAuthenticated && user?.id && <UserMenu user={user} openSettings={handleOpenSettings} />}
              </div>
            )}
          </div>
          {location.pathname === '/signup/client' && (
            <CenterRow gap={10}>
              <div sty onClick={() => handleContactSupport('questionnaire')} style={{ direction: searchParams.get('lng') === 'ar' && 'rtl' }} className={Styles.login_wrap__help}>
                <SupportAgentIcon sx={{ color: '#8450A0' }} />
                <p>{t('Header:help')}</p>
              </div>
              <IconButton
                onClick={() => {
                  // Imp toogling between ar and en
                  const lng = language === 'ar' ? 'en' : 'ar'
                  searchParams.set('lng', lng)
                  setSearchParams(searchParams)
                }}
              >
                <IconWorld />
              </IconButton>
            </CenterRow>
          )}
          {location.pathname.includes('login') && (
            <IconButton
              onClick={() => {
                // Imp toogling between ar and en
                const lng = language === 'ar' ? 'en' : 'ar'
                searchParams.set('lng', lng)
                setSearchParams(searchParams)
              }}
            >
              <IconWorld />
            </IconButton>
          )}
          {isOpenSettingsModal && <UserSettings open={isOpenSettingsModal} onClose={handleCloseSettings} />}
          {/* <NotificationSound play={playNotification} setPlayNotification={setPlayNotification} /> */}
        </div>
      </header>
      {impersonated && (
        <CenterRow justifyContent="center" p={2} width="100%" bg="primary.900" px={user?.user_type === userTypes.parent ? [10, 10, 10, 20, 30, 102] : [40]}>
          <Button color="white" fontSize={17} fontWeight="bold" onClick={stopImpersonation} variant="ghost">
            Stop Impersonation
          </Button>
        </CenterRow>
      )}
    </Container>
  )
}
