import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectFirebaseToken, selectUserDetail } from 'defaultLayout/slice/selectors'
import { useGetUserByUidMutation } from 'defaultLayout/slice/service'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'reducers/authSlice'

export const useAuth = () => {
  const firebaseToken = useSelector(selectFirebaseToken)
  const user = useSelector(selectUserDetail)
  const { actions, dispatch } = useDefaultLayoutSlice()
  // Temporary fix for user, becuase all older code uses the auth slice
  useEffect(() => {
    dispatch(setUser({ user }))
    localStorage.setItem('user_type', String(user?.user_type))
  }, [JSON.stringify(user)])
  const [getUserByUid, { data, isLoading: isFetchingUser }] = useGetUserByUidMutation()

  useEffect(() => {
    if (data && !isFetchingUser) {
      dispatch(actions.setUserData(data))
      localStorage.setItem('user_type', String(data.user_type))
    }
  }, [data, isFetchingUser])
  const refetchUser = useCallback(() => {
    getUserByUid(user?.firebase_uid)
  }, [])
  return {
    userAuthenticated: Boolean(firebaseToken),
    token: firebaseToken,
    user,
    nestedUser: { user: user },
    refetchUser,
  }
}
