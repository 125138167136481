import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

type PresignedURLResponse = Array<{
  url: string
  id: string
  file_name: string
  file_type: string
}>
type PresignedURLPayload = {
  file_name: string
  file_type: string
  file_id: string
}
const chatService = rootServiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrl: builder.mutation<PresignedURLResponse, Array<PresignedURLPayload>>({
      query: (data) => ({
        url: apiRoutes.chats.getPreSignedUrl,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useGetPreSignedUrlMutation } = chatService
