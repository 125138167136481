import type { ReactNode } from 'react'
import { CenterRow, Flex, Text } from './Core'
import { theme } from 'utils/theme'
import { Skeleton, SkeletonProps } from '@mui/material'
import { currencyFormatter } from 'utils/utils'
import { t } from 'i18next'
type Props = {
  // children: ReactNode
  onClick?: () => void
  item: {
    duration: number
    price: number
  }
}
export const HourlyPriceCard = ({ item, onClick }: Props) => {
  return (
    <CenterRow
      _hover={{
        backgroundColor: theme.colors.typePurpure[200],
        color: 'white',
        borderColor: 'white',
      }}
      color="typePurpure.300"
      cursor="pointer"
      gap="10px"
      flexDirection="column"
      borderRadius={1}
      boxShadow={'base'}
      justifyContent="center"
      border={2}
      width={135}
      height={104}
      onClick={onClick}
    >
      <Flex gap="5px" alignItems="flex-end">
        <Text fontSize={23} variant="body1" color="inherit" fontWeight={700}>
          {item.duration >= 60
            ? `${Math.floor(item.duration / 60)}${t('global:hours')} ${item.duration % 60 > 0 ? `${item.duration % 60}${t('global:mins')}` : ''}`
            : `${item.duration}${t('global:mins')}`}
        </Text>
      </Flex>
      <Text>
        {currencyFormatter({
          amount: item.price,
        })}
      </Text>
    </CenterRow>
  )
}

interface SkeletonWrapperProps extends SkeletonProps {
  isLoading?: boolean
}
const SkeletonWrapper = ({ children, isLoading }: SkeletonWrapperProps) => {
  return isLoading ? (
    <Skeleton
      sx={{
        width: 135,
        height: 173,
      }}
    />
  ) : (
    children
  )
}
