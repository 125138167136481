import { Container, Tab, Tabs } from '@mui/material'
import { HistoryRecord } from './HistoryRecord'
import { CenterColumn, Text } from 'components/Core'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'utils/types/types'

type Props = {
  history: any[]
  handleOpenPaymentExpiresPopup: () => void
  clientName?: string
  profile_id?: number
  userRole?: string
  parent?: any
  subscription?: Subscription
  calComLink?: string
}
export const SessionsList = ({ history, parent, clientName, profile_id, userRole, subscription, calComLink, handleOpenPaymentExpiresPopup }: Props) => {
  const [activeHistoryTab, setActiveHistoryTab] = useState('upcoming')
  const { t } = useTranslation()
  const filteredHistory = useMemo(() => {
    if (!history?.length) return []

    return history.filter((record: any) => {
      switch (activeHistoryTab) {
        case 'upcoming':
          return ['Planned', 'In progress', 'Pending Payment'].includes(record?.calcom_session?.get_status)
        case 'completed':
          return record?.calcom_session?.get_status === 'Completed'
        case 'canceled':
          return record?.calcom_session?.get_status.toLowerCase().includes('cancel')
        default:
          return true
      }
    })
  }, [history, activeHistoryTab])

  return (
    <CenterColumn gap={16} width="100%">
      <Tabs textColor="secondary" indicatorColor="secondary" value={activeHistoryTab} onChange={(e, value) => setActiveHistoryTab(value)}>
        <Tab label={t('therapy_page:upcoming')} value="upcoming" />
        <Tab label={t('therapy_page:completed')} value="completed" />
        <Tab label={t('therapy_page:canceled')} value="canceled" />
      </Tabs>

      {history?.length ? (
        filteredHistory.length ? (
          filteredHistory.map((record) => (
            <HistoryRecord
              parent={parent ?? {}}
              clientName={clientName ?? record?.therapist?.full_name}
              key={record?.id}
              record={record}
              userRole={userRole ?? 'parent'}
              openPaymentExpiresPopup={handleOpenPaymentExpiresPopup}
              profile_id={profile_id}
              subscription={subscription}
              calComLink={calComLink}
            />
          ))
        ) : (
          <Container>
            <Text>{t('global:noSessionFound')}</Text>
          </Container>
        )
      ) : history?.length === 0 ? (
        <span>{t('global:no_history')}</span>
      ) : (
        <CenterColumn height="100%" gap="5px" alignItems="center">
          <Spinner />
        </CenterColumn>
      )}
    </CenterColumn>
  )
}
