import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Box, Button, CenterColumn, Text } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { Subscription } from 'utils/types/types'

const CardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  z-index: -1;
  background: linear-gradient(135deg, ${theme.colors.black[200]} 0%, ${theme.colors.white} 100%);
`

const PriceCard = styled(CenterColumn)`
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(135deg, ${theme.colors.black[200]} 0%, ${theme.colors.white} 100%);
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  margin-top: 10px;
  text-align: left;
  background: transparent !important;
  box-shadow: none !important;
`

const FeatureItem = styled.li`
  padding: 8px 0;
  display: flex;
  max-width: 360px;
  color: ${theme.colors.textPrimary};
  background-color: transparent;

  &:before {
    content: '✓';
    color: ${theme.colors.typePurpure[100]};
    margin-right: 10px;
  }
`

const Banner = styled(Box)`
  position: absolute;
  top: 20px;
  right: -35px;
  width: 150px;
  background: ${theme.colors.error};
  color: white;
  padding: 8px 0;
  text-align: center;
  transform: rotate(45deg);
  font-size: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 700;
`
interface Props {
  onClose: () => void
  onProceedToPayment: () => void
  subscription: Subscription | undefined
}
export const MembershipCard = ({ onClose, onProceedToPayment, subscription }: Props) => {
  const { t } = useTranslation()
  const { direction } = useGetLanguage()
  const features = t('global:membership:features', { returnObjects: true }) as Array<string>
  return (
    <PriceCard direction={direction} width={['100%', '100%', '460px']} minHeight={['fit-content', 'fit-content', 500]} boxShadow="normal" borderRadius={10} p={4}>
      <CardBackground />
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Text variant="heading2" textAlign="center" mb={2}>
        {t('global:membership:title')}
      </Text>
      <Text variant="body1" textAlign="center" color="textSecondary" mb={4}>
        {t('global:membership:subtitle')}
      </Text>
      <Box my={'16px'}>
        <Text variant="heading1" textAlign="center" color="typePurpure.100">
          ${subscription?.price}
        </Text>
        <Text variant="body2" textAlign="center" color="textSecondary">
          {t('global:membership:priceUnit')}
        </Text>
      </Box>
      <FeatureList>
        {features?.map((feature, index) => (
          <FeatureItem key={index}>{feature}</FeatureItem>
        ))}
      </FeatureList>
      <CenterColumn gap={2}>
        <Button onClick={onProceedToPayment} borderRadius={10} variant="primary" width="100%">
          {t('global:membership:ctaButton')}
        </Button>
      </CenterColumn>
    </PriceCard>
  )
}
