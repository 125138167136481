//Core
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//Actions
import { advisorOnbordingActions } from '../../../actions'
//Utils
import { getInitials, returnCountry } from '../../../utils'
//Styles
import Styles from './styles.module.scss'
//Mui
import { InputBase, TablePagination } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as ArrowUpIcon } from '../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
//Utils
import { sortArray } from '../../../utils'
//Components
import { TherapistInfoModal } from '../../AdvisorFlow/Therapists/TherapistsInfoModal'
import { Box, Button, CenterRow, Chip, Container, Text } from 'components/Core'
import { ScreeningTypeSelectorModal } from './ScreeningTypeSelectorModal'
import { useDisclosure } from 'hooks/useDisclosure'
import { SubscriptionType, userTypes } from 'constants/index'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { SearchInput } from 'components/Core/SearchInput'
import { advisorProfilesActions } from 'components/AdvisorFlow/Parents/slice'

/**
 * AssignTherapists component for assigning therapists to a parent profile.
 *
 * @component
 * @returns {JSX.Element} The AssignTherapists component.
 */
export const ProfileTherapistsBlock = ({
  goToPage,
  page,
  pageSize,
  changePageSize,
  allTherapists,
  assignedTherapists,
  setRefetch,
  handleSearchQuery,
  setSearchInputValue,
  searchInputValue,
  data,
  handleSorting,
  isFetching,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state?.general?.loading)

  const { profileId } = useParams()

  const user = useSelector((state) => state.auth.user)
  const { onOpen, open, onClose } = useDisclosure()
  /**
   * State to store the list of therapists.
   * @type {Array}
   */
  const [therapists, setTherapists] = useState([])
  const [currentTherapist, setCurrentTherapist] = useState()
  const [subscriptionType, setSubscriptionType] = useState('')

  /**
   * State to store the list of therapists to be assigned.
   * @type {Array}
   */
  const [therapistsForAssign, setTherapistsForAssign] = useState([])

  /**
   * State to store the list of therapists assigned earlier.
   * @type {Array}
   */
  const [therapistsAssignedEarlier, setTherapistsAssignedEarlier] = useState([])

  /**
   * State to store the selected therapist ID.
   * @type {string|undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState([])

  /**
   * State to manage sorting order for qualification.
   * @type {boolean}
   */
  const [isQualificationDescending, setIsQualificationDescending] = useState(true)

  /**
   * State to manage sorting order for language.
   * @type {boolean}
   */
  const [isLanguageDescending, setIsLanguageDescending] = useState(true)

  /**
   * State to manage sorting order for time zone.
   * @type {boolean}
   */
  const [isTimeDescending, setIsTimeDescending] = useState(true)

  /**
   * State to manage the visibility of the menu.
   * @type {boolean}
   */
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  /**
   * State to manage the visibility of the therapist details modal.
   * @type {boolean}
   */
  const [isTherapistDetailsOpen, setIsTherapistDetailsOpen] = useState(false)

  /**
   * Update therapists state when allTherapists changes.
   */
  useEffect(() => {
    setTherapists([...allTherapists])
  }, [allTherapists])

  /**
   * Update onboarding questions and previously assigned therapists when the application or type changes.
   */
  useEffect(() => {
    const assignedTherapistsEarlier = assignedTherapists?.map((therapist) => therapist.id)
    if (assignedTherapistsEarlier) {
      setTherapistsAssignedEarlier([...assignedTherapistsEarlier])
    }
  }, [assignedTherapists])

  /**
   * Handles input change for searching therapists.
   *
   * @param {object} e - Event object.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allTherapists.filter((therapist) => therapist.user.full_name.toLowerCase().includes(inputValue))
    setTherapists(filteredArray)
  }

  /**
   * Handles sorting of therapists based on the given type and sort field.
   *
   * @param {boolean} type - Sorting type (ascending/descending).
   * @param {string} sortField - Field to sort by.
   */
  const handleSortQualification = (type, sortField) => {
    switch (sortField) {
      case 'ADVISOR_THERAPIST_QUALIFICATION':
        setIsQualificationDescending((prev) => !prev)
        break
      case 'ADVISOR_THERAPIST_LANGUAGE':
        setIsLanguageDescending((prev) => !prev)
        break
      case 'ADVISOR_THERAPIST_TIMEZONE':
        setIsTimeDescending((prev) => !prev)
        break
    }

    sortArray(type, therapists, sortField)
  }

  /**
   * Checks if a therapist is selected.
   *
   * @param {string} therapist_id - ID of the therapist.
   * @returns {boolean} - Whether the therapist is selected or not.
   */
  const checkIsTherapistSelected = (therapist_id) => {
    const isSelected = therapistsForAssign?.find((assignment) => assignment?.id === therapist_id) || assignedTherapists?.find((assignment) => assignment?.id === therapist_id)
    return isSelected
  }
  const checkAssignedAlready = (therapist_id) => {
    const alreadyAssigned = assignedTherapists?.find((assignment) => assignment?.id === therapist_id)
    return alreadyAssigned
  }

  /**
   * Handles the opening of the therapist menu.
   *
   * @param {string} id - ID of the therapist.
   */
  const handleOpenTherapistMenu = (id) => {
    setSelectedTherapistId(id)
    setIsMenuOpen(true)
  }

  /**
   * Handles the closing of the therapist menu.
   */
  const handleCloseTherapistMenu = () => {
    setIsMenuOpen(false)
  }

  /**
   * Opens the therapist info modal.
   */
  const handleOpenTherapistInfo = (id) => {
    setSelectedTherapistId(id)
    setIsTherapistDetailsOpen(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsTherapistDetailsOpen(false)

  /**
   * Handles the selection of therapists for assignment.
   *
   * @param {string} therapist_id - ID of the therapist.
   */
  const handleSelectTherapistsForAssign = (therapist_id) => {
    const isSelected = !!checkIsTherapistSelected(therapist_id)
    if (isSelected) {
      const newArrayOfTherapists = therapistsForAssign.filter((assignment) => assignment?.id !== therapist_id)
      setTherapistsForAssign([...newArrayOfTherapists])
      setSubscriptionType('')
    } else {
      setCurrentTherapist(therapist_id)
      setTherapistsForAssign((prev) => [...prev, { id: therapist_id }])
      onOpen()
    }
    // setIsMenuOpen(false)
  }
  useEffect(() => {
    setTherapistsForAssign((prev) => {
      return prev.map((therapist) => {
        if (therapist.id === currentTherapist) {
          therapist.type = subscriptionType
        }
        return therapist
      })
    })
  }, [currentTherapist, subscriptionType])
  /**
   * Resets the selection of all therapists.
   */
  const handleResetAllTherapists = () => setTherapistsForAssign([])

  /**
   * Handles the assignment of selected therapists.
   */
  const handleAssignTherapists = () => {
    if (!therapistsForAssign.length) return null

    const data = {
      id: profileId,
      token: user?.token,
      payload: {
        subscription: therapistsForAssign,
      },
    }
    dispatch(advisorOnbordingActions.assignTherapists(data)).then(() => {
      setRefetch((prev) => !prev)
      dispatch(advisorProfilesActions.changeRefetch(true))
      setTherapistsForAssign([])
    })
  }

  const getSubscriptionType = (therapistId) => {
    const therapist = assignedTherapists?.find((therapist) => therapist.id === therapistId) || therapistsForAssign?.find((therapist) => therapist.id === therapistId)
    if (therapist) {
      return therapist?.subscription?.type || therapist?.type
    }
    return ''
  }

  return (
    <Box height="85vh" className={Styles.main_container}>
      <div className={Styles.second_block}>
        <div className={Styles.second_header}>
          <div className={Styles.second_header__hint}>
            <h6>Therapists</h6>
            <span>You can assign a minimum of 1 therapist per person</span>
          </div>
          <div className={Styles.second_header__sorting}>
            <div className={Styles.second_header__sorting__search}>
              <SearchInput
                setSearchInputValue={setSearchInputValue}
                value={searchInputValue}
                sx={{
                  width: ['100%'],
                  bgcolor: 'white',
                }}
                getValueChange={handleSearchQuery}
                placeholder="Search by name or email"
                size="small"
              />
            </div>
            <div className={Styles.second_header__filters}>
              <div
                onClick={() => {
                  handleSortQualification(isQualificationDescending, 'ADVISOR_THERAPIST_QUALIFICATION')
                  handleSorting('professional_qualities', isQualificationDescending ? 'desc' : 'asc')
                }}
                className={Styles.second_header__filters__item}
              >
                Type
                {!isQualificationDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
              <div
                onClick={() => {
                  handleSortQualification(isLanguageDescending, 'ADVISOR_THERAPIST_LANGUAGE')
                  handleSorting('languages', isLanguageDescending ? 'desc' : 'asc')
                }}
                className={Styles.second_header__filters__item}
              >
                Language
                {!isLanguageDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
              <div
                onClick={() => {
                  handleSortQualification(isTimeDescending, 'ADVISOR_THERAPIST_TIMEZONE')
                  handleSorting('user__time_zone', isTimeDescending ? 'desc' : 'asc')
                }}
                className={Styles.second_header__filters__item}
              >
                Timezone
                {!isTimeDescending ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </div>
            </div>
          </div>
        </div>
        <Box overflowY="auto" className={Styles.second_content_block}>
          {isFetching ? (
            <>
              <CenterRow width="100%" minHeight={'380px'} justifyContent="center" bg={'rgba(0,0,0,0.2)'} gap={2}>
                <Spinner />
                <Text>Loading...</Text>
              </CenterRow>
            </>
          ) : therapists?.length ? (
            therapists?.map((therapist) => {
              return (
                <div key={therapist.id} className={Styles.therapist_block}>
                  <div className={Styles.therapist_card}>
                    <div className={Styles.therapist_card__left}>
                      <div className={Styles.therapist_card__initials}>
                        {checkIsTherapistSelected(therapist?.id) ? (
                          <TickIcon />
                        ) : therapist?.user?.profile_pic ? (
                          <img src={therapist?.user?.profile_pic} />
                        ) : (
                          getInitials(therapist?.user?.full_name)
                        )}
                      </div>
                      <div className={Styles.therapist_card__personal_info}>
                        <p>{therapist?.user?.full_name}</p>
                        <span>{therapist?.professional_qualities}</span>
                      </div>
                    </div>
                    {isMenuOpen && selectedTherapistId === therapist?.id ? (
                      <ArrowUpIcon onClick={handleCloseTherapistMenu} className={Styles.cursor} />
                    ) : (
                      <ArrowDownIcon onClick={() => handleOpenTherapistMenu(therapist?.id)} className={Styles.cursor} />
                    )}
                  </div>
                  {isMenuOpen && selectedTherapistId === therapist?.id && (
                    <div className={Styles.therapist_menu_block}>
                      {getSubscriptionType(therapist?.id) && (
                        <Chip
                          fontWeight="700"
                          type={getSubscriptionType(therapist?.id) === SubscriptionType.PAY_AS_YOU_GO ? 'secondary' : 'default'}
                          textTransform="capitalize"
                          value={getSubscriptionType(therapist?.id)}
                        />
                      )}
                      <div className={Styles.therapist_menu_block__personal_info}>
                        <div className={Styles.therapist_menu_block__personal_info__item}>
                          <span>Years experience</span>
                          <p>{therapist?.experience}</p>
                        </div>
                        <div className={Styles.therapist_menu_block__personal_info__item}>
                          <span>Language</span>
                          <p>{therapist?.languages?.join(', ')}</p>
                        </div>
                        <div className={Styles.therapist_menu_block__personal_info__item}>
                          <span>Country</span>
                          <p>{returnCountry(therapist?.user?.country)}</p>
                        </div>
                        <div className={Styles.therapist_menu_block__personal_info__item}>
                          <span>Timezone</span>
                          <p>{therapist?.user?.experience || '-'}</p>
                        </div>
                      </div>
                      <div className={Styles.therapist_menu_block__footer}>
                        <div
                          onClick={() => {
                            handleOpenTherapistInfo(therapist?.id)
                          }}
                          className={Styles.therapist_menu_block__footer__details_btn}
                        >
                          Details
                        </div>
                        {!checkAssignedAlready(therapist?.id) && (
                          <Button onClick={() => handleSelectTherapistsForAssign(therapist?.id)}>{checkIsTherapistSelected(therapist.id) ? 'Reset' : 'Assign'}</Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            })
          ) : (
            <Box p={4}>
              <Text>No Therapists Found</Text>
            </Box>
          )}
        </Box>
        <CenterRow justifyContent="flex-end" width="100%">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={data?.count || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(_, value) => goToPage(value)}
            onRowsPerPageChange={(event) => {
              changePageSize(+event.target.value)
              goToPage(0)
            }}
          />
        </CenterRow>
        <div className={Styles.second_footer}>
          <div onClick={handleResetAllTherapists} className={therapistsForAssign.length ? Styles.second_footer__reset_btn : Styles.second_footer__reset_btn_disabled}>
            Reset all
          </div>
          <Button minWidth={100} gap={8} disabled={!therapistsForAssign.length || isLoading} onClick={handleAssignTherapists}>
            {isLoading ? <Spinner width="25px" /> : 'Submit'}
          </Button>
        </div>
      </div>
      {isTherapistDetailsOpen && (
        <TherapistInfoModal open={isTherapistDetailsOpen} userType={userTypes.advisor} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />
      )}
      <ScreeningTypeSelectorModal
        open={open}
        onClose={onClose}
        onSelect={(value) => {
          onClose()
          setSubscriptionType(value)
        }}
      />
    </Box>
  )
}
