import { apiRoutes } from 'apiRoutes'
import { userTypes } from 'constants/index'
import { rootServiceApi } from 'store/service'
import { ChatsData } from 'utils/types/types'

const chatServices = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getChannels: build.query<ChatsData, { profile_id?: string | undefined; userType: string | undefined }>({
      query: ({ profile_id, userType }) => ({
        method: 'GET',
        url: userType === userTypes.parent ? `${apiRoutes.chats.getParentChannels}${profile_id}/` : apiRoutes.chats.getOtherUsersChannels,
      }),
      providesTags: ['get-chat-rooms'],
    }),
    viewMessages: build.mutation<any, { chatId: number; un_viewed_messages: Array<number>; is_group_chat: boolean }>({
      query: ({ chatId, ...data }) => ({
        method: 'PUT',
        url: `chats/${chatId}/viewed/`,
        data,
      }),
    }),
  }),
})

export const { useGetChannelsQuery, useViewMessagesMutation } = chatServices
