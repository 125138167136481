import { Box, Button, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import { CalcomBookingView } from 'components/ParentFlow/Therapy/NewParentOnboarding/CalcomBookingView'
import { RecordingConsent } from 'components/ParentFlow/Therapy/NewParentOnboarding/RecordingConsent/RecordingConsent'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useState, useMemo, useEffect } from 'react'
import { useGetScreeningQuery, useGetTherapistQuery } from './service'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FlowContainer } from 'components/ParentFlow/Therapy/NewParentOnboarding/FlowContainer'
import { useGetSessionPriceQuery } from 'components/ParentFlow/Therapy/service'
import { HourlyPriceCard } from 'components/HourlyPriceCard'
import { calComActions } from 'components/GeneralComponents/CalCom/slice'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import { IconButton } from '@mui/material'
import { ArrowBack, CalendarToday, Close, Refresh } from '@mui/icons-material'
import { AcceptTherapist } from './AcceptTherapist'
import { t } from 'i18next'
import { useDisclosure } from 'hooks/useDisclosure'
import { theme } from 'utils/theme'

import { useAuth } from 'hooks/useAuth'

export const BookingTherapy = () => {
  const [openCalCom, setOpenCalCom] = useState(false)

  const [metadata, setMetaData] = useState({})
  const { user } = useAuth()
  const navigate = useNavigate()
  const { direction } = useGetLanguage()
  const [searchParams] = useSearchParams()
  const sessionLengthPriceController = useDisclosure()
  const bookNextSessionController = useDisclosure()

  const profileId = searchParams.get('profileId')
  const isDiscoveryCall = useMemo(() => searchParams.get('isDiscoveryCall') === 'true', [searchParams.get('isDiscoveryCall')])
  const {
    data: therapist,
    isLoading,
    error,
    refetch: refetchTherapist,
  } = useGetTherapistQuery({
    therapistId: searchParams.get('therapistId'),
    profileId,
  })
  const {
    data: sessionPrices,
    error: priceListError,
    refetch: refetchSessionPricing,
  } = useGetSessionPriceQuery({
    therapist_id: searchParams.get('therapistId'),
  })
  const {
    data: screening,
    isError: screeningError,
    refetch: refetchScreenings,
  } = useGetScreeningQuery(
    {
      screeningId: searchParams.get('screeningId'),
    },
    {
      skip: !searchParams.get('screeningId'),
    },
  )

  const dispatch = useDispatch()
  const isError = useMemo(() => error || screeningError || priceListError, [screeningError, priceListError, error])
  useEffect(() => {
    if (screening?.parent_status === 'accepted' && isDiscoveryCall) {
      bookNextSessionController.onOpen()
    } else {
      bookNextSessionController.onClose()
    }
  }, [screening?.parent_status, isDiscoveryCall])

  return (
    <Container direction={direction} width="100%" minHeight="100%" flexDirection="column">
      {isError ? (
        <Container flexDirection="column" gap={16}>
          {/* Refetch on error */}
          <Text maxWidth="100%" variant="heading4">
            {t('global:bookingTherapy:somethingWentWrong')}
          </Text>
          <Button
            onClick={() => {
              if (screeningError) {
                refetchScreenings()
              }
              if (priceListError) {
                refetchSessionPricing()
              }
              if (error) {
                refetchTherapist()
              }
            }}
          >
            <Refresh />
            <Text>{t('global:refresh')}</Text>
          </Button>
        </Container>
      ) : (
        <FlowContainer
          minHeight="90vh"
          justifyContent={openCalCom ? 'flex-start' : 'center'}
          position="relative"
          pr={0}
          py={40}
          alignItems="center"
          width={['100%', '100%', '100%', '90%']}
        >
          {openCalCom ? (
            <>
              <CenterRow flexDirection={['column']} gap={10} px={[1, 1, 2]} maxWidth={['100%', '100%', '100%', '100%', '90%', '63%']}>
                <RecordingConsent
                  sub_text={t('global:recordingConsent:sub_text')}
                  main_text={t('global:recordingConsent:main_text')}
                  title={t('global:bookYourTherapySession')}
                  direction={direction}
                  setMetaData={setMetaData}
                />
              </CenterRow>
              <CalcomBookingView
                onSuccess={() => {
                  setTimeout(() => {
                    navigate(`/session-payment-page?therapistId=${therapist?.id}`)
                  }, 2000)
                }}
                metadata={metadata}
                link={therapist?.regular_link as string}
              />
            </>
          ) : isDiscoveryCall && !bookNextSessionController.open ? (
            !sessionLengthPriceController.open && (
              <AcceptTherapist
                therapist={therapist}
                onAccept={() => {
                  refetchScreenings()
                }}
                fetchingInfo={isLoading}
                screeningId={searchParams.get('screeningId')}
                direction={direction}
              />
            )
          ) : (
            !sessionLengthPriceController.open && (
              <Container flexDirection="column" gap={24}>
                <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.4 }}>
                  <CenterColumn>
                    <Text variant="heading3" color={theme.colors.black[600]} textAlign="center">
                      {isDiscoveryCall ? t('global:bookYourFirstSession') : t('global:bookYourNextSession')}
                    </Text>
                  </CenterColumn>
                </motion.div>

                <CenterColumn alignItems="center" gap={3} width="100%" maxWidth="400px" mx="auto">
                  <Button onClick={sessionLengthPriceController.onOpen} variant="primary" width="100%" height="48px" fontSize="16px">
                    <CalendarToday />
                    {isDiscoveryCall ? t('global:bookFirstSession') : t('global:bookNextSession')}
                  </Button>

                  <Button opacity={0.8} color={theme.colors.black['600']} onClick={() => navigate('/')} variant="ghost" width="100%" height="44px" fontSize="15px">
                    <ArrowBack />
                    {t('global:membership:backToDashboard')}
                  </Button>
                </CenterColumn>
              </Container>
            )
          )}
          {sessionLengthPriceController.open && !openCalCom && (
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
              <CenterColumn gap={48} minHeight={500}>
                <div>
                  <Text maxWidth="100%" variant="heading1" textAlign="center" fontSize="36px" fontWeight="700" color="#1a1a1a">
                    {t('global:chooseSessionLength')}
                  </Text>
                </div>

                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
                  <CenterRow gap={16} flexWrap="wrap" justifyContent="center">
                    {sessionPrices?.data?.map((item, index) => (
                      <motion.div key={item.duration} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1 + 0.5 }}>
                        <HourlyPriceCard
                          onClick={() => {
                            dispatch(calComActions.setDuration(String(item.duration)))
                            setOpenCalCom(true)
                          }}
                          item={item}
                        />
                      </motion.div>
                    ))}
                  </CenterRow>
                </motion.div>
              </CenterColumn>
            </motion.div>
          )}

          {openCalCom && (
            <Box position="absolute" right={0} top={0} p={2}>
              <IconButton onClick={() => setOpenCalCom(false)}>
                <Close />
              </IconButton>
            </Box>
          )}
        </FlowContainer>
      )}
    </Container>
  )
}
