import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Button, CenterRow, Container, Text } from 'components/Core'
import { CountdownComponent } from 'components/GeneralComponents'
import { useGetOrderByIdQuery } from 'defaultLayout/slice/service'
import { useAuth } from 'hooks/useAuth'
import { t } from 'i18next'
import GoSellElement from 'pages/PaymentPages/TapGateway/GoSellElement'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isSafariBrowser, uid } from 'utils'
import { OrderDetail } from 'utils/types/types'

export const Payment = () => {
  const [searchParams] = useSearchParams()
  const [orderDetails, setOrderDetails] = useState<Partial<OrderDetail>>({})
  const [sessionExpired, setSessionExpired] = useState(false)
  const { user } = useAuth()
  const { data } = useGetOrderByIdQuery(searchParams.get('order_id'), {
    skip: !searchParams.get('order_id'),
  })
  const startPayment = useCallback(() => {
    if (data?.id && user?.id) {
      setOrderDetails({
        ...data,
        full_name: user.full_name,
        phone_number: user?.phone_number,
        email: user.email,
        description: 'Session Payment',
        order_id: data?.id,
        transaction_id: uid(),
        user_id: user.id,
      })
      // if (isSafariBrowser()) {
      //   safariPaymentController.onOpen()
      // } else {
      //   regularPaymentController.onOpen()
      // }
    }
  }, [data, user])
  useEffect(() => {
    startPayment()
  }, [startPayment])
  const navigate = useNavigate()
  return (
    <Container gap={16} flexDirection="column" minHeight="100%">
      <CenterRow gap={2}>
        <Text variant="body1" color="gray.500">
          {t('global:paymentExpireIn')}
        </Text>
        <CountdownComponent
          creationTimestamp={data?.created_at}
          onComplete={() => {
            setSessionExpired(true)
            // navigate('/')
          }}
        />
      </CenterRow>
      <CenterRow gap={2}>
        <Text
          maxWidth={600}
          textAlign="center"
          variant="heading2"
          style={{
            fontSize: '32px',
            fontWeight: 600,
            color: '#1A202C',
          }}
        >
          {t('global:processing')}
        </Text>
        <Spinner />
      </CenterRow>
      {/* <GoSellElement redirectUrl={process.env.REACT_APP_SITE_URL} orderDetails={orderDetails} /> */}
    </Container>
  )
}
