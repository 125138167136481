// Core
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Actions
import { advisorOnbordingActions } from '../../actions/advisorOnbordingActions'
//Components
import { GeneralProfileInformation, ParentProfileQuestionnairePart, ProfileTherapistsBlock } from '../../components'
// Styles
import Styles from './Styles.module.scss'
import { CustomTabs } from 'components/shared/CustomTab/Tabs'
import { TreatmentPlan } from 'pages/ShardPage/TreatmentPlan/TreatmentPlan'
import { ParentProfileQuestionnairePartWithTranslation } from 'components/ClientProfileComponents/QuestionnairePartWithTranslation'
import { Box, TablePagination } from '@mui/material'
import { CustomAccordion } from 'components/shared/CustomAccordion'
import { theme } from 'utils/theme'
import { useSearchValues } from 'hooks/useSearchValues'
import { defaultLayoutActions } from 'defaultLayout/slice'
import { selectTherapistListPageSearchValues } from 'defaultLayout/slice/selectors'
import { useGetTherapistListQuery } from 'defaultLayout/slice/service'
import { CenterRow, Chip, Flex } from 'components/Core'

export const AdvisorAssignTherapistsPage = () => {
  // const dispatch = useDispatch()
  const searchValues = useSelector(selectTherapistListPageSearchValues)
  const allSearchValues = useSearchValues(defaultLayoutActions.changeTherapistListSearchValues, searchValues)
  const { data, isFetching } = useGetTherapistListQuery(allSearchValues.validSearchValues)
  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.advisorOnbording.currentParent)
  // const allTherapists = useSelector((state) => state.advisorOnbording.allTherapists)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)
  const [assignedTherapists, setAssignedTherapists] = useState(null)
  const [refetch, setRefetch] = useState(true)

  useEffect(() => {
    if (profileId) {
      const dataForRequest = {
        id: profileId,
      }

      allSearchValues.dispatch(advisorOnbordingActions.getParentInfoForAssigning(dataForRequest))
      // dispatch(advisorOnbordingActions.getAllTherapists())
    }
  }, [profileId, refetch])
  const questions = useMemo(() => {
    return Array.isArray(currentProfile?.questions) ? currentProfile?.questions : Object.values(currentProfile?.questions || {})
  }, [currentProfile?.questions])
  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.profile?.full_name,
        age: currentProfile?.profile?.age,
        birth_date: currentProfile?.profile?.birth_date,
        languages: currentProfile?.profile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
        assignedTherapists: currentProfile?.therapists,
        parent_preferred_language: currentProfile?.parent?.preferred_language,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const finalQuestionnaire = currentProfile?.profile?.questionnaire?.questionnaire.map((category) => {
        // const questions = Array.isArray(currentProfile?.questions) ? currentProfile?.questions : Object.values(currentProfile?.questions || {})

        const matchingQuestion = questions.find((q) => q.id === category.id)

        return {
          ...category,
          advisor_comment: matchingQuestion?.advisor_comment || '',
        }
      })

      const questionnaire = {
        familyDynamics: currentProfile?.profile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.profile?.questionnaire?.parental_wellbeing,
        questionnaire: finalQuestionnaire,
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setQuestionnaire(questionnaire)
      setAssignedTherapists(currentProfile?.therapists)
    }
  }, [currentProfile])
  const isYou = currentProfile?.organizer_user?.id === user?.user?.id
  return (
    <Flex flexDirection="column" gap={2} p={2} px={[2, 2, 30]}>
      {currentProfile?.organizer_user && (
        <CenterRow width={['100%']}>
          <Chip ype={isYou ? 'default' : 'info'} width="100%" value={`Advisor : ${isYou ? 'You' : currentProfile?.organizer_user?.full_name}`} />
        </CenterRow>
      )}
      <Box p={0} className={Styles.main_container}>
        <div className={Styles.general_block}>
          <CustomTabs
            tabs={[
              {
                label: 'User Profile',
                component: (
                  <>
                    <div className={Styles.general_block__info}>
                      <GeneralProfileInformation profile={profileInfo} client={clientInfo} />
                    </div>
                    <Box>
                      {/* <ParentProfileQuestionnairePartWithTranslation onboardingQuestions={questions} questionnaire={questionnaire} profile={profileInfo} /> */}
                      <CustomAccordion
                        accordionSummaryProps={{
                          sx: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: theme.shadows.normal,
                            px: 1,
                          },
                        }}
                        titleAlignment="left"
                        title={'Questionnaire and Reports'}
                      >
                        <ParentProfileQuestionnairePartWithTranslation
                          onboardingQuestions={questions}
                          questionnaire={questionnaire}
                          profile={profileInfo}
                          style={{ width: '100%' }}
                        />
                      </CustomAccordion>
                    </Box>
                  </>
                ),
              },
              {
                label: 'Treatment Plan',
                component: <TreatmentPlan allowEdit={false} name={profileInfo?.full_name} profile_id={currentProfile?.profile?.id} />,
              },
            ]}
          />
        </div>
        <div className={Styles.therapists_block}>
          <ProfileTherapistsBlock
            isFetching={isFetching}
            allTherapists={data?.results ?? []}
            assignedTherapists={assignedTherapists}
            setRefetch={setRefetch}
            {...allSearchValues}
            data={data}
          />
        </div>
      </Box>
    </Flex>
  )
}
