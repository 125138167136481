// Core
import { shape, string, bool, func, number } from 'prop-types'
import { useState } from 'react'

// Styles
import Styles from './styles.module.scss'
import StylesNewMessage from '../../MessagesHistory/NewMessageBar/styles.module.scss'

// Components
import { ReactComponent as CheckIcon } from '../../../../../theme/assets/icons/check_updated.svg'
import { ReactComponent as FileIcon } from '../../../../../theme/assets/icons/file_new.svg'
import { UserAvatar } from '../../UserAvatar'
import { Spinner } from '../../Spinner'

//Helpers
import { getFormatedLocalTime } from '../../../../../helpers'

//Utils
import { convertBytes, returnContent } from '../../../../../utils'
import { IconButton } from '@mui/material'
import { Download } from '@mui/icons-material'
import axios from 'axios'
import { Box, CenterColumn, Flex, Text } from 'components/Core'
import { getMimeType } from 'utils/utils'
import { imageFileExtensions, audioFileExtensions, videoFileExtensions } from 'constants/index'

export const RecievedMessageInstance = ({ messageInfo, onChatDetailsOpenning, profileAvatar, id, grouped = false }) => {
  const { isViewed, content, contact, timestamp, attachment, senderName, role } = messageInfo
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenFile = async (fileUrl, filename) => {
    try {
      setIsLoading(true)
      await axios(fileUrl, {
        responseType: 'blob',
      })
        .then((response) => {
          const mimeType = getMimeType(filename)
          const blob = new Blob([response.data], { type: mimeType })
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = filename
          link.click()
          URL.revokeObjectURL(url)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } catch (error) {
      // console.error('Error downloading file:', error);
      setIsLoading(false)
    }
  }

  const getPreviewImg = (fileType, filePreview) => {
    switch (true) {
      case imageFileExtensions.includes(fileType):
        return (
          <Box width="100%" overflow="hidden" borderRadius="12px">
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={filePreview}
            />
          </Box>
        )
      case videoFileExtensions.includes(fileType):
        return (
          <Box width="100%" maxHeight="300px" borderRadius="12px">
            <video
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
                // backgroundColor: '#000',
              }}
              controls
              src={filePreview}
            />
          </Box>
        )
      case audioFileExtensions.includes(fileType):
        return (
          <Box width="100%" padding="10px">
            <audio
              style={{
                width: '100%',
                height: '40px',
              }}
              controls
              src={filePreview}
            />
          </Box>
        )
      default:
        return (
          <div className={Styles.preview__item__preview__imgWrap}>
            <FileIcon />
          </div>
        )
    }
  }

  const thumbs = () => {
    return (
      <Box position="relative" width={['100%']} className={StylesNewMessage.preview__item} key={attachment.name}>
        <Box width="100%" className={StylesNewMessage.preview__item__preview}>
          <CenterColumn width="100%">
            <Box>{getPreviewImg(attachment?.extension, attachment?.url)}</Box>
            <div className={StylesNewMessage.preview__item__preview__description}>
              <p className={StylesNewMessage.preview__item__preview__description__name}>{attachment.filename}</p>
              <p className={StylesNewMessage.preview__item__preview__description__size}>{convertBytes(attachment.size)}</p>
            </div>
          </CenterColumn>
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
          }}
          onClick={() => handleOpenFile(attachment.url, attachment.filename)}
          size="small"
        >
          {isLoading ? <Spinner width="30px" /> : <Download fontSize="medium" />}
        </IconButton>
      </Box>
    )
  }

  return (
    <Flex
      id={id}
      flexWrap="wrap"
      className={Styles.recievedMessageInstance}
      sx={{
        marginBottom: '12px',
        animation: 'fadeIn 0.3s ease-in',
      }}
    >
      <UserAvatar senderName={senderName} avatar={profileAvatar} online={contact?.online} onChatDetailsOpenning={onChatDetailsOpenning} sx={{ marginRight: '12px' }} />
      <Flex
        width={['95%', '95%', '80%', '75%', '65%', '40%']}
        className={Styles.recievedMessageInstance__content}
        sx={{
          backgroundColor: '#f0f2f5',
          borderRadius: '18px',
          padding: '12px 16px',
          position: 'relative',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#e8eaed',
          },
        }}
      >
        <Box width={attachment ? '100%' : 'max-content'}>
          <CenterColumn className={Styles.recievedMessageInstance__content__message__text}>
            {grouped && (
              <Text
                color="typePurpure.300"
                variant="light"
                sx={{
                  fontSize: '13px',
                  marginBottom: '4px',
                  fontWeight: '500',
                }}
              >
                {senderName}
              </Text>
            )}
            {attachment ? (
              thumbs()
            ) : (
              <Text
                sx={{
                  fontSize: '15px',
                  lineHeight: '1.4',
                  wordBreak: 'break-word',
                }}
              >
                {returnContent(content)}
              </Text>
            )}
          </CenterColumn>
        </Box>
        <div
          className={Styles.recievedMessageInstance__content__time}
          style={{
            fontSize: '11px',
            color: '#65676b',
            marginTop: '4px',
          }}
        >
          {getFormatedLocalTime(timestamp)}
        </div>
      </Flex>
    </Flex>
  )
}
