import { useContentBundle } from 'hooks/useContentBundle'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { CenterColumn, CenterRow, Text } from 'components/Core'
import { profileActions } from 'actions'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { Checkbox } from '@mui/material'
import { useEffect } from 'react'
import type { Direction } from 'components/Core/common/types'
type Props = {
  setMetaData: (data: object) => void
  direction: Direction
  title?: string
  sub_text?: string
  main_text?: string
  hideNahra?: boolean
}
export const RecordingConsent = ({ setMetaData, direction, title, main_text, sub_text, hideNahra }: Props) => {
  const dispatch = useDispatch()
  const { t } = useContentBundle({
    englishContent: en,
    arabicContent: ar,
    key: 'recording_modal',
  })
  const handleSelectOption = (options: boolean) => {
    const dataForRequest = {
      setData: (ipAddress: string) => {
        setMetaData({
          consent: options,
          user_agent: navigator.userAgent,
          ip: ipAddress,
          time: dayjs.utc().toISOString(),
        })
      },
    }
    dispatch(profileActions.getIPAddress(dataForRequest) as any)
  }

  useEffect(() => {
    handleSelectOption(true)
  }, [])
  return (
    <CenterColumn direction={direction} gap="10px">
      {!hideNahra && (
        <CenterColumn gap="5px">
          <Text color="typePurpure.300" variant="heading2">
            {title ?? t('recording_modal:bookYourFreeConsultation')}
          </Text>
          <Text lineHeight={1.3}>{main_text ?? t('recording_modal:main_text')}</Text>
          <Text variant="light">{sub_text ?? t('recording_modal:sub_text')}</Text>
        </CenterColumn>
      )}
      <CenterRow gap="8px">
        <Checkbox
          sx={{
            p: 0,
          }}
          color="secondary"
          onChange={(e) => {
            setMetaData({})
            handleSelectOption(e.target.checked)
          }}
        />
        <Text fontWeight="bold">{t('recording_modal:doNotRecord')}</Text>
      </CenterRow>
    </CenterColumn>
  )
}
