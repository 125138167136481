import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

type MenuItemType = {
  label: string
  onClick: () => void
}

type Props = {
  ButtonLabel: (prop: any) => JSX.Element
  menuItems: MenuItemType[]
}

export function ReusableMenu({ ButtonLabel, menuItems }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {typeof ButtonLabel === 'string' ? (
        <Button id="menu-button" aria-controls={open ? 'menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
          {ButtonLabel}
        </Button>
      ) : (
        <ButtonLabel id="menu-button" aria-controls={open ? 'menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick} />
      )}

      <Menu
        sx={{
          '&.MuiMenu-list': {
            padding: '8px',
          },
        }}
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'menu-button' }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
