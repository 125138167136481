import { createSelector } from '@reduxjs/toolkit'

const rootState = (state) => state

export const selectOnboarding = createSelector([rootState], (state) => state?.advisorOnbording)
export const selectAdditionalAdvisorComments = createSelector([selectOnboarding], (state) => state?.additionalAdvisorComments)
export const selectHistoryAndBackgroundComments = createSelector([selectOnboarding], (state) => state?.historyAndBackground)
export const selectCurrentQuestionId = createSelector([selectOnboarding], (state) => state?.currentQuestionId)
export const selectShowAdvisorCommentSection = createSelector([selectOnboarding], (state) => state?.showAdvisorCommentSection)
export const selectShowHistoryAndBackgroundSection = createSelector([selectOnboarding], (state) => state?.showHistoryAndBackgroundSection)
export const selectFilledQuestions = createSelector([selectOnboarding], (state) => state?.questions)
export const selectParentGoalsObjectives = createSelector([selectOnboarding], (state) => state?.parentGoalsObjectives)
export const selectAdvisorRecommendations = createSelector([selectOnboarding], (state) => state?.advisorRecommendations)

export const selectOnBoardingStep = createSelector([selectOnboarding], (state) => state?.step)
