import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectDefaultLayout = createSelector([rootState], (state) => state.defaultLayout)

export const selectAuthState = createSelector([rootState], (state) => state?.auth)
export const selectUserDetail = createSelector([selectDefaultLayout], (state) => state?.user)
export const selectUserType = createSelector([selectAuthState], (state) => state?.user?.user?.user_type)

export const selectAlertState = createSelector([selectDefaultLayout], (state) => state.alert)
export const selectZoomLeaveUrl = createSelector([selectDefaultLayout], (state) => state.zoomLeaveUrl)
export const selectABTestingState = createSelector([selectDefaultLayout], (state) => state.abTesting)

export const selectOpenedTab = createSelector([selectDefaultLayout], (state) => state.openedTab)
export const selectFirebaseToken = createSelector([selectDefaultLayout], (state) => state.firebaseToken)
export const selectIsAuthenticating = createSelector([selectDefaultLayout], (state) => state.isAuthenticating)
export const selectIsUserLoggingIn = createSelector([selectDefaultLayout], (state) => state.isUserLoggingIn)
export const selectSubscription = createSelector([selectDefaultLayout], (state) => state.subscription)

export const selectRedirectTo = createSelector([selectDefaultLayout], (state) => state.redirectTo)
export const selectSessionBookingLink = createSelector([selectDefaultLayout], (state) => state.sessionBookingLink)
export const selectUnreadMessagesCount = createSelector([selectDefaultLayout], (state) => state.unreadMessagesCount)
export const selectChatSelectedProfile = createSelector([selectDefaultLayout], (state) => state.chatSelectedProfile)
export const selectChannels = createSelector([selectDefaultLayout], (state) => state.channels)

export const selectTherapistApplicationPageSearchValues = createSelector([selectDefaultLayout], (state) => state.therapistApplicationPageSearchValues)
export const selectTherapistMyPatientsPageSearchValues = createSelector([selectDefaultLayout], (state) => state.therapistMyPatientsPageSearchValues)

export const selectTherapistListPageSearchValues = createSelector([selectDefaultLayout], (state) => state.therapistListPageSearchValues)
