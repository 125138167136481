/**
 * Functional component for managing and displaying applications.
 *
 * @component
 */

import Styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { advisorOnbordingActions, zoomActions } from '../../actions'
import { setIsOpenZoomModal } from '../../reducers/zoomSlice'
import { Table } from './Table'
import { NoDataComponent } from '../GeneralComponents'
import { SeeResultsPopup } from '../OnbordingParent/SeeResultsPopup'
import { TablePagination } from '@mui/material'
import { setCurrentParent } from 'reducers/advisorOnbordingSlice'
import { notify } from 'helpers'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { Box, CenterRow, Text } from 'components/Core'
import { SearchInput } from 'components/Core/SearchInput'
import { useAuth } from 'hooks/useAuth'
import { useAdvisorApplicationsSearchValues } from './slice/hooks/useAdvisorApplicationsSearchValues'
import { useGetApplicationsAdvisorQuery } from 'defaultLayout/slice/service'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { selectAdvisorApplicationsRefetch } from './slice/selector'
import { advisorApplicationsActions } from './slice'
import { advisorProfilesActions } from 'components/AdvisorFlow/Parents/slice'
const headers = [
  { text: 'DATE CREATED', key: 'created_at', sorting: true },
  { text: 'NAME OF APPLIER', sorting: false },
  { text: 'PHONE', sorting: false },
  { text: 'PROBLEM', sorting: false },
  { text: 'STATUS', sorting: false },
  { text: 'MEETING TIME', key: 'session__start_time', sorting: true },
  { text: 'Advisor', sorting: false },
  { text: 'ACTION', sorting: false },
]
export const Applies = () => {
  const {
    searchInputValue,
    searchValues,
    page,
    pageSize,
    validSearchValues,
    setSearchInputValue,
    changePageSize,
    dispatch,
    goToPage,
    handleSelectTab,
    handleSearchQuery,
    handleSorting,
  } = useAdvisorApplicationsSearchValues()
  const shouldRefetch = useSelector(selectAdvisorApplicationsRefetch)
  const { data, isFetching, refetch } = useGetApplicationsAdvisorQuery(validSearchValues, { skip: searchValues.page === 0 })

  const { setZoomLeaveUrl } = useDefaultLayoutSlice()
  const navigate = useNavigate()

  const { user } = useAuth()

  const [isOpenResults, setIsOpenResults] = useState(false)
  const [isAdult, setIsAdult] = useState(false)

  /**
   * Opens the results popup and fetches application details.
   *
   * @param {string} meetingId - The ID of the meeting.
   * @param {boolean} isAdult - Indicates if the application is for an adult.
   */

  const handleOpenResultsPopup = (meetingId, isAdult) => {
    const data = {
      meeting_id: meetingId,
      onSuccess: () => {
        setIsOpenResults(true)
        setIsAdult(isAdult)
      },
    }
    dispatch(advisorOnbordingActions.getOneApply(data))
  }

  /**
   * Closes the results popup.
   */

  const handleCloseResultsPopup = () => setIsOpenResults(false)

  /**
   * Joins a meeting and updates the application status.
   *
   * @param {string} meetingId - The ID of the meeting.
   * @param {string} zoomMeetingId - The ID of the Zoom meeting.
   */

  const handleJoinMeeting = (meetingId, zoomMeetingId) => {
    if (meetingId) {
      const data = {
        id: meetingId,
        payload: {
          status: 'in_a_meeting',
        },
      }
      dispatch(advisorOnbordingActions.changeStatusApplies(data))
      if (meetingId) {
        setZoomLeaveUrl()
        navigate(`/session?id=${meetingId}`)
      } else {
        notify(t('alertMessages:zoomMeetingNotFound'), true, false)
      }
      setTimeout(refetch, 2000)
    }

    const dataForZoom = {
      id: zoomMeetingId,
    }
    dispatch(zoomActions.getMeetingInformation(dataForZoom))
    dispatch(setIsOpenZoomModal())
  }

  /**
   * Starts the onboarding process.
   *
   * @param {Object} data - The onboarding data.
   * @param {boolean} isAdult - Indicates if the onboarding is for an adult.
   */

  const handleStartOnboarding = (data, isAdult, childData) => {
    // console.log({ data })
    dispatch(advisorOnbordingActions.clearOnboardingDetails())
    dispatch(advisorOnbordingActions.setParent(data))
    dispatch(advisorOnbordingActions.setChildInfo(childData))
    dispatch(advisorApplicationsActions.changeRefetch(false))
    navigate(`onboarding_children?type=${isAdult ? 'adult' : 'child'}&consultation_id=${data.meetingId}`)
  }

  const handleOpenUserDetails = (profileId, isAdult, rowInfo) => {
    // console.log(rowInfo)
    const meetingInfo = {
      start_time: rowInfo?.start_time,
      meeting_id: rowInfo?.meeting_id,
      status: rowInfo?.status,
    }
    dispatch(setCurrentParent(meetingInfo))
    navigate(`${profileId}`)
  }

  /**
   * Event handlers for button clicks.
   *
   * @type {Object}
   * @property {function(string, boolean): void} openResults - Opens the results popup.
   * @property {function(Object, boolean): void} startOnboarding - Starts the onboarding process.
   * @property {function(string, string): void} joinMeeting - Joins a meeting.
   */
  const onClickEvents = {
    openResults: handleOpenResultsPopup,
    startOnboarding: handleStartOnboarding,
    joinMeeting: handleJoinMeeting,
    openUserDetails: handleOpenUserDetails,
  }

  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(advisorProfilesActions.changeRefetch(true))
    }
  }, [shouldRefetch])

  return (
    <Box px={[2, 2, 10, 20, 40]} py={[24]} className={Styles.main_container}>
      <div className={Styles.header}>
        <Box flexWrap={['wrap']} gap={10} className={Styles.header__first_block}>
          <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
            <h6>Applications</h6>
            <SearchInput
              sx={{
                width: ['100%', '90%', '400px', '400px'],
                bgcolor: 'white',
              }}
              getValueChange={handleSearchQuery}
              setSearchInputValue={setSearchInputValue}
              placeholder="Search by name or email"
              size="small"
              value={searchInputValue}
            />
          </CenterRow>
        </Box>
        <Box overflowX="scroll" className={Styles.tabs}>
          <Text minWidth="fit-content" className={searchValues.status === '' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('')}>
            All
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'planned' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('planned')}>
            Planned meeting
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'accepted' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('accepted')}>
            Accepted
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'declined' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('declined')}>
            Declined
          </Text>
          <Text minWidth="fit-content" className={searchValues.status === 'failed' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('failed')}>
            Failed
          </Text>
        </Box>
      </div>

      <Box minHeight="auto" className={Styles.data_container}>
        {/* {belowMd ? (
            <AdvisorApplicationCardView consultations={applications} onClickEvents={onClickEvents} />
          ) : ( */}
        <Table
          isFetching={isFetching}
          onSort={handleSorting}
          sort_by={searchValues.sort_by}
          sort_direction={searchValues.sort_direction === 'asc'}
          data={data?.results ?? []}
          headers={headers}
          userType={user?.user_type}
          userDataType={'application'}
          onClickEvents={onClickEvents}
        />
        {/* )} */}
      </Box>
      {!data?.count && !isFetching && <NoDataComponent text={'There is no applications yet.'} />}
      <CenterRow justifyContent="flex-end" width="100%" pr={[0, 0, 50]}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, value) => {
            goToPage(value)
          }}
          onRowsPerPageChange={(event) => {
            changePageSize(+event.target.value)
            goToPage(0)
          }}
        />
      </CenterRow>
      {isOpenResults && <SeeResultsPopup open={isOpenResults} isAdult={isAdult} onClose={handleCloseResultsPopup} />}
    </Box>
  )
}
