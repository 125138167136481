import { Box, CenterColumn, CenterRow, Text } from 'components/Core'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { Subscription } from 'utils/types/types'

const FeatureList = styled.div`
  list-style: none;
  padding-top: 10px;
  margin: 24px 0;
  text-align: left;
  background: ${theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`

const FeatureItem = styled.li`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  max-width: 360px;
  color: ${theme.colors.textPrimary};
  font-size: 16px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${theme.colors.black[100]};
  }

  &:before {
    content: '✓';
    color: ${theme.colors.typePurpure[100]};
    margin-right: 5px;
    margin-left: 5px;
    font-weight: bold;
    font-size: 18px;
  }
`

type Props = {
  subscription: Subscription
}

export const MembershipTherapistDetail = ({ subscription }: Props) => {
  const { t } = useTranslation()
  const { direction } = useGetLanguage()
  const features = t('global:membership:features', { returnObjects: true }) as Array<string>
  return (
    <CenterColumn
      style={{
        // background: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
        // borderRadius: '16px',
        maxWidth: '100%',
      }}
      gap={'8px'}
    >
      <Text style={{ color: theme.colors.black[600] }} mb={3}>
        {t('global:membership:title')}
      </Text>

      <CenterColumn
        style={{
          // padding: '16px',
          // background: theme.colors.black[200],
          borderRadius: '12px',
          textAlign: 'center',
        }}
        gap={8}
      >
        <CenterRow gap={8} width="max-content">
          <Text style={{ fontSize: 18, width: 'max-content' }}>4 x {t('global:session')}</Text>
          <Text style={{ width: 'max-content' }}>=</Text>
          <Text style={{ fontSize: 18, width: 'max-content' }}>${subscription?.price}</Text>
        </CenterRow>
        {/* <Text
          style={{
            fontSize: '14px',
            color: theme.colors.textSecondary,
          }}
        >
          {t('global:membership:priceUnit')}
        </Text> */}
      </CenterColumn>

      {/* <FeatureList>
        {features?.map((feature, index) => (
          <FeatureItem key={index}>{feature}</FeatureItem>
        ))}
      </FeatureList> */}
    </CenterColumn>
  )
}
