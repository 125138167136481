import { apiRoutes } from 'apiRoutes'
import { notify } from 'helpers'
import { t } from 'i18next'
import { rootServiceApi } from 'store/service'
import { TherapistDetail } from 'utils/types/types'
import { showToast } from 'utils/utils'

const bookingTherapyService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getTherapist: build.query<TherapistDetail, { therapistId: string | null | undefined; profileId: string | null | undefined }>({
      query: ({ therapistId, profileId }) => ({
        url: `${apiRoutes.therapistBooking.get}${therapistId}/`,
        params: {
          profile_id: profileId,
        },
      }),
    }),
    acceptTherapist: build.mutation<any, { screeningId: number | string | null | undefined; onSuccess?: () => void }>({
      query: ({ screeningId }) => ({
        url: `screenings/${screeningId}/accept/`,
        method: 'PATCH',
      }),
      async onQueryStarted(arg, api) {
        try {
          await api.queryFulfilled
          arg.onSuccess?.()
        } catch (error: any) {
          console.log(error)
          if (error?.error?.status === 405) {
            showToast({ message: t(`global:errors:methodNotAllowed`), type: 'error' })
          } else if (error?.error?.status === 403) {
            showToast({ message: t(`global:errors:unAuthorized`), type: 'error' })
          } else {
            showToast({ message: t(`global:errors:somethingWentWrong`), type: 'error' })
          }
        }
      },
    }),
    declineTherapist: build.mutation<any, { screeningId: number | string | null | undefined; reason: string | undefined; onSuccess?: () => void }>({
      query: ({ screeningId, reason }) => ({
        url: `screenings/${screeningId}/decline/`,
        method: 'PATCH',
        data: {
          reason,
        },
      }),
      async onQueryStarted(arg, api) {
        try {
          await api.queryFulfilled
          arg.onSuccess?.()
        } catch (error: any) {
          console.log(error)
          if (error?.error?.status === 405) {
            showToast({ message: t(`global:errors:methodNotAllowed`), type: 'error' })
          } else if (error?.error?.status === 403) {
            showToast({ message: t(`global:errors:unAuthorized`), type: 'error' })
          } else {
            showToast({ message: t(`global:errors:somethingWentWrong`), type: 'error' })
          }
        }
      },
    }),
    getScreening: build.query<any, { screeningId: number | string | null | undefined }>({
      query: ({ screeningId }) => ({
        url: `screenings/${screeningId}/`,
      }),
    }),
  }),
})

export const { useGetTherapistQuery, useAcceptTherapistMutation, useDeclineTherapistMutation, useGetScreeningQuery } = bookingTherapyService
