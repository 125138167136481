/**
 * @fileoverview Redux slice for advisor onboarding-related state management
 * @module advisorOnbordingSlice
 */

//Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Advisor Onboarding slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Object} initialState.currentParent - Current parent information.
 * @property {Object} initialState.childInfo - Information about the child.
 * @property {Array} initialState.questions - List of questions.
 * @property {Array} initialState.allApplies - List of all applications.
 * @property {Object} initialState.oneApply - Details of one application.
 * @property {Array} initialState.allTherapists - List of all therapists.
 * @property {Object} initialState.oneTherapist - Details of one therapist.
 * @property {Array} initialState.allParentsForAssigning - List of all parents for assigning.
 * @property {Object} initialState.oneParentForAssigning - Details of one parent for assigning.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setCurrentParent - Sets the current parent information.
 * @property {Function} reducers.setChildInformation - Sets the information about the child.
 * @property {Function} reducers.setQuestions - Sets the list of questions.
 * @property {Function} reducers.setAllApplies - Sets the list of all applications.
 * @property {Function} reducers.setOneApply - Sets the details of one application.
 * @property {Function} reducers.setAllTherapists - Sets the list of all therapists.
 * @property {Function} reducers.setOneTherapist - Sets the details of one therapist.
 * @property {Function} reducers.setAssignings - Sets the list of all parents for assigning.
 * @property {Function} reducers.setParentForAssigning - Sets the details of one parent for assigning.
 * @property {Function} reducers.setAdvisorComments - Sets the details of one parent for assigning.
 */

export const advisorOnbordingSlice = createSlice({
  name: 'advisorOnbording',
  initialState: {
    currentParent: {},
    childInfo: {},
    questions: [],
    allApplies: [],
    oneApply: {},
    allTherapists: [],
    oneTherapist: {},
    allParentsForAssigning: [],
    oneParentForAssigning: {},
    additionalAdvisorComments: '',
    historyAndBackground: '',
    parentGoalsObjectives: '',
    advisorRecommendations: '',
    currentQuestionId: 0,
    showAdvisorCommentSection: false,
    showHistoryAndBackgroundSection: true,
    step: 'parent-goals',
  },
  reducers: {
    changeStep: (state, action) => {
      state.step = action.payload
    },
    setAdditionalAdvisorComments: (state, action) => {
      state.additionalAdvisorComments = action.payload
    },
    setHistoryAndBackgroundComments: (state, action) => {
      state.historyAndBackground = action.payload
    },
    setParentGoalsObjectives: (state, action) => {
      state.parentGoalsObjectives = action.payload
    },
    setAdvisorRecommendations: (state, action) => {
      state.advisorRecommendations = action.payload
    },
    increaseCurrentQuestionId: (state, action) => {
      state.currentQuestionId += 1
    },
    decreaseCurrentQuestionId: (state, action) => {
      state.currentQuestionId -= 1
    },
    setCurrentQuestionId: (state, action) => {
      state.currentQuestionId = action.payload
    },
    resetQuestionSteps: (state, action) => {
      state.currentQuestionId = 0
      state.showAdvisorCommentSection = false
      state.showHistoryAndBackgroundSection = true
      state.additionalAdvisorComments = ''
      state.historyAndBackground = ''
      state.parentGoalsObjectives = ''
      state.advisorRecommendations = ''
      state.oneApply = {}
      state.step = 'parent-goals'
    },
    setShowAdvisorCommentSection: (state, action) => {
      state.showAdvisorCommentSection = action.payload
    },
    setShowHistoryAndBackgroundSection: (state, action) => {
      state.showHistoryAndBackgroundSection = action.payload
    },
    /**
     * Sets the current parent information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new current parent information.
     */
    setCurrentParent: (state, action) => {
      state.currentParent = { ...state.currentParent, ...action.payload }
    },
    /**
     * Sets the information about the child.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new child information.
     */
    setChildInformation: (state, action) => {
      state.childInfo = action.payload
    },
    /**
     * Sets the list of questions.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of questions.
     */
    setQuestions: (state, action) => {
      state.questions = action.payload
    },
    /**
     * Sets the list of all applications.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all applications.
     */
    setAllApplies: (state, action) => {
      state.allApplies = action.payload
    },
    /**
     * Sets the details of one application.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new details of one application.
     */
    setOneApply: (state, action) => {
      state.oneApply = action.payload
    },
    /**
     * Sets the list of all therapists.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all therapists.
     */
    setAllTherapists: (state, action) => {
      state.allTherapists = action.payload
    },
    /**
     * Sets the details of one therapist.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new details of one therapist.
     */
    setOneTherapist: (state, action) => {
      state.oneTherapist = action.payload
    },
    /**
     * Sets the list of all parents for assigning.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all parents for assigning.
     */
    setAssignings: (state, action) => {
      state.allParentsForAssigning = action.payload
    },
    /**
     * Sets the details of one parent for assigning.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new details of one parent for assigning.
     */
    setParentForAssigning: (state, action) => {
      state.oneParentForAssigning = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setCurrentParent - Action creator for setting the current parent information.
 * @property {Function} setChildInformation - Action creator for setting the information about the child.
 * @property {Function} setQuestions - Action creator for setting the list of questions.
 * @property {Function} setAllApplies - Action creator for setting the list of all applications.
 * @property {Function} setOneApply - Action creator for setting the details of one application.
 * @property {Function} setAllTherapists - Action creator for setting the list of all therapists.
 * @property {Function} setOneTherapist - Action creator for setting the details of one therapist.
 * @property {Function} setAssignings - Action creator for setting the list of all parents for assigning.
 * @property {Function} setParentForAssigning - Action creator for setting the details of one parent for assigning.
 */
export const {
  setCurrentParent,
  setChildInformation,
  setAdditionalAdvisorComments,
  setQuestions,
  setAllApplies,
  setOneApply,
  setAllTherapists,
  setOneTherapist,
  setAssignings,
  setParentForAssigning,
  increaseCurrentQuestionId,
  decreaseCurrentQuestionId,
  setShowAdvisorCommentSection,
  setShowHistoryAndBackgroundSection,
  setHistoryAndBackgroundComments,
  resetQuestionSteps,
  changeStep,
  setParentGoalsObjectives,
  setAdvisorRecommendations,
  setCurrentQuestionId,
} = advisorOnbordingSlice.actions

export default advisorOnbordingSlice.reducer
