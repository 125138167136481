import { GoSell } from '@tap-payments/gosell'
import { useEffect } from 'react'
import { paymnetPath, tapPaymentStatusCode, paymentKey, threeDSecure } from 'constants/index'
import { CountdownComponent } from 'components/GeneralComponents'
import Styles from './Styles.module.scss'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { Box } from 'components/Core'
import { t } from 'i18next'

export const PaymentPage = ({
  isOpen,
  closePopup,
  orderDetails,
  openPaymentExpiresPopup,
  webhookUrl = 'api/payments/webhooks/create/',
  showCloseIcon = false,
  showCountdown = true,
  showTopLeftHeader = true,
  redirectUrl = '',
}) => {
  // console.log(orderDetails, webhookUrl, redirectUrl)
  const { amount, currency, full_name, phone_number, email, description, order_id, transaction_id, created_at, user_id } = orderDetails ?? {}

  const callbackFunc = (response) => {
    localStorage.setItem('paymentResponse', JSON.stringify(response?.callback))
    if (response?.callback?.response?.code === '000') {
      const paymentsData = {
        transaction_id: response.callback.reference.transaction,
        status: response.callback.response.code,
      }
      localStorage.setItem(`payment_state_${order_id}`, JSON.stringify(paymentsData))
    }
  }

  const handleClosePopupTimeExpires = () => {
    closePopup()
    openPaymentExpiresPopup(true)
  }

  useEffect(() => {
    if (isOpen) {
      GoSell.openLightBox()
    }
  }, [isOpen])

  return (
    <div className="App" allow="payment">
      {showTopLeftHeader && (
        <div className={Styles.countdown_conatiner}>
          {showCountdown && (
            <>
              <h5>{t('global:paymentExpireIn')}:</h5>
              <CountdownComponent creationTimestamp={created_at} onComplete={handleClosePopupTimeExpires} />
            </>
          )}
        </div>
      )}
      <GoSell
        gateway={{
          publicKey: paymentKey,
          contactInfo: false,
          supportedCurrencies: ['USD'],
          supportedPaymentMethods: ['VISA', 'MASTERCARD', 'APPLE_PAY'],
          saveCardOption: false,
          customerCards: false,
          notifications: 'standard',
          onClose: closePopup,
          backgroundImg: {
            url: 'imgURL',
            opacity: '0.5',
          },
          callback: (res) => callbackFunc(res),
          labels: {
            cardNumber: 'Card Number',
            expirationDate: 'MM/YY',
            cvv: 'CVV',
            cardHolder: 'Name on Card',
            actionButton: 'PAY',
            style: {
              base: {
                color: '#dc3545',
                lineHeight: '18px',
                fontFamily: 'sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: 'rgba(0, 0, 0, 0.26)',
                  fontSize: '15px',
                },
              },
              invalid: {
                color: 'red',
                iconColor: '#fa755a ',
              },
            },
          },
        }}
        customer={{
          first_name: full_name,
          email: email,
          phone: {
            number: phone_number,
          },
        }}
        order={{
          amount: amount,
          currency: currency,
          items: [{ amount: 1, description: 'Therapist Numuw, May 31' }],
        }}
        transaction={{
          mode: 'charge',
          charge: {
            saveCard: false,
            threeDSecure: threeDSecure,
            description: description,
            reference: {
              transaction: transaction_id,
              order: order_id,
              user_id,
            },
            receipt: {
              sms: false,
            },
            redirect: redirectUrl,
            post: `${paymnetPath}/${webhookUrl}`,
          },
        }}
      />
    </div>
  )
}
