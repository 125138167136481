import type { ABTestingState, AlertState, DefaultLayoutState, UnreadMessageCounts } from 'defaultLayout/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import type { PushSubscriptionData } from 'serviceWorker/type'
import type { ChatsData, SearchValues, User } from 'utils/types/types'
import history from 'lib/history'

const initialSearchValues = {
  page: 0,
  page_size: 5,
  search_query: '',
  status: '',
}
const initialState: DefaultLayoutState = {
  alert: {
    open: false,
    message: '',
    type: 'info',
  },
  redirectTo: undefined,
  zoomLeaveUrl: localStorage.getItem('zoomLeaveUrl') || process.env.REACT_APP_ZOOM_LEAVE_URL || '',
  abTesting: {
    variant: 'A',
    onboardingStep: 'add_child',
    addChildStep: 1,
    questionnaireStatementStep: 0,
  },
  openedTab: '',
  firebaseToken: '',
  isAuthenticating: true,
  isUserLoggingIn: false,
  subscription: {},
  user: {},
  sessionBookingLink: '',
  unreadMessagesCount: {
    group_messages: 0,
    private_messages: 0,
    total: 0,
  },
  chatSelectedProfile: undefined,
  channels: [],
  therapistApplicationPageSearchValues: initialSearchValues,
  therapistMyPatientsPageSearchValues: initialSearchValues,
  therapistListPageSearchValues: { ...initialSearchValues, sort_by: '-user__created_at' },
}

export const defaultLayoutSlice = createSlice({
  name: 'defaultLayout',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<Partial<User>>) {
      state.user = action.payload
    },
    setRedirectUrl(state, action: PayloadAction<string>) {
      state.redirectTo = action.payload
    },
    setSubscription(state, action: PayloadAction<Partial<PushSubscriptionData> | undefined>) {
      state.subscription = action.payload
    },
    setFirebaseToken: (state, action: PayloadAction<string | undefined | null>) => {
      state.firebaseToken = action.payload
    },
    setIsAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticating = action.payload
    },
    setIsLoggingIn(state, { payload }: PayloadAction<boolean>) {
      state.isUserLoggingIn = payload
    },
    setAlert: (state, { payload }: PayloadAction<Partial<Omit<AlertState, 'open'>>>) => {
      state.alert = { ...state.alert, ...payload, open: true }
    },
    closeAlert: (state) => {
      state.alert = { ...initialState.alert }
    },
    setZoomLeaveUrl: (state, { payload }: PayloadAction<string>) => {
      state.zoomLeaveUrl = payload
    },
    setABTestingState: (state, { payload }: PayloadAction<Partial<ABTestingState>>) => {
      state.abTesting = { ...state.abTesting, ...payload }
    },
    setOpenedTab(state, action: PayloadAction<string | number>) {
      state.openedTab = action.payload
    },
    redirectUser: (state, action: PayloadAction<string>) => {
      if (state.user.user_type === 'parent' && state.redirectTo) {
        history.push(state.redirectTo)
      } else {
        history.push(action.payload ?? '/')
      }
    },
    resetRedirectTo: (state) => {
      state.redirectTo = undefined
    },
    setBookingLink: (state, action: PayloadAction<string>) => {
      state.sessionBookingLink = action.payload
    },
    setUnreadMessagesCount: (state, action: PayloadAction<UnreadMessageCounts>) => {
      state.unreadMessagesCount = action.payload
    },
    setChatSelectedProfile: (state, action: PayloadAction<string | number | undefined>) => {
      state.chatSelectedProfile = action.payload
    },
    setChannels: (state, action: PayloadAction<ChatsData>) => {
      state.channels = action.payload
    },
    changeTherapistApplicationSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.therapistApplicationPageSearchValues = action.payload
    },
    changeTherapistMyPatientsSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.therapistMyPatientsPageSearchValues = action.payload
    },
    changeTherapistListSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.therapistListPageSearchValues = action.payload
    },
  },
})

export const useDefaultLayoutSlice = () => {
  const dispatch = useDispatch()
  const { actions } = defaultLayoutSlice
  type Options = { url: string }
  const setZoomLeaveUrl = (options?: Options) => {
    localStorage.setItem('zoomLeaveUrl', options?.url ?? window.location.href)
    dispatch(actions.setZoomLeaveUrl(options?.url ?? window.location.href))
  }
  return { actions, dispatch, setZoomLeaveUrl }
}

export const defaultLayoutReducer = defaultLayoutSlice.reducer
export const defaultLayoutActions = defaultLayoutSlice.actions
