import { Box, Button, CenterColumn, CenterRow, Text } from 'components/Core'
import { motion } from 'framer-motion'
import { Avatar, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import { Check, Close, Language, School, Psychology, Star } from '@mui/icons-material'
import { TherapistDetail } from 'utils/types/types'
import { t } from 'i18next'
import { theme } from 'utils/theme'
import { useAcceptTherapistMutation, useDeclineTherapistMutation } from './service'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { statusColor } from 'constants/index'
import { CloseIcon } from 'theme/assets/icons'
import { useDisclosure } from 'hooks/useDisclosure'
import { MuiTextField } from 'components/Core/TextArea/MuiTextField'
import { useState } from 'react'
import { showToast } from 'utils/utils'
import { Direction } from 'components/Core/common/types'

interface AcceptTherapistProps {
  therapist: TherapistDetail | undefined
  onAccept: () => void
  fetchingInfo: boolean
  screeningId: number | string | undefined | null
  direction: Direction
}

export const AcceptTherapist = ({ therapist, fetchingInfo, screeningId, direction, onAccept }: AcceptTherapistProps) => {
  const [accept, { isLoading: accepting }] = useAcceptTherapistMutation()
  const [decline, { isLoading: declining }] = useDeclineTherapistMutation()
  const [reason, setReason] = useState('')
  const navigate = useNavigate()
  const { open, onClose, onOpen } = useDisclosure()

  const renderContent = () => {
    if (fetchingInfo) {
      return (
        <CenterColumn alignItems="flex-start" gap={2}>
          <CenterRow gap={2}>
            <Skeleton variant="circular" width={40} height={40} />
            <CenterColumn>
              <Skeleton variant="text" width={150} height={24} />
              <Skeleton variant="text" width={100} height={20} />
            </CenterColumn>
          </CenterRow>
          <CenterRow gap={2} flexWrap="wrap">
            <Skeleton variant="rounded" width={120} height={32} />
            <Skeleton variant="rounded" width={100} height={32} />
            <Skeleton variant="rounded" width={140} height={32} />
          </CenterRow>
        </CenterColumn>
      )
    }

    return (
      <CenterColumn alignItems="flex-start" gap={2}>
        <CenterRow gap={2}>
          <Box position="relative">
            <Avatar sx={{ width: 60, height: 60 }} src={therapist?.user?.profile_pic} />
          </Box>
          <CenterColumn>
            <Text variant="body3" color="#2D3748">
              {therapist?.user?.full_name}
            </Text>
            <Text variant="light">
              {therapist?.experience} {t('global:yearsOfExperience')}
            </Text>
          </CenterColumn>
        </CenterRow>

        <CenterRow gap={2} flexWrap="wrap">
          {therapist?.professional_qualities?.map((item, index) => (
            <Chip sx={{ direction: 'ltr' }} key={index} icon={<Psychology />} label={item} />
          ))}
          <Chip sx={{ direction: 'ltr' }} icon={<Language />} label={therapist?.languages?.join(', ')} />
        </CenterRow>
      </CenterColumn>
    )
  }

  return (
    <motion.div style={{ height: '100%' }} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
      <CenterColumn gap={16} direction={direction} height="100%" alignItems="center" width={'100%'}>
        <Box>
          <motion.div initial={{ y: -20 }} animate={{ y: 0 }} transition={{ duration: 0.8 }}>
            <Text maxHeight="auto" variant="heading4">
              {t('global:acceptTherapistTitle')}
            </Text>
            <Text maxWidth={700} variant="light">
              {t('global:acceptDeclineSubtitle')}
            </Text>
          </motion.div>
        </Box>
        <motion.div style={{ width: '100%' }} initial={{ opacity: 0, scale: 0.95 }} animate={{ opacity: 1, scale: 1 }} transition={{ delay: 0.3 }}>
          <CenterColumn p={30} gap={'32px'} backgroundColor={theme.colors.black['200']} borderRadius="24px" boxShadow="normal" width={['100%']} maxWidth={700}>
            {renderContent()}

            <CenterRow gap={16} flexWrap="wrap" width="100%" mt={4}>
              <Button
                bg="#28A745"
                flexGrow={1}
                onClick={() => {
                  accept({ onSuccess: onAccept, screeningId })
                }}
                disabled={fetchingInfo}
              >
                {accepting && <Spinner width="25px" />}
                {t('global:accept')}
              </Button>
              <Button bg="#DC3545" flexGrow={1} onClick={onOpen} disabled={fetchingInfo}>
                {t('global:decline')}
              </Button>
            </CenterRow>
          </CenterColumn>
        </motion.div>
        <Dialog sx={{ direction }} open={open} onClose={onClose}>
          <DialogTitle>
            <CenterRow width="100%" justifyContent="space-between">
              <Text>{t('global:areYourSureToDeclineTitle')}</Text>
              {/* <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton> */}
            </CenterRow>
          </DialogTitle>
          <DialogContent>
            <CenterColumn gap={2}>
              <Text variant="light">{t('global:reasonsToDecline')}</Text>
              <MuiTextField
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={t('global:theReason')}
                minRows={3}
                maxLength={500}
                fullWidth
                showCharacterCounter
              />
            </CenterColumn>
          </DialogContent>
          <DialogActions>
            <CenterRow gap={2}>
              <Button variant="secondary" onClick={onClose}>
                {t('global:cancel')}
              </Button>
              <Button
                onClick={() => {
                  decline({
                    screeningId,
                    reason,
                    onSuccess() {
                      onClose()
                      navigate('/')
                      showToast({
                        message: t('global:afterDecliningMessage'),
                        type: 'info',
                      })
                    },
                  })
                }}
              >
                {declining ? <Spinner width="25px" /> : t('global:confirm')}
              </Button>
            </CenterRow>
          </DialogActions>
        </Dialog>
      </CenterColumn>
    </motion.div>
  )
}
