import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { selectRedirectTo } from 'defaultLayout/slice/selectors'
import { createContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const AuthContext = createContext({})
type Props = {
  children: React.ReactNode
}
export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { actions, dispatch } = useDefaultLayoutSlice()

  useEffect(() => {
    const user_type = localStorage.getItem('user_type') || null

    let dashboardLink
    switch (String(user_type)) {
      case 'admin':
        dashboardLink = '/admin_panel/admin/parents'
        break
      case 'advisor':
        dashboardLink = '/admin_panel/advisor/applications'
        break
      case 'therapist':
        dashboardLink = '/profile/therapist/applications'
        break
      case 'parent':
        dashboardLink = '/profile/parent/therapy'
        break
    }

    if (pathname === '/' || pathname === '/login' || pathname.includes('signup')) {
      dispatch(actions.redirectUser(dashboardLink ?? '/'))
    }
  }, [pathname])

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>
}
