// Core
import { object, string, array, bool, func, shape } from 'prop-types'
// Instruments
import { Controller } from 'react-hook-form'
// Mui
import { Autocomplete, TextField } from '@mui/material'
// Utils
import { uid } from '../../../utils'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'
import { useEffect } from 'react'
import { theme } from 'utils/theme'

export const TextFieldAutocomplete = (props) => {
  if (!props.name || !props.control || !props.options) return null
  useEffect(() => {
    if (props.value && props.setValue) {
      props.setValue(props.name, props.value)
    }
  }, [JSON.stringify(props.value)])
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <div style={{ width: props?.width }} className={`${Styles.autocomplete_wrapper} ${props?.class_wrapper}`}>
            <label htmlFor={props.name}>
              {props.label}
              <span>{props?.requiredIcon}</span>
            </label>
            <Autocomplete
              {...field}
              value={field.value ?? props?.value}
              sx={props.sx ? { ...props.sx } : {}}
              freeSolo={props?.freeSolo}
              // autoSelect={true}
              clearOnBlur={true}
              disablePortal={props?.disablePortal}
              id={props.name}
              options={props?.options.map((option) => option)}
              getOptionLabel={(option) => {
                return typeof option === 'object' ? option.value || '' : option
              }}
              onChange={(event, option) => {
                if (event.target.innerText === 'Add custom') {
                  props.openCustomField(true)
                  return
                }

                if (props?.manualSetValue && event.target.innerText === 'Add custom') {
                  if (props?.isLicence) {
                    props?.manualSetValue({ value: option })
                  } else {
                    props?.manualSetValue({ value: option })
                  }
                } else {
                  props.setValue(props.name, option, { shouldValidate: true })
                  props?.customOnChange?.({ value: option })
                }
              }}
              disabled={props?.disabled}
              className={`${Styles.autocomplete_field} ${props?.class_field}`}
              renderOption={(params, option) => {
                const value = option.value.split('_')

                if (value.length === 1) {
                  return (
                    <li {...params} className={option?.disable ? Styles.disabled : params.className}>
                      {option.value.split('_')[0]}
                    </li>
                  )
                }

                if (value.length > 1) {
                  return (
                    <li {...params}>
                      <img width={20} src={`https://flagcdn.com/w20/${option.value.split('_')[0].toLowerCase()}.png`} alt="flag" />
                      {option.value.split('_')[1]}
                    </li>
                  )
                }
              }}
              renderInput={(params) => {
                const { value } = params.inputProps
                let newValue =
                  (value ? value : props?.defaultValue?.value || '').split('_').length > 1
                    ? (value ? value : props?.defaultValue?.value || '').split('_')[1]
                    : (value ? value : props?.defaultValue?.value || '').split('_')[0]
                const isImg = (value ? value : props?.defaultValue?.value || '').split('_').length > 1 ? (value ? value : props?.defaultValue?.value || '').split('_')[0] : null

                if (value === 'Add custom') {
                  newValue = ''
                }

                return (
                  <div ref={params.InputProps.ref}>
                    {isImg && <img width={20} src={`https://flagcdn.com/w20/${isImg.toLowerCase()}.png`} alt="flag_image" />}
                    <TextField
                      type="text"
                      sx={{
                        background: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.colors.black[300],
                        },
                      }}
                      {...params}
                      value={newValue || (props.isLicence ? props?.defaultValue?.value?.value : props?.defaultValue?.value) || ''}
                      autoFocus={props?.autoFocus}
                      placeholder={props.placeholder}
                    />
                  </div>
                )
              }}
            />
            {props?.error && (
              <div className={Styles.error_wraper}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                <div className={Styles.error}>{props?.error ? <span>{props.error.message}</span> : null}</div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

TextFieldAutocomplete.propTypes = {
  name: string,
  control: object,
  options: array,
  class_wrapper: string,
  label: string,
  requiredIcon: string,
  freeSolo: bool,
  disablePortal: bool,
  setValue: func,
  disabled: bool,
  class_field: string,
  autoFocus: bool,
  placeholder: string,
  error: shape({
    message: string,
  }),
}
