import { useState } from 'react'
import { useSelector } from 'react-redux'
import Styles from './styles.module.scss'
import { therapistActions, zoomActions } from '../../../actions'
import { setIsOpenZoomModal, setIsSelectedOpenZoomModal } from '../../../reducers/zoomSlice'
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { ConfirmationPopup, NoDataComponent } from '../../GeneralComponents'
import { ChildInfoPopup } from '../Popups/ChildInfo'

import { TablePagination, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { therapistNotificationTranslations } from 'data/locale.data'
import { useAlertController } from 'components/AlertMessage/useAlertController'
import { useTranslation } from 'react-i18next'
import { notify } from 'helpers'
import { defaultLayoutActions, useDefaultLayoutSlice } from 'defaultLayout/slice'
import { useAuth } from 'hooks/useAuth'
import { ScreeningsCardView } from './ScreeningsCardView'
import { Box, CenterRow } from 'components/Core'
import { useGetApplicationsTherapistQuery } from 'defaultLayout/slice/service'
import { SearchInput } from 'components/Core/SearchInput'
import { useSearchValues } from 'hooks/useSearchValues'
import { selectTherapistApplicationPageSearchValues } from 'defaultLayout/slice/selectors'

const headers = [
  { text: 'DATE CREATED', key: 'created_at', sorting: true },
  { text: 'NAME OF APPLIER' },
  { text: 'PROBLEM' },
  { text: 'BIRTHDAY' },
  { text: 'STATUS' },
  { text: 'MEETING TIME' },
  { text: 'SUBSCRIPTION TYPE' },
  { text: 'ACTION' },
]
/**
 * TherapistApplications component for managing therapist applications.
 * @returns {JSX.Element} TherapistApplications component.
 */
export const TherapistApplications = () => {
  const searchValues = useSelector(selectTherapistApplicationPageSearchValues)
  const { page, pageSize, changePageSize, goToPage, handleSearchQuery, handleSelectTab, handleSorting, searchInputValue, validSearchValues, setSearchInputValue } = useSearchValues(
    defaultLayoutActions.changeTherapistApplicationSearchValues,
    searchValues,
  )
  const { data, refetch, isFetching } = useGetApplicationsTherapistQuery({
    ...validSearchValues,
  })
  const { setZoomLeaveUrl, dispatch } = useDefaultLayoutSlice()
  const [selectedTab, setSelectedTab] = useState('')
  /**
   * Redux state
   */
  const { user } = useAuth()
  const loading = useSelector((state) => state.general.loading)

  /**
   * Component state
   */
  const [isOpenDeclineChildPopup, setIsOpenDeclineChildPopup] = useState(false)
  const [isOpenChildDetails, setIsOpenChildDetails] = useState(false)
  const [isAdult, setIsAdult] = useState(false)

  const [selectedApplicationId, setSelectedApplicationId] = useState(undefined)

  /**
   * Accepts a child's application and triggers notifications.
   * @param {string} screeningId - The ID of the screening application.
   */
  const handleAcceptChild = (screeningId) => {
    const data = {
      id: screeningId,
      action: () => refetch(),
      notification: therapistNotificationTranslations?.accepted?.en,
    }
    dispatch(therapistActions.acceptApplication(data))
  }

  /**
   * Opens the decline child confirmation popup.
   * @param {string} screeningId - The ID of the screening application.
   */
  const handleOpenDeclineChildPopup = (screeningId) => {
    setSelectedApplicationId(screeningId)
    setIsOpenDeclineChildPopup(true)
  }

  /**
   * Declines a child's application.
   * @param {object} data - Data containing decline reason and application ID.
   */
  const declineApplication = (data) => {
    const dataForDecline = {
      id: selectedApplicationId,
      payload: {
        decline_reason: data?.reason,
      },
      notification: therapistNotificationTranslations?.dicline?.en,
      action: () => {
        setIsOpenDeclineChildPopup(false)
        refetch()
      },
    }
    // // console.log(dataForDecline)
    dispatch(therapistActions.declineApplication(dataForDecline))
  }

  /**
   * Opens child details popup.
   * @param {string} screeningId - The ID of the screening application.
   * @param {boolean} isAdult - Flag indicating if the applicant is an adult.
   */
  const handleOpenChildDetails = (screeningId, isAdult) => {
    setSelectedApplicationId(screeningId)
    setIsOpenChildDetails(true)
    setIsAdult(isAdult)
  }

  /**
   * Closes child details popup.
   */
  const handleCloseChildDetails = () => {
    setIsOpenChildDetails(false)
  }

  /**
   * Content configuration for decline application popup.
   */
  const contentForDeclineApplicationPopup = {
    title: 'Are you sure you want to decline the apply?',
    secondaryText: {
      text: 'What are the reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_application__delete,
          onClick: declineApplication,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_application__cancel,
          onClick: () => setIsOpenDeclineChildPopup(false),
        },
      ],
    },
  }
  const navigate = useNavigate()
  const { t } = useTranslation()
  /**
   * Handles joining a Zoom meeting.
   * @param {string} zoomMeetingId - The ID of the Zoom meeting.
   */
  const handleJoinMeeting = (zoomMeetingId) => {
    const dataForZoom = {
      id: zoomMeetingId,
    }

    dispatch(zoomActions.getMeetingInformation(dataForZoom))
    dispatch(setIsOpenZoomModal())
    if (zoomMeetingId) {
      dispatch(setIsSelectedOpenZoomModal(zoomMeetingId))
      setZoomLeaveUrl()
      navigate(`/session?id=${zoomMeetingId}&isDiscoveryCall=true`)
    } else {
      notify(t('alertMessages:zoomMeetingNotFound'), true, false)
    }
  }

  /**
   * Click event handlers for various actions.
   */
  const onClickEvents = {
    acceptApplication: handleAcceptChild,
    declineApplication: handleOpenDeclineChildPopup,
    openUserDetails: handleOpenChildDetails,
    joinMeeting: handleJoinMeeting,
  }

  /**
   * Handles input change for search.
   * @param {Object} e - The input change event.
   */

  const belowMd = useMediaQuery('(max-width: 992px)')

  return (
    <Box px={[3, 3, 20, 20, 40]} py={[24]} className={Styles.main_container}>
      <div className={Styles.header}>
        <Box gap={10} flexWrap={['wrap']} className={Styles.header__first_container}>
          <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
            <h6>Applications</h6>
            <SearchInput
              setSearchInputValue={setSearchInputValue}
              value={searchInputValue}
              sx={{
                width: ['100%', '90%', '400px', '400px'],
                bgcolor: 'white',
              }}
              getValueChange={handleSearchQuery}
              placeholder="Search by name or email"
              size="small"
            />
          </CenterRow>
        </Box>
        <div className={Styles.tabs}>
          <p className={searchValues.status === '' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('')}>
            All
          </p>
          <p className={searchValues.status === 'waiting' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('waiting')}>
            Waiting for assignment
          </p>
          <p className={searchValues.status === 'accepted' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('accepted')}>
            Accepted
          </p>
        </div>
      </div>

      <Box minHeight="auto">
        {belowMd ? (
          <ScreeningsCardView isFetching={isFetching} screenings={data?.results ?? []} onClickEvents={onClickEvents} />
        ) : (
          <Table
            isFetching={isFetching}
            onSort={handleSorting}
            sort_by={searchValues.sort_by}
            sort_direction={searchValues.sort_direction}
            headers={headers}
            data={data?.results ?? []}
            refetch={refetch}
            userType={user?.user_type}
            userDataType={'application'}
            onClickEvents={onClickEvents}
          />
        )}
      </Box>
      {!isFetching && !data?.count && <NoDataComponent text={'There are no applications yet.'} />}
      <CenterRow justifyContent="flex-end" width="100%" pr={[0, 0, 50]}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, value) => goToPage(value)}
          onRowsPerPageChange={(event) => {
            changePageSize(+event.target.value)
            goToPage(0)
          }}
        />
      </CenterRow>
      {/* <CustomPagination dataArray={dataForPagination} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
      {isOpenDeclineChildPopup && (
        <ConfirmationPopup
          open={isOpenDeclineChildPopup}
          onClose={() => setIsOpenDeclineChildPopup(false)}
          content={contentForDeclineApplicationPopup}
          width={'436px'}
          isDeclinePopup={true}
        />
      )}
      {isOpenChildDetails && (
        <ChildInfoPopup open={isOpenChildDetails} onClose={handleCloseChildDetails} screeningId={selectedApplicationId} isTherapist={true} isAdult={isAdult} />
      )}
    </Box>
  )
}
