import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import type { ChargeData, ChildConsultation, Meetings, Order, PaginatedResponse, SearchValues, TherapistDetail, User } from 'utils/types/types'
type QueryArgs = {
  payload: {
    child_info: Object
    questionnaire: Object
    family_dynamics: Object
    parental_wellbeing: Object
  }
}

type GetParentsResponse = {
  id: number
  user: {
    id: number
    full_name: string
    email: string
    phone_number: string
    used_therapy_promo_code: boolean
  }
  profiles: []
  is_agree_to_receive_email: boolean
}

type GetPendingOrderArgs = { therapist_id: string | null | undefined; user_id: number | undefined }
const defaultLayoutService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    createNewProfile: build.mutation<{ id: number }, QueryArgs>({
      query: (data) => ({
        url: apiRoutes.parent.addChild,
        method: 'POST',
        data: data.payload,
        // headers: {
        //   Authorization: `Token ${data.token}`,
        // },
      }),
    }),
    getUser: build.query<User, number | string | undefined>({
      query: (userId) => ({
        url: `users/${userId}/`,
        method: 'GET',
      }),
      providesTags: ['get-user'],
      keepUnusedDataFor: 3600, // 1hr
    }),
    getUserByUid: build.mutation<User, string | undefined>({
      query: (firebaseUid) => ({
        url: `users/${firebaseUid}/`,
        method: 'GET',
      }),
    }),
    openNotification: build.mutation<string, string | undefined>({
      query: (notificationId) => ({
        url: apiRoutes.notifications.opened,
        method: 'PUT',
        data: {
          id: notificationId,
        },
      }),
    }),
    signUpUser: build.mutation<{ id: number }, { full_name: string; email: string; password: string }>({
      query: (data) => ({
        url: apiRoutes.user.signUp,
        method: 'POST',
        data,
      }),
    }),
    linkExistingUserWithPromoCode: build.mutation<string, { promo_code: string }>({
      query: (data) => ({
        url: apiRoutes.promoCode.linkExistingUser,
        method: 'POST',
        data,
      }),
    }),
    saveUserJoinReason: build.mutation<string, { join_reason: string }>({
      query: (data) => ({
        url: apiRoutes.joinReason,
        method: 'POST',
        data,
      }),
    }),
    onPaymentSuccess: build.mutation<string, ChargeData>({
      query: (data) => ({
        url: apiRoutes.payment.processCharge,
        method: 'POST',
        data,
      }),
    }),
    getLatestPendingOrder: build.query<Order, GetPendingOrderArgs>({
      query: (data) => ({
        url: `${apiRoutes.payment.getLatestPendingOrder}`,
        method: 'GET',
        params: data,
      }),
    }),
    getOrderById: build.query<Order, string | null>({
      query: (orderId) => ({
        url: `${apiRoutes.payment.getOrderById}${orderId}/`,
        method: 'GET',
      }),
    }),
    getZoomMeetings: build.query<PaginatedResponse<Meetings>, { page: number; page_size: number; search_query: string }>({
      query: (params) => ({
        url: `${apiRoutes.calcom.getMeetings}`,
        method: 'GET',
        params,
      }),
      providesTags: ['zoom-meetings'],
    }),
    getParentsAdmin: build.query<PaginatedResponse<GetParentsResponse>, { page: number; page_size: number; search_query: string }>({
      query: (params) => ({
        url: `parents/`,
        method: 'GET',
        params,
      }),
      providesTags: ['get-parents'],
    }),
    getApplicationsAdvisor: build.query<PaginatedResponse<ChildConsultation>, Partial<SearchValues>>({
      query: (params) => ({
        url: `landing/consultations/`,
        method: 'GET',
        params,
      }),
      providesTags: ['get-applications-advisor'],
    }),
    getInitialCalls: build.query<PaginatedResponse<any>, { page: number; page_size: number; search_query?: string; status?: string }>({
      query: (params) => ({
        url: apiRoutes.advisor.getInitialCalls,
        method: 'GET',
        params,
      }),
      providesTags: ['get-initial-calls'],
    }),
    getApplicationsTherapist: build.query<PaginatedResponse<any>, { page: number; page_size: number; search_query: string; status: string }>({
      query: (params) => ({
        url: `therapists/screenings/`,
        method: 'GET',
        params,
      }),
      providesTags: ['get-applications-therapist'],
    }),
    getTherapistMyPatients: build.query<PaginatedResponse<any>, Partial<SearchValues>>({
      query: (params) => ({
        url: `therapists/profiles/`,
        method: 'GET',
        params,
      }),
      providesTags: ['get-therapist-patients'],
    }),
    getTherapistList: build.query<PaginatedResponse<TherapistDetail>, { page: number; page_size: number; search_query: string }>({
      query: (params) => ({
        url: `therapists/all`,
        method: 'GET',
        params,
      }),
      providesTags: ['get-therapist-list'],
    }),
    getBookOnBehalf: build.mutation<any, { profile_id: number | string | undefined; user_id: number | string | undefined }>({
      query: (data) => ({
        url: `parents/get-parent-therapy/`,
        method: 'POST',
        data,
      }),
    }),
    enrollCourseWithPromo: build.mutation<any, { course_id: number | string | undefined; promo_code: string | null | undefined }>({
      query: (data) => ({
        url: `course/enroll/`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useOpenNotificationMutation,
  useGetUserByUidMutation,
  useCreateNewProfileMutation,
  useSignUpUserMutation,
  useLinkExistingUserWithPromoCodeMutation,
  useSaveUserJoinReasonMutation,
  useOnPaymentSuccessMutation,
  useGetLatestPendingOrderQuery,
  useGetZoomMeetingsQuery,
  useGetParentsAdminQuery,
  useGetApplicationsAdvisorQuery,
  useGetInitialCallsQuery,
  useGetOrderByIdQuery,
  useGetApplicationsTherapistQuery,
  useGetTherapistMyPatientsQuery,
  useGetTherapistListQuery,
  useGetBookOnBehalfMutation,
  useEnrollCourseWithPromoMutation,
} = defaultLayoutService
