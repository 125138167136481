//Styles
import Styles from './styles.module.scss'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
//MUI
import { InputBase, TablePagination, useMediaQuery } from '@mui/material'
//Actions
import { advisorOnbordingActions, adminActions } from '../../../actions'
//Components
import { Table } from '../../Applies/Table'
import { SetApiModal } from '../../AdminPanel/AdvisorsTab/SetApiKey'
import { ConfirmationPopup, Notifications, NoDataComponent, CustomPagination } from '../../GeneralComponents'
import { EditTherapistInfoModal } from '../../AdminPanel/Popups/EditTherapistInfo'
import { TherapistInfoModal } from './TherapistsInfoModal'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { ParentsPopup } from '../../AdminPanel/Popups'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/check_updated.svg'
//Utils
import { paginateArray } from '../../../utils'
import { selectFirebaseToken, selectTherapistListPageSearchValues } from 'defaultLayout/slice/selectors'
import { TherapistsCardView } from './AdvisorTherapistCardView'
import { Box, CenterRow } from 'components/Core'
import { useSearchValues } from 'hooks/useSearchValues'
import { defaultLayoutActions } from 'defaultLayout/slice'
import { useGetTherapistListQuery } from 'defaultLayout/slice/service'
import { SearchInput } from 'components/Core/SearchInput'

/**
 * AdvisorTherapists component for managing therapists data.
 * @returns {JSX.Element} AdvisorTherapists component JSX.
 */
export const AdvisorTherapists = () => {
  // const dispatch = useDispatch()
  const searchValues = useSelector(selectTherapistListPageSearchValues)
  const { changePageSize, dispatch, goToPage, handleSearchQuery, handleSelectTab, handleSorting, page, pageSize, searchInputValue, setSearchInputValue, validSearchValues } =
    useSearchValues(defaultLayoutActions.changeTherapistListSearchValues, searchValues)
  const { isFetching, data, refetch } = useGetTherapistListQuery(validSearchValues)
  // const firebaseToken = useSelector(selectFirebaseToken)
  // State management
  /**
   * All therapists fetched from Redux store.
   * @type {Array}
   */
  // const allTherapists = useSelector((state) => state.advisorOnbording.allTherapists)

  /**
   * User data fetched from Redux store.
   * @type {object}
   */
  const user = useSelector((state) => state.auth)

  /**
   * Loading state for indicating data fetching status.
   * @type {boolean}
   */
  const loading = useSelector((state) => state.general.loading)

  /**
   * State for managing visibility of API key modal.
   * @type {[boolean, function]}
   */
  const [isSetAPIKeyOpen, setIsSetAPIKeyOpen] = useState(false)

  /**
   * State for managing visibility of decline therapist confirmation popup.
   * @type {[boolean, function]}
   */
  const [isOpenDeclineTherapist, setIsOpenDeclineTherapist] = useState(false)

  /**
   * State for managing visibility of edit therapist info modal.
   * @type {[boolean, function]}
   */
  const [isOpenEditTherapist, setIsOpenEditTherapist] = useState(false)

  /**
   * State for managing visibility of therapist info modal.
   * @type {[boolean, function]}
   */
  const [isOpenTherapistInfo, setIsOpenTherapistInfo] = useState(false)

  /**
   * State for managing visibility of notification popup.
   * @type {[boolean, function]}
   */
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false)

  /**
   * State for managing visibility of parents popup.
   * @type {[boolean, function]}
   */
  const [isOpenParentsPopup, setIsOpenParentsPopup] = useState(false)

  /**
   * State for storing paginated therapist data.
   * @type {Array}
   */
  const [therapists, setTherapists] = useState([])

  /**
   * State for storing selected therapist ID.
   * @type {string | undefined}
   */
  const [selectedTherapistId, setSelectedTherapistId] = useState(undefined)

  /**
   * State for storing approved therapist data.
   * @type {object | undefined}
   */
  const [approvedTherapist, setApprovedTherapist] = useState(undefined)

  /**
   * State for storing selected therapist details.
   * @type {object | undefined}
   */
  const [selectedTherapist, setSelectedTherapist] = useState(undefined)

  /**
   * State for storing parents data associated with a therapist.
   * @type {Array}
   */
  const [parents, setParents] = useState([])

  /**
   * State for managing table headers.
   * @type {Array}
   */
  const [headers, setHeaders] = useState([])

  /**
   * State for managing current page in pagination.
   * @type {[number, function]}
   */
  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Opens the API key modal for a therapist.
   * @param {string} id - Therapist ID.
   */
  const handleOpenAPIKey = (id) => {
    setSelectedTherapistId(id)
    setIsSetAPIKeyOpen(true)
  }

  /**
   * Closes the API key modal and triggers data refetch.
   */
  const handleCloseAPIKey = () => {
    setIsSetAPIKeyOpen(false)
    refetch()
  }

  /**
   * Opens the decline therapist confirmation popup.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenDeclineTherapistPopup = (therapistId) => {
    setIsOpenDeclineTherapist(true)
    setSelectedTherapistId(therapistId)
  }

  /**
   * Closes the decline therapist confirmation popup and triggers data refetch if status is 200.
   * @param {number} status - HTTP status code.
   */
  const handleCloseDeclineTherapistPopup = (status) => {
    setIsOpenDeclineTherapist(false)

    if (status === 200) {
      refetch()
    }
  }

  /**
   * Declines a therapist application.
   * @param {object} formData - Form data including reason for declining.
   */
  const declineTherapist = async (formData) => {
    const data = {
      id: selectedTherapistId,
      payload: {
        application_message: formData.reason,
      },
    }

    dispatch(adminActions.declineTherapist(data)).then((res) => handleCloseDeclineTherapistPopup(res.status))
  }

  /**
   * Approves a therapist application.
   * @param {string} therapistId - Therapist ID.
   */
  const approveTherapist = async (therapistId) => {
    const data = {
      id: therapistId,
    }

    dispatch(adminActions.approveTherapist(data)).then((res) => {
      refetch()
      openNotification()
    })
  }

  /**
   * Opens the edit therapist info modal.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenEditTherapistPopup = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenEditTherapist(true)
  }

  /**
   * Closes the edit therapist info modal and triggers data refetch after a delay.
   */
  const handleCloseEditTherapistPopup = () => {
    setIsOpenEditTherapist(false)
    setTimeout(() => {
      refetch()
    }, 2000)
  }

  /**
   * Opens the therapist info modal.
   * @param {string} therapistId - Therapist ID.
   */
  const handleOpenTherapistInfo = (therapistId) => {
    setSelectedTherapistId(therapistId)
    setIsOpenTherapistInfo(true)
  }

  /**
   * Closes the therapist info modal.
   */
  const handleCloseTherapistInfo = () => setIsOpenTherapistInfo(false)

  /**
   * Opens the parents popup modal.
   * @param {Array} parents - List of parents associated with the therapist.
   * @param {object} therapist - Therapist object containing details like full name and email.
   */
  const handleOpenParentsPopup = (parents, therapist) => {
    const selectedTherapist = {
      full_name: therapist?.full_name,
      email: therapist?.email,
    }
    setParents(parents)
    setSelectedTherapist(selectedTherapist)
    setIsOpenParentsPopup(true)
  }

  /**
   * Closes the parents popup modal.
   */
  const handleCloseParentsPopup = () => {
    setIsOpenParentsPopup(false)
    setParents([])
    setSelectedTherapist(0)
  }

  /**
   * Sets table headers based on the user's role.
   */
  useEffect(() => {
    if (user?.user?.user?.user_type === 'admin') {
      setHeaders([
        { text: 'FULL NAME', sorting: false },
        { text: 'COUNTRY', sorting: false },
        { text: 'LANGUAGE', sorting: false },
        { text: 'YEARS EXPERIENCE', sorting: false },
        { text: 'QUALIFICATION', sorting: false },
        { text: 'PROFILES', sorting: false },
        { text: 'API KEY', sorting: false },
        // { text: 'Impersonate Therapist', sorting: false },
        { text: 'ACTION', sorting: false },
      ])
    } else {
      setHeaders([
        { text: 'FULL NAME', sorting: false },
        { text: 'COUNTRY', sorting: false },
        { text: 'LANGUAGE', sorting: false },
        { text: 'YEARS EXPERIENCE', sorting: false },
        { text: 'QUALIFICATION', sorting: false },
      ])
    }
  }, [JSON.stringify(user)])

  /**
   * Content configuration for the decline therapist confirmation popup.
   * @type {object}
   */
  const contentForDeclineTherapistPopup = {
    title: 'Are you sure you want to decline the apply?',
    secondaryText: {
      text: 'What are reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_therapist__delete,
          onClick: declineTherapist,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_therapist__cancel,
          onClick: handleCloseDeclineTherapistPopup,
        },
      ],
    },
  }

  /**
   * Event handlers for various actions on therapist data.
   * @type {object}
   */
  const onClickEvents = {
    setApiKey: handleOpenAPIKey,
    acceptTherapist: approveTherapist,
    declineTherapist: handleOpenDeclineTherapistPopup,
    editProfile: handleOpenEditTherapistPopup,
    openUserDetails: handleOpenTherapistInfo,
    openParentsPopup: handleOpenParentsPopup,
  }

  /**
   * Handles input change for search functionality.
   * @param {Event} e - Input change event.
   */
  // const handleChangeInput = (e) => {
  //   const inputValue = e.target.value.toLowerCase()

  //   const filteredArray = allTherapists.filter(
  //     (therapist) => therapist.user.full_name.toLowerCase().includes(inputValue) || therapist.user.email.toLowerCase().includes(inputValue),
  //   )

  //   const paginatedData = paginateArray(filteredArray)
  //   if (paginatedData.length) {
  //     setCurrentPage(1)
  //     setTherapists([...paginatedData[currentPage - 1]])
  //   } else {
  //     setTherapists([])
  //   }
  // }

  /**
   * Closes the notification popup.
   */
  const closeNotification = () => setIsNotificationDisplayed(false)

  /**
   * Opens the notification popup.
   */
  const openNotification = () => setIsNotificationDisplayed(true)
  const belowMd = useMediaQuery('(max-width: 992px)')
  return (
    <Box px={[3, 3, 10, 20, 40]} py={[24]} className={Styles.main_container}>
      {!loading && isNotificationDisplayed && (
        <Notifications
          icon={<TickIcon />}
          title={'Application accepted'}
          secondaryText={'You have accepted the profile of'}
          userName={`${approvedTherapist}.`}
          onClose={closeNotification}
        />
      )}
      <div className={Styles.header}>
        <Box gap={10} flexWrap={['wrap']} className={Styles.header__first_block}>
          <CenterRow gap={[2]} flexWrap="wrap" width="100%" justifyContent="space-between">
            <h6>Therapists</h6>
            <SearchInput
              setSearchInputValue={setSearchInputValue}
              value={searchInputValue}
              sx={{
                width: ['100%', '90%', '400px', '400px'],
                bgcolor: 'white',
              }}
              getValueChange={handleSearchQuery}
              placeholder="Search by name or email"
              size="small"
            />
          </CenterRow>
        </Box>
      </div>

      <Box minHeight="auto">
        {/* {belowMd ? (
            <TherapistsCardView onClickEvents={onClickEvents} therapists={therapists} />
          ) : ( */}
        <Table
          isFetching={isFetching}
          onSort={handleSorting}
          sort_by={searchValues.sort_by}
          sort_direction={searchValues.sort_direction}
          data={data?.results ?? []}
          headers={headers}
          // refetch={refetch}
          userType={user?.user?.user?.user_type}
          userDataType={'therapist'}
          onClickEvents={onClickEvents}
          showNotification={setApprovedTherapist}
        />
        {/* )} */}
      </Box>

      {!isFetching && !data?.count && <NoDataComponent text={'There are no applications yet.'} />}
      <CenterRow justifyContent="flex-end" width="100%" pr={[0, 0, 50]}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data?.count || 0}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={(_, value) => goToPage(value)}
          onRowsPerPageChange={(event) => {
            changePageSize(+event.target.value)
            goToPage(0)
          }}
        />
      </CenterRow>
      {isSetAPIKeyOpen && <SetApiModal therapistId={selectedTherapistId} open={isSetAPIKeyOpen} onClose={handleCloseAPIKey} />}
      {isOpenDeclineTherapist && (
        <ConfirmationPopup
          open={isOpenDeclineTherapist}
          onClose={handleCloseDeclineTherapistPopup}
          content={contentForDeclineTherapistPopup}
          width={'436px'}
          isDeclinePopup={true}
        />
      )}
      {isOpenEditTherapist && <EditTherapistInfoModal onClose={handleCloseEditTherapistPopup} open={isOpenEditTherapist} selectedTherapistId={selectedTherapistId} />}
      {isOpenTherapistInfo && <TherapistInfoModal open={isOpenTherapistInfo} onClose={handleCloseTherapistInfo} therapistId={selectedTherapistId} />}
      {isOpenParentsPopup && <ParentsPopup open={isOpenParentsPopup} onClose={handleCloseParentsPopup} parents={parents} userType={'Therapist'} user={selectedTherapist} />}
    </Box>
  )
}
