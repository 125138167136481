import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AdminZoomMeetingsState } from './type'
import type { SearchValues } from 'utils/types/types'
const initialState: AdminZoomMeetingsState = {
  searchValues: {
    page: 0,
    page_size: 5,
    search_query: '',
    status: '',
    sort_by: '',
    sort_direction: '',
  },
}
export const advisorApplicationsSlice = createSlice({
  name: 'adminZoomMeetings',
  initialState,
  reducers: {
    changeSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.searchValues = action.payload
    },
  },
})

export const adminZoomMeetingsActions = advisorApplicationsSlice.actions
export const adminZoomMeetingsReducer = advisorApplicationsSlice.reducer
