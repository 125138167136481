import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { usePagination } from 'hooks/usePagination'
import { useGetApplicationsAdvisorQuery } from 'defaultLayout/slice/service'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import type { SearchValues } from 'utils/types/types'

export const useSearchValues = (changeSearchValues: ActionCreatorWithPayload<Partial<SearchValues>>, searchValues: Partial<SearchValues>) => {
  const [searchInputValue, setSearchInputValue] = useState('')
  const dispatch = useDispatch()
  const updateSearchValues = (value: Partial<SearchValues>) => {
    dispatch(changeSearchValues(value))
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const initialPage = useMemo(() => {
    if (searchParams.get('page') && Number(searchParams.get('page')) > 0) {
      return Number(searchParams.get('page')) - 1
    }
    if (searchValues.page && searchValues.page > 0) {
      return searchValues.page - 1
    }
    return 0
  }, [searchParams, searchValues.page])
  const { page, goToPage, pageSize, changePageSize } = usePagination({
    initialPageSize: searchValues.page_size ?? 5,
    initialPage,
  })

  const validSearchValues = useMemo(() => {
    // filter out empty search values
    const newSearchValues = { ...searchValues }
    Object.keys(newSearchValues).forEach((key) => {
      const key_string = key as keyof SearchValues
      if (newSearchValues[key_string] === '' || newSearchValues[key_string] === undefined || newSearchValues[key_string] === null) {
        delete newSearchValues[key_string]
      }
    })

    return newSearchValues
  }, [searchValues])

  useEffect(() => {
    if (searchValues.search_query) {
      setSearchInputValue(searchValues.search_query)
    }
  }, [searchValues])

  useEffect(() => {
    updateSearchValues({
      ...searchValues,
      page: page + 1,
      page_size: pageSize,
    })
    searchParams.set('page', `${page + 1}`)
    searchParams.set('page_size', `${pageSize}`)
    setSearchParams(searchParams)
  }, [page, pageSize])

  /**Initialize search values */
  useEffect(() => {
    if (!searchValues.search_query && !searchValues.sort_by && !searchValues.status && !searchValues.sort_direction) {
      const initialParams = new URLSearchParams(window.location.search)
      updateSearchValues({
        ...searchValues,
        page: initialParams.get('page') ? Number(initialParams.get('page')) : 0,
        page_size: initialParams.get('page_size') ? Number(initialParams.get('page_size')) : 5,
        search_query: initialParams.get('search_query') || '',
        status: initialParams.get('status') || '',
        sort_by: initialParams.get('sort_by') || '',
        sort_direction: initialParams.get('sort_direction') || '',
      })
    }
  }, [])

  const handleSelectTab = (status: string) => {
    updateSearchValues({
      ...searchValues,
      status,
    })
    if (!status) {
      searchParams.delete('status')
    }
    setSearchParams(searchParams)
    goToPage(0)
  }

  const handleSearchQuery = (value: string) => {
    updateSearchValues({
      ...searchValues,
      search_query: value,
    })
    if (!value) {
      searchParams.delete('search_query')
    }
    goToPage(0)
    setSearchParams(searchParams)
  }

  const handleSorting = (sort_by: string, sort_direction: string) => {
    updateSearchValues({
      ...searchValues,
      sort_by,
      sort_direction,
    })
  }

  useEffect(() => {
    Object.keys(validSearchValues ?? {}).map((key) => {
      if (key !== 'page' && key !== 'page_size') {
        searchParams.set(key, validSearchValues[key as keyof SearchValues] as string)
      }
    })

    setSearchParams(searchParams)
    // To do: also add delete search params when the value is empty here
  }, [validSearchValues])
  return {
    searchValues,
    page,
    pageSize,
    searchParams,
    searchInputValue,
    validSearchValues,
    setSearchInputValue,
    goToPage,
    changePageSize,
    setSearchParams,
    updateSearchValues,
    dispatch,
    handleSelectTab,
    handleSearchQuery,
    handleSorting,
  }
}
