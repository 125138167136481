import { useSearchValues } from 'hooks/useSearchValues'
import { useSelector } from 'react-redux'
import { selectAdvisorApplicationsSearchValues } from '../selector'
import { advisorApplicationsActions } from '..'

export const useAdvisorApplicationsSearchValues = () => {
  const storeSearchValues = useSelector(selectAdvisorApplicationsSearchValues)
  const params = useSearchValues(advisorApplicationsActions.changeSearchValues, storeSearchValues)

  return params
}
