import { defaultLayoutActions } from 'defaultLayout/slice'
import history from 'lib/history'
import { toast } from 'react-toastify'
import { setIsAuth, setUser } from 'reducers/authSlice'
import { setLoading } from 'reducers/generalSlice'
import { setTimeZone } from 'reducers/profileSlice'
import { deleteCookie, setCookie, userTimeZone } from 'utils'

export const getFormatedLocalTime = (timestamp) => {
  const date = new Date(timestamp)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

  return formattedHours + ':' + formattedMinutes + ' ' + period
}

export const capitalizeWord = (word) => {
  if (!word) {
    return
  } else {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
}

//SET NOTIFICATION
export const notify = (message, isError = true, autoClose = true) => {
  if (isError)
    toast.error(message, {
      theme: 'light',
      autoClose,
    })
  if (!isError)
    toast.success(message, {
      theme: 'light',
      autoClose,
    })
}

export const stopImpersonation = () => {
  localStorage.removeItem('token')
  deleteCookie('user')
  window.location.href = '/'
  localStorage.removeItem('impersonated')
}
