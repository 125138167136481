/**
 * Functional component for handling onboarding questions.
 *
 * @component
 */

//Core
import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowLeft } from '../../../theme/assets/icons/arrow_left_icon.svg'
//Data
import { adultOnboardingQuestions, mandatory_questions_parent_onboarding } from '../../../data'
// Instruments
import { useForm } from 'react-hook-form'
// Components
import { TextAreaField } from '../../GeneralComponents'
// Actions
import { advisorOnbordingActions } from '../../../actions'
import {
  changeStep,
  decreaseCurrentQuestionId,
  increaseCurrentQuestionId,
  setAdditionalAdvisorComments,
  setAdvisorRecommendations,
  setCurrentQuestionId,
  setHistoryAndBackgroundComments,
  setParentGoalsObjectives,
} from '../../../reducers/advisorOnbordingSlice'
import {
  selectAdditionalAdvisorComments,
  selectAdvisorRecommendations,
  selectCurrentQuestionId,
  selectFilledQuestions,
  selectHistoryAndBackgroundComments,
  selectOnBoardingStep,
  selectParentGoalsObjectives,
} from './selectors'
import { selectOneConsultation } from 'reducers/selectors/general'

export const Questions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const storeAdditionalAdvisorComments = useSelector(selectAdditionalAdvisorComments)
  // const storeHistoryAndBackground = useSelector(selectHistoryAndBackgroundComments)
  // const storeParentGoalsObjectives = useSelector(selectParentGoalsObjectives)
  // const storeAdvisorRecommendations = useSelector(selectAdvisorRecommendations)

  const onboardingStep = useSelector(selectOnBoardingStep)

  const filledQuestions = useSelector(selectFilledQuestions)
  const currentQuestionId = useSelector(selectCurrentQuestionId)
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const consultation_id = searchParams.get('consultation_id')
  const savedOnboardingQuestions = localStorage.getItem(consultation_id)

  const [onboardingQuestions, setOnboardingQuestions] = useState([])

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
  }, [searchParams.get('lng')])

  useEffect(() => {
    if (consultation_id) {
      const data = {
        meeting_id: consultation_id,
      }
      dispatch(advisorOnbordingActions.getOneApply(data))
    }
  }, [consultation_id])
  const consultation = useSelector(selectOneConsultation)
  const preparedOnboardingQuestions = useMemo(() => {
    if (consultation?.questions?.length) {
      return consultation?.questions?.reduce((acc, curr) => {
        if (curr?.advisor_comment) {
          acc[`${curr?.id}_advisor_comment`] = curr?.advisor_comment
        }

        if (curr?.historyAndBackground) {
          acc[`historyAndBackground`] = curr?.historyAndBackground
        }
        if (curr?.parentGoalsObjectives) {
          acc[`parentGoalsObjectives`] = curr?.parentGoalsObjectives
        }
        if (curr?.advisorRecommendations) {
          acc[`advisorRecommendations`] = curr?.advisorRecommendations
        }
        if (curr?.additional_advisor_comments) {
          acc[`additionalAdvisorComments`] = curr?.additional_advisor_comments
        }
        return acc
      }, {})
    }
    return {}
  }, [consultation?.questions])

  const { handleSubmit, control, getValues, register, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      ...JSON.parse(savedOnboardingQuestions ?? '{}'),
    },
  })
  useEffect(() => {
    const values = {
      ...preparedOnboardingQuestions,
      ...getValues(),
      parentGoalsObjectives: getValues('parentGoalsObjectives') ?? preparedOnboardingQuestions?.parentGoalsObjectives,
    }
    localStorage.setItem(consultation_id, JSON.stringify(values))
    Object.keys(values).forEach((key) => {
      setValue(key, values[key])
    })
  }, [preparedOnboardingQuestions, getValues()])

  /**
   * Navigates to the previous page.
   */
  const goBack = () => {
    switch (onboardingStep) {
      case 'parent-goals':
        navigate(-1)
        break
      case 'history-and-background':
        dispatch(changeStep('parent-goals'))
        break
      case 'onboarding-questions':
        if (currentQuestionId !== 0) {
          dispatch(decreaseCurrentQuestionId())
        } else {
          dispatch(changeStep('history-and-background'))
        }
        break
      case 'advisor-recommendations':
        dispatch(changeStep('onboarding-questions'))
        // Last question
        dispatch(setCurrentQuestionId(onboardingQuestions?.length - 1))
        break
      case 'additional-advisor-comments':
        dispatch(changeStep('advisor-recommendations'))
        break
    }
  }
  /**
   * Initializes onboarding questions based on the profile type.
   */
  useEffect(() => {
    if (filledQuestions.length) {
      setOnboardingQuestions(filledQuestions)
    } else {
      if (type === 'adult') {
        setOnboardingQuestions(adultOnboardingQuestions)
      } else {
        const childQuestionnaire = mandatory_questions_parent_onboarding?.map((item) => ({ id: item?.id, category: item?.category, main_statement: item?.main_statement }))
        setOnboardingQuestions(childQuestionnaire)
      }
    }
  }, [type, JSON.stringify(filledQuestions)])

  /**
   * Advances to the next onboarding question or submits the final answer.
   */

  const next = (data) => {
    const updatedQuestion = {
      ...onboardingQuestions[currentQuestionId],
      advisor_comment: data[`${currentQuestionId}_advisor_comment`] || '',
    }
    const additionalAdvisorComments = getValues('additionalAdvisorComments')
    const historyAndBackground = getValues('historyAndBackground')
    const parentGoalsObjectives = getValues('parentGoalsObjectives')
    const advisorRecommendations = getValues('advisorRecommendations')

    const finalQuestions = [...onboardingQuestions.slice(0, currentQuestionId), updatedQuestion, ...onboardingQuestions.slice(currentQuestionId + 1)]
    setOnboardingQuestions(finalQuestions)

    localStorage.setItem(consultation_id, JSON.stringify(data))
    switch (onboardingStep) {
      case 'parent-goals':
        dispatch(changeStep('history-and-background'))
        break
      case 'history-and-background':
        dispatch(changeStep('onboarding-questions'))
        break
      case 'onboarding-questions':
        const amountofQuestions = type === 'adult' ? adultOnboardingQuestions?.length : mandatory_questions_parent_onboarding?.length
        if (currentQuestionId + 1 === amountofQuestions) {
          dispatch(changeStep('advisor-recommendations'))
        } else {
          dispatch(increaseCurrentQuestionId())
        }
        break
      case 'advisor-recommendations':
        dispatch(changeStep('additional-advisor-comments'))
        break
      case 'additional-advisor-comments':
        dispatch(advisorOnbordingActions.setQuestions(finalQuestions))
        dispatch(setAdditionalAdvisorComments(additionalAdvisorComments))
        dispatch(setHistoryAndBackgroundComments(historyAndBackground))
        dispatch(setParentGoalsObjectives(parentGoalsObjectives))
        dispatch(setAdvisorRecommendations(advisorRecommendations))
        navigate(`summary?id=${consultation_id}`)
    }
  }
  const steps = () => {
    switch (onboardingStep) {
      case 'parent-goals':
        return (
          <div className={Styles.main_container__content__item}>
            <h4>1. Parent Goals/Objective</h4>
            <TextAreaField
              key="parentGoalsObjectives"
              // defaultValue={storeParentGoalsObjectives}
              control={control}
              placeholder="Parent Goals/Objective"
              inputClass={Styles.input_text_area}
              className={Styles.input_container}
              name="parentGoalsObjectives"
              rows={4}
            />
          </div>
        )
      case 'history-and-background':
        return (
          <div className={Styles.main_container__content__item}>
            <h4>2. History and Background</h4>
            <TextAreaField
              key="historyAndBackground"
              // defaultValue={storeHistoryAndBackground}
              control={control}
              placeholder="Please provide relevant historical information and background details"
              inputClass={Styles.input_text_area}
              className={Styles.input_container}
              name="historyAndBackground"
              // label="History and Background"
              // maxLength={2000}
              rows={4}
            />
          </div>
        )
      case 'onboarding-questions':
        return onboardingQuestions?.map(
          (item) =>
            currentQuestionId === item?.id && (
              <div className={Styles.main_container__content__item} key={item?.id}>
                <h4>
                  {item?.id + 3}. {item?.category}
                </h4>
                <h6>{item?.main_statement}</h6>
                <TextAreaField
                  defaultValue={item?.advisor_comment || ''}
                  control={control}
                  placeholder={'Your comment here'}
                  inputClass={Styles.input_text_area}
                  class={Styles.input_container}
                  name={`${item?.id}_advisor_comment`}
                  label={' '}
                  autoFocus
                />
              </div>
            ),
        )
      case 'advisor-recommendations':
        return (
          <div className={Styles.main_container__content__item}>
            <h4>12. Advisor Recommendations</h4>
            <TextAreaField
              key={'advisorRecommendations' + new Date().getTime()}
              // defaultValue={storeAdvisorRecommendations}
              control={control}
              placeholder="Advisor Recommendations"
              inputClass={Styles.input_text_area}
              className={Styles.input_container}
              name="advisorRecommendations"
              rows={4}
            />
          </div>
        )
      case 'additional-advisor-comments':
        return (
          <div className={Styles.main_container__content__item}>
            <h4>13. Additional Advisor Comments</h4>
            <TextAreaField
              key="additionalAdvisorComments"
              // defaultValue={storeAdditionalAdvisorComments}
              control={control}
              placeholder="Additional Advisor Comments"
              inputClass={Styles.input_text_area}
              className={Styles.input_container}
              name="additionalAdvisorComments"
              rows={4}
            />
          </div>
        )
    }
  }
  return (
    <div className={Styles.main_container}>
      <div className={Styles.main_container__onboarding_container}>
        <div className={Styles.main_container__content}>
          {steps()}

          <div className={Styles.btns}>
            <div
              className={`${
                onboardingQuestions?.length < (type === 'adult' ? adultOnboardingQuestions?.length : mandatory_questions_parent_onboarding?.length)
                  ? Styles.btns__back_disabled
                  : Styles.btns__back
              }`}
              onClick={goBack}
            >
              <ArrowLeft />
              <span>Back</span>
            </div>
            <button className={true ? Styles.btns__next : Styles.btns__next_disable} onClick={handleSubmit(next)}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
