/**
 * @fileoverview Configuration for Redux Store.
 * @module store
 */

// Core
import { configureStore } from '@reduxjs/toolkit'
// Reducers
import generalReducer from '../reducers/generalSlice'
import authReducer from '../reducers/authSlice'
import adminReducer from '../reducers/adminSlice'
import profileReducer from '../reducers/profileSlice'
import chatSliceReducer from '../reducers/chatSlice'
import advisorOnbordingReducer from '../reducers/advisorOnbordingSlice'
import parentSlice from '../reducers/parentSlice'
import therapistSlice from '../reducers/therapistSlice'
import verificationSlice from '../reducers/verificationSlice'
import zoomSlice from '../reducers/zoomSlice'
import { rootServiceApi } from './service'
import { setupListeners } from '@reduxjs/toolkit/query'
import { calComeReducer } from '../components/GeneralComponents/CalCom/slice'
import { defaultLayoutReducer } from 'defaultLayout/slice'
import { questionnaireReducers } from 'components/Auth/ParentOnboarding/QuestionsPartMandatory/slice'
import { treatmentPlaneReducer } from 'pages/ShardPage/TreatmentPlan/slice'
import { parentTaskReducer } from 'pages/ShardPage/ParentTask/slice'
import { parentOnboardingReduce } from 'components/ParentFlow/Therapy/NewParentOnboarding/slice'
import { advisorApplicationsReducer } from 'components/Applies/slice'
import { advisorProfilesReducer } from 'components/AdvisorFlow/Parents/slice'
import { adminClientsReducer } from 'components/AdminPanel/Parents/slice'
import { adminZoomMeetingsReducer } from 'components/AdminPanel/MeetingsTab/slice'

/**
 * Configuration for Redux Store.
 * @type {Store}
 * @property {Reducer} general - Reducer for general data: error message, loaging states etc..
 * @property {Reducer} auth - Reducer for authentication: data for registration, data about current user etc..
 * @property {Reducer} admin - Reducer for data connected to admin user.
 * @property {Reducer} profile - Reducer for profiles.
 * @property {Reducer} chatSystem - Reducer for chat system.
 * @property {Reducer} advisorOnboarding - Reducer for onboarding proccess.
 * @property {Reducer} parent - Reducer for data connected to parent user.
 * @property {Reducer} therapist - Reducer for data connected to therapist user.
 * @property {Reducer} verification - Reducer for verification.
 * @property {Reducer} zoom - Reducer for Zoom data.
 */

const store = configureStore({
  reducer: {
    general: generalReducer,
    auth: authReducer,
    admin: adminReducer,
    profile: profileReducer,
    chatSystem: chatSliceReducer,
    advisorOnbording: advisorOnbordingReducer,
    parent: parentSlice,
    therapist: therapistSlice,
    verification: verificationSlice,
    zoom: zoomSlice,
    defaultLayout: defaultLayoutReducer,
    advisorApplications: advisorApplicationsReducer,
    advisorProfiles: advisorProfilesReducer,
    adminClients: adminClientsReducer,
    adminZoomMeetings: adminZoomMeetingsReducer,
    questionnaire: questionnaireReducers,
    calCom: calComeReducer,
    treatmentPlan: treatmentPlaneReducer,
    parentTask: parentTaskReducer,
    parentOnboarding: parentOnboardingReduce,
    [rootServiceApi.reducerPath]: rootServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(rootServiceApi.middleware),
})

setupListeners(store.dispatch)

export default store
