import React from 'react'
import { IconButton, Modal } from '@mui/material'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Box, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import { Close } from '@mui/icons-material'
import { theme } from 'utils/theme'
import { SubscriptionType } from 'constants/index'

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  padding: 2rem;
  max-width: 800px;
  width: 90%;
  outline: none;
`

const Title = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #2d3748;
  font-weight: bold;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
`

const Card = styled(motion.div)`
  border: 2px solid ${(props) => props.color};
  border-radius: 15px;
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%;
  background: white;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`

const IconContainer = styled(Container)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
`

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #4a5568;

  &:before {
    content: '✓';
    color: #48bb78;
    margin-right: 0.5rem;
  }
`

const CloseButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 2rem;
  background: #f7fafc;
  border: none;
  border-radius: 8px;
  color: #4a5568;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #edf2f7;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

const StyledIcon = styled.svg`
  color: ${(props) => props.color ?? theme.colors.primary[900]};
  width: 28px;
  height: 28px;
`

type Props = {
  open: boolean
  onClose: () => void
  onSelect: (type: string) => void
}
export const ScreeningTypeSelectorModal = ({ open, onClose, onSelect }: Props) => {
  return (
    <Modal
      open={open}
      aria-labelledby="screening-type-modal"
      aria-describedby="select-screening-type"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <Box gap={32} variant="modal-container">
        <CenterColumn>
          <CenterRow width="100%" justifyContent="space-between">
            <Title>Choose a Plan</Title>
            {/* <IconButton onClick={onClose}>
              <Close />
            </IconButton> */}
          </CenterRow>
          <Text variant="light">Select a plan for the screening.</Text>
        </CenterColumn>
        <Grid>
          <Card color={theme.colors.primary[900]} onClick={() => onSelect(SubscriptionType.PAY_AS_YOU_GO)}>
            <TitleContainer>
              <StyledIcon viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </StyledIcon>
              <CardTitle>Pay As You Go</CardTitle>
            </TitleContainer>
          </Card>

          <Card color={theme.colors.typePurpure[300]} onClick={() => onSelect(SubscriptionType.MEMBERSHIP)}>
            <TitleContainer>
              <StyledIcon viewBox="0 0 24 24" fill="none" stroke="currentColor" color={theme.colors.typePurpure[300]} strokeWidth="2">
                <path d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
              </StyledIcon>
              <CardTitle>Membership</CardTitle>
            </TitleContainer>
          </Card>
        </Grid>
      </Box>
    </Modal>
  )
}
