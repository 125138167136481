import { useEffect, useMemo, useState } from 'react'
import { useGetAllCoursesQuery, useGetCoursesTotalQuery, useGetTherapistPerformanceQuery } from '../service'
import { Box, CenterColumn, CenterRow, Container, Flex, Text } from 'components/Core'
import { DateRangePopover } from '../DatePopover'
import { RangeKeyDict } from 'react-date-range'
import { IconButton, Tooltip } from '@mui/material'
import { usePagination } from 'hooks/usePagination'
import { ArrowBack, QuestionMark } from '@mui/icons-material'
import { MuiTable } from 'components/MuiTable/MuiTable'
import { currencyFormatter } from 'utils/utils'
import { CommonTable } from 'components/MuiTable/CommonTable'
import type { Course } from 'utils/types/types'
import { AdminCourseDetail } from './AdminCourseDetail'

export const CoursesList = () => {
  const [selectedCourse, setSelectedCourse] = useState<Course>()
  const { page, setTotalItems, goToPage, totalPages, pageSize, changePageSize } = usePagination({
    initialPageSize: 10,
    initialPage: 0,
  })
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const { data, isFetching } = useGetAllCoursesQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
    page: page + 1,
    page_size: pageSize,
  })

  const courseTotals = useGetCoursesTotalQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
  })
  const handleSelect = (ranges: RangeKeyDict) => {
    goToPage(0)
    setStartDate(ranges.selection.startDate!)
    setEndDate(ranges.selection.endDate!)
  }
  useEffect(() => {
    if (data?.count) setTotalItems(data?.count)
  }, [data?.count])

  const headers = useMemo(() => {
    return [
      { field: 'title', label: 'Course Title', isSortable: true },

      {
        field: 'author.full_name',
        label: 'Author',
        isSortable: true,
      },
      {
        field: 'purchased_courses',
        label: '# Number of Enrollments',
        isSortable: true,
        counter: true,
      },
      {
        field: 'feedbacks',
        label: '# Number of feedbacks',
        isSortable: true,
        counter: true,
      },
      {
        field: 'price',
        label: 'Price',
        isSortable: true,
        render: (value: number) =>
          currencyFormatter({
            amount: value,
          }),
      },
      {
        field: 'total_revenue',
        label: 'Total Revenue',
        isSortable: true,
        render: (value: number) =>
          currencyFormatter({
            amount: value,
          }),
      },
    ]
  }, [])
  return (
    <CenterColumn maxWidth={'2400px'} gap="20px" p={2} pt={20}>
      {selectedCourse ? (
        <Flex flexDirection={'column'}>
          <Box ml="60px" maxWidth="1400px">
            <IconButton onClick={() => setSelectedCourse(undefined)}>
              <ArrowBack />
            </IconButton>
          </Box>
          <AdminCourseDetail course={selectedCourse} courseId={selectedCourse.id} fetchingCourse={false} />
        </Flex>
      ) : (
        <>
          <CenterRow width="100%" justifyContent="space-between">
            <Text variant="heading2">Courses</Text>
            <DateRangePopover
              handleClear={() => {
                goToPage(0)
                setStartDate(undefined)
                setEndDate(undefined)
              }}
              endDate={endDate}
              startDate={startDate}
              handelSelect={handleSelect}
            />
          </CenterRow>
          <CenterRow bg="white" p={3} borderRadius={1} width="100%" gap="30px">
            <CenterRow gap="2px">
              <Text width="auto" variant="heading4">
                Overall totals :
              </Text>
            </CenterRow>
            <CenterRow gap={10} borderRight={1} pr={3}>
              {/* Total session */}
              <Text fontWeight="bold">Total number of courses:</Text>
              <Text>{courseTotals?.data?.total_courses}</Text>
            </CenterRow>
            <CenterRow gap={10} borderRight={1} pr={3}>
              {/* Total session */}
              <Text fontWeight="bold">Total Purchased Courses:</Text>
              <Text>{courseTotals?.data?.total_purchased}</Text>
            </CenterRow>

            <CenterRow gap={10}>
              {/* Total session */}
              <Text fontWeight="bold">Total Revenue:</Text>
              <Text>
                {currencyFormatter({
                  amount: courseTotals?.data?.total_revenue,
                })}
              </Text>
            </CenterRow>
          </CenterRow>
          <CommonTable<Course>
            onRowClick={setSelectedCourse}
            data={data?.results}
            headers={headers}
            isFetching={isFetching}
            changePageSize={changePageSize}
            pageSize={pageSize}
            goToPage={goToPage}
            page={page}
          />
        </>
      )}
    </CenterColumn>
  )
}
