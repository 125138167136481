//Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
import { setHistory } from '../../../reducers/therapistSlice'
import WebSocketService from '../../../websocket'
import { Box, Flex, Text } from 'components/Core'
import { selectFirebaseToken } from 'defaultLayout/slice/selectors'
import { useAuth } from 'hooks/useAuth'
import { SessionsList } from 'components/ParentFlow/Therapy/SessionsList'

/**
 * ChildProfile component for displaying detailed information about a child profile.
 * @returns {JSX.Element} ChildProfile component.
 */
export const ChildProfileTherapistHistory = ({ profile_id }) => {
  const { profileId } = useParams()
  const firebaseToken = useSelector(selectFirebaseToken)

  const dispatch = useDispatch()

  /**
   * Redux state
   */
  // const user = useSelector((state) => state.auth.user)
  const { user } = useAuth()
  const application = useSelector((state) => state.therapist.oneApplication)
  const history = useSelector((state) => state.therapist.history)

  /**
   * Component state
   */
  const [webSocketInstance, setWebSocketInstance] = useState(null)

  /**
   * Effect to initialize WebSocket connection when user token is available.
   */
  useEffect(() => {
    if (firebaseToken && user) {
      setWebSocketInstance(new WebSocketService('sessions', 0, user))
    }
  }, [firebaseToken, JSON.stringify(user)])

  /**
   * Effect to connect WebSocket instance when initialized.
   */
  useEffect(() => {
    if (webSocketInstance) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  /**
   * Effect to fetch sessions history using WebSocket and update Redux state.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance.state() !== 1) {
      const waitForSocketConnection = (callback) => {
        setTimeout(() => {
          if (webSocketInstance.state() === 1) {
            callback()
          } else {
            waitForSocketConnection(callback)
          }
        }, 100)
      }

      waitForSocketConnection(() => {
        webSocketInstance.setSessionsCallback((data) => {
          dispatch(setHistory(data))
        })
        webSocketInstance?.fetchSessionsHistory(profileId)
      })
    } else if (webSocketInstance && webSocketInstance.state() === 1) {
      webSocketInstance.setSessionsCallback((data) => {
        dispatch(setHistory(data))
      })
      webSocketInstance?.fetchSessionsHistory(profileId)
    }
  }, [webSocketInstance, profileId])
  return (
    <Flex bg="white" height={['100%']} flexDirection="column" gap={'24px'}>
      <div className={Styles.content_div}>
        <Box p={[2, 2, 20]} className={Styles.history_container}>
          <div className={Styles.header}>
            <Text variant="body1">History</Text>
            <span>
              {history?.length} {history?.length === 1 ? 'meeting' : 'meetings'}
            </span>
          </div>
          <SessionsList profile_id={profile_id} history={history} clientName={application?.full_name} parent={application?.parent} userRole={'therapist'} />
        </Box>
      </div>
    </Flex>
  )
}
