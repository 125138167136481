import { Box, Typography, Modal, Divider, Skeleton, IconButton, Paper, List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetSessionLengthPriceMutation } from '../service'
import { useCallback, useEffect } from 'react'
import { Button, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import PsychologyIcon from '@mui/icons-material/Psychology'
import HandshakeIcon from '@mui/icons-material/Handshake'
import { theme } from 'utils/theme'
import { Refresh } from '@mui/icons-material'
import { TherapistDetail } from 'utils/types/types'
import { SubscriptionType } from 'constants/index'
import { MembershipTherapistDetail } from 'components/MembershipComponents/MemebrshipTherapistDetail'

type Props = {
  open: boolean
  onClose: () => void
  onContinue: () => void
  therapist: TherapistDetail
  direction: 'rtl' | 'ltr'
}
export const DiscoveryCallPriceModal = ({ open, onClose, onContinue, therapist, direction }: Props) => {
  const { t } = useTranslation()
  const [getSessionPrices, { data, isLoading, isError }] = useGetSessionLengthPriceMutation()
  useEffect(() => {
    if (therapist?.id) {
      getSessionPrices({
        therapist_id: therapist.id,
      })
    }
  }, [])

  const formatDuration = useCallback((duration: number) => {
    // if duration is 60min or greater format it to hours
    if (duration >= 60) {
      const hours = Math.floor(duration / 60)
      const minutes = duration % 60
      return `${hours}${t('global:hours')} ${minutes ? minutes + t('global:mins') : ''}`
    }
    return `${duration}${t('global:mins')}`
  }, [])
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 600 },
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          direction,
        }}
      >
        <Text variant="heading2" maxWidth="100%" mb={3}>
          {t('global:bookCall')}
        </Text>

        <Box mb={4}>
          <CenterColumn gap={16}>
            <Text variant="heading4" color="typePurpure.300" mb={2}>
              {t('global:freeConsult')}
            </Text>
            <Text lineHeight={1.3}>
              {t('global:firstCall')} {therapist?.user?.full_name} {t('global:sessionDesc')}
            </Text>
            <CenterColumn gap={'4px'}>
              <BenefitItem icon={<TaskAltIcon />} text={t('global:benefit1')} />
              <BenefitItem icon={<PsychologyIcon />} text={t('global:benefit2')} />
              <BenefitItem icon={<HandshakeIcon />} text={t('global:benefit3')} />
            </CenterColumn>
          </CenterColumn>

          <Divider sx={{ my: 3 }} />

          <Box mb={2}>
            <Typography variant="h6">{t('global:futurePrices')}:</Typography>
          </Box>

          {isLoading ? (
            <Skeleton
              sx={{
                borderRadius: '8px',
              }}
              variant="rectangular"
              height={250}
            />
          ) : isError ? (
            <Container py={16} px={8} gap={8} flexDirection="column">
              <>
                <Text color="error" textAlign="center">
                  {t('global:errorOccurred')}
                </Text>
                <IconButton
                  color="secondary"
                  sx={{
                    bgcolor: theme.colors.primary[100],
                  }}
                  onClick={() => {
                    getSessionPrices({
                      therapist_id: therapist.id,
                    })
                  }}
                >
                  <Refresh />
                </IconButton>
              </>
            </Container>
          ) : therapist?.subscription?.type === SubscriptionType.MEMBERSHIP ? (
            <MembershipTherapistDetail subscription={therapist.subscription} />
          ) : (
            <Paper elevation={0} style={{ maxHeight: 250, overflow: 'auto' }}>
              <List sx={{ boxShadow: 'none', p: 8 }}>
                {data?.data?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 2.5,
                      bgcolor: 'grey.50',
                      borderRadius: 1,
                    }}
                    height="auto"
                    mb={index < data.data.length - 1 ? '8px' : 'none'}
                  >
                    <Box>
                      <Typography fontWeight="500">{formatDuration(item.duration)}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('global:regSession')}
                      </Typography>
                    </Box>
                    <Typography fontWeight="bold" color="#8450A0">
                      ${item.price}
                    </Typography>
                  </Box>
                ))}
              </List>
            </Paper>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button variant="secondary" onClick={onClose}>
            {t('global:cancel')}
          </Button>
          <Button variant="primary" onClick={onContinue}>
            {t('global:bookBtn')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

type BenefitItemProps = {
  icon: React.ReactNode
  text: string
}
export const BenefitItem = ({ icon, text }: BenefitItemProps) => (
  <CenterRow gap={2}>
    <Box
      sx={{
        color: '#8450A0',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon}
    </Box>
    <Text color={theme.colors.black[600]}>{text}</Text>
  </CenterRow>
)
