import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdvisorProfilesState } from './type'
import type { SearchValues } from 'utils/types/types'
const initialState: AdvisorProfilesState = {
  searchValues: {
    page: 0,
    page_size: 5,
    search_query: '',
    status: '',
    sort_by: '',
    sort_direction: '',
  },
  refetch: false,
}
export const advisorApplicationsSlice = createSlice({
  name: 'advisorProfiles',
  initialState,
  reducers: {
    changeSearchValues: (state, action: PayloadAction<Partial<SearchValues>>) => {
      state.searchValues = action.payload
    },
    changeRefetch: (state, action: PayloadAction<boolean>) => {
      state.refetch = action.payload
    },
  },
})

export const advisorProfilesActions = advisorApplicationsSlice.actions
export const advisorProfilesReducer = advisorApplicationsSlice.reducer
