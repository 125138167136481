//MUI
import { Tooltip, Fade, ClickAwayListener } from '@mui/material'
import { Box } from 'components/Core'

export const CustomTooltip = ({ title, placement, children, isOpen, setIsOpen, customClass = '', boxProps = {} }) => {
  const handleMouseEnter = () => {
    if (setIsOpen) {
      setIsOpen(true)
    }
  }

  const handleMouseLeave = () => {
    if (setIsOpen) {
      setIsOpen(false)
    }
  }
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (setIsOpen) setIsOpen(false)
      }}
    >
      <Box {...boxProps} className={customClass} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Tooltip
          disableFocusListener={true}
          disableHoverListener={false}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#EEE9F3',
                fontFamily: 'Poppins_Regular, sans-serif',
                color: '#6D308D',
              },
            },
          }}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={title}
          placement={placement}
          open={isOpen}
          onClose={() => {
            if (setIsOpen) setIsOpen(false)
          }}
        >
          {children}
        </Tooltip>
      </Box>
    </ClickAwayListener>
  )
}
