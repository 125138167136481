import { createSelector } from '@reduxjs/toolkit'

const rootState = (state: any) => state

export const selectGeneralStates = createSelector([rootState], (state) => state?.general)
export const selectAdvisorOnboarding = createSelector([rootState], (state) => state?.advisorOnbording)
export const selectParentStates = createSelector([rootState], (state) => state?.parent)
export const selectIsLoading = createSelector([selectGeneralStates], (state) => state?.loading)
export const selectAllParentProfiles = createSelector([selectParentStates], (state) => state?.allProfiles)
export const selectOneConsultation = createSelector([selectAdvisorOnboarding], (state) => state?.oneApply)
