//Core
import { shape, string, number } from 'prop-types'

//Components
import { Spinner } from '../../Spinner'

//Styles
import Styles from './styles.module.scss'

//Utils
import { convertBytes } from '../../../../../utils'
import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'

export const FileAttachmentLoader = ({ file }) => {
  const [progress, setProgress] = useState(() => {
    // Get stored progress from localStorage
    const storedProgress = localStorage.getItem(`upload-progress-${file.id}`)
    return storedProgress ? Number(storedProgress) : 0
  })

  useEffect(() => {
    const UPLOAD_SPEED = (15 * 1024 * 1024) / 8000
    const totalUploadTime = file.size / UPLOAD_SPEED
    const UPDATE_INTERVAL = 100
    const progressIncrement = (UPDATE_INTERVAL / totalUploadTime) * 100

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress >= 99 ? prevProgress : Math.min(99, prevProgress + progressIncrement)
        // Store progress in localStorage
        localStorage.setItem(`upload-progress-${file.id}`, String(newProgress))
        return newProgress
      })
    }, UPDATE_INTERVAL)

    return () => clearInterval(timer)
  }, [file.size, file.id])

  useEffect(() => {
    if (file.progress >= 100) {
      setTimeout(() => {
        setProgress(100)
        localStorage.removeItem(`upload-progress-${file.id}`)
      }, 1000)
    }
  }, [file.progress, progress, file.id])

  // Cleanup localStorage when component unmounts
  useEffect(() => {
    return () => {
      if (progress === 100) {
        localStorage.removeItem(`upload-progress-${file.id}`)
      }
    }
  }, [progress, file.id])

  return (
    <div className={Styles.fileAttachmentLoader}>
      <div className={Styles.fileAttachmentLoader__content}>
        <div>
          <CircularProgressWithLabel value={progress} />
        </div>
        <div className={Styles.fileAttachmentLoader__text}>
          <span>{file.name}</span>
          <p>{convertBytes(file.size)}</p>
        </div>
      </div>
    </div>
  )
}

FileAttachmentLoader.propTypes = {
  file: shape({
    name: string,
    size: number,
    url: string,
    id: string,
  }),
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}
