import { FC } from 'react'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ChatRoom } from 'pages/NewChatPage/useChat'
import { Box, CenterColumn, CenterRow, Text } from 'components/Core'

// const StyledChip = styled(Chip)(({ theme }) => ({
//   borderRadius: '16px',
//   backgroundColor: theme.palette.primary.light,
//   '& .MuiChip-label': {
//     fontWeight: 500,
//   },
// }))

interface ChatNameDisplayProps {
  selectedChat: ChatRoom
  user: {
    user: {
      user_type: string
    }
  }
}

export const ChatNameDisplay: FC<ChatNameDisplayProps> = ({ selectedChat, user }) => {
  if (selectedChat.room_name) {
    return (
      <CenterRow flexWrap="wrap" gap={8} width="100%">
        <Text truncateLines={1}>{selectedChat.room_name}</Text>
      </CenterRow>
    )
  }

  if (selectedChat.participants.length) {
    return (
      <Stack alignItems="center" flexWrap="wrap" direction={'row'} spacing={1}>
        {selectedChat.participants.map((p, index) => (
          <Text key={index + p.user_name} truncateLines={2}>
            {p.user_name}
          </Text>
        ))}
      </Stack>
    )
  }

  if (user.user.user_type === 'parent') {
    return <Text truncateLines={1}>{selectedChat.parent_name}</Text>
  }

  return <Text truncateLines={1}>{selectedChat.parent_name}</Text>
}
