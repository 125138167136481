import { Button, CenterColumn, CenterRow, Chip, Container, Flex, Text } from 'components/Core'
import { CalcomBookingView } from 'components/ParentFlow/Therapy/NewParentOnboarding/CalcomBookingView'
import { FlowContainer } from 'components/ParentFlow/Therapy/NewParentOnboarding/FlowContainer'
import { RecordingConsent } from 'components/ParentFlow/Therapy/NewParentOnboarding/RecordingConsent/RecordingConsent'
import { selectSessionBookingLink } from 'defaultLayout/slice/selectors'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetSubscriptionQuery } from './service'
import { Step, StepLabel, Stepper, Tab, Tabs, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import { Avatar, Box, Paper, Typography } from '@mui/material'
import { VideoCall, Schedule, Person, Dashboard } from '@mui/icons-material'
import { Session } from 'utils/types/types'
import { returnSessionStatus } from 'utils/utils'
import { NoCreditsLeft } from './NoCreditsLeft'
import { checkIfDateIsTomorrowOrAfter } from 'utils'
import { v4 as uuid4 } from 'uuid'
import { t } from 'i18next'
import { CustomTooltip } from 'components/GeneralComponents'
import { notify } from 'helpers'
import { setIsSelectedOpenZoomModal } from 'reducers/zoomSlice'
import { useContentBundle } from 'hooks/useContentBundle'
import en from 'components/ParentFlow/Therapy/HistoryRecord/locale/en.json'
import ar from 'components/ParentFlow/Therapy/HistoryRecord/locale/ar.json'
import { PaymentPage } from 'pages/PaymentPages'
import { apiRoutes } from 'apiRoutes'
import { useDisclosure } from 'hooks/useDisclosure'
import { useAuth } from 'hooks/useAuth'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { MembershipInfoModal } from 'components/MembershipComponents/MembershipInfoModal'
import { Spinner } from 'components/ChatSystem/Common/Spinner'
import { Direction } from 'components/Core/common/types'
import { theme } from 'utils/theme'

export const MembershipBooking = () => {
  const { user } = useAuth()
  const [orderDetails, setOrderDetails] = useState<any>({})
  const [bookingSuccess, setBookingSuccess] = useState(false)
  const [metadataForRecording, setMetadataForRecording] = useState<object>({})
  const [openBookAGainOrGoToDashboard, setOpenBookAGainOrGoToDashboard] = useState(false)
  const [hideBookAgain, setHideBookAgain] = useState(false)
  const [searchParams] = useSearchParams()
  const bookingLink = useSelector(selectSessionBookingLink)
  const subscription_id = searchParams.get('subscription_id')
  const { data, refetch, isLoading } = useGetSubscriptionQuery(Number(subscription_id), { skip: !subscription_id, refetchOnMountOrArgChange: true })
  const navigate = useNavigate()
  useContentBundle({
    englishContent: en,
    arabicContent: ar,
    key: 'history_record',
  })

  useEffect(() => {
    if (Number(data?.credits) <= 0) {
      setOrderDetails({
        amount: data?.price,
        currency: 'usd',
        full_name: user.full_name,
        phone_number: user?.phone_number,
        email: user.email,
        description: 'Membership Credit Purchase',
        order_id: data?.id,
        transaction_id: uuid4(),
        created_at: new Date(),
        user_id: user.id,
      })
      setHideBookAgain(true)
    }
  }, [data?.credits])

  useEffect(() => {
    if (Number(data?.credits) > 0) {
      setOpenBookAGainOrGoToDashboard(bookingSuccess)
    }
  }, [data, bookingSuccess])
  const { onClose, onOpen, open } = useDisclosure()
  const membershipInModalController = useDisclosure()
  const aboveLg = useMediaQuery('(min-width: 1200px)')
  const { direction } = useGetLanguage()
  return (
    <Container direction={direction} bg="white" flexWrap={['wrap']} gap={8} px={8} alignItems="flex-start" minHeight={'100%'}>
      {isLoading ? (
        <Container height={'90vh'}>
          <Spinner />
        </Container>
      ) : !data?.order?.id ? (
        <Container height="90vh" display="flex" alignItems="center" justifyContent="center">
          <CenterColumn gap={24} p={8} maxWidth={600} width="100%" textAlign="center">
            <Typography variant="h4" color="secondary.main" gutterBottom>
              {t('global:membership:noSubscriptionFound') as string}
            </Typography>

            <Box>
              <Typography variant="body1" color="text.secondary" mb={3}>
                {t('global:membership:transactionFailed') as string}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('global:membership:pleaseRetry') as string}
              </Typography>
            </Box>

            <Box display="flex" gap={3} width="100%" justifyContent="center">
              <Button variant="secondary" onClick={() => navigate('/')}>
                <Dashboard />
                {t('global:membership:backToDashboard')}
              </Button>
              {/* <Button onClick={() => membershipInModalController.onOpen()}>
                <Schedule />
                {t('global:membership:retrySubscription')}
              </Button> */}
            </Box>
          </CenterColumn>
        </Container>
      ) : Number(data?.credits) <= 0 ? (
        <NoCreditsLeft handleRenewal={() => membershipInModalController.onOpen()} />
      ) : openBookAGainOrGoToDashboard ? (
        <Container height="100vh">
          <CenterColumn gap={52} p={8} maxWidth={600} width="100%">
            <CenterColumn gap={8} textAlign="center">
              <Typography variant="h4" color="secondary.main" gutterBottom>
                {t('global:membership:sessionBookedSuccessfully') as string}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {t('global:membership:bookOrReturn') as string}
              </Typography>
            </CenterColumn>

            <CenterColumn gap={3} width="100%">
              {!hideBookAgain && (
                <Button
                  width="100%"
                  onClick={() => {
                    setOpenBookAGainOrGoToDashboard(false)
                    setBookingSuccess(false)
                  }}
                >
                  <Schedule />
                  {t('global:membership:bookAnOtherSession') as string}
                </Button>
              )}

              <Button color="black.600" variant="ghost" width="100%" onClick={() => navigate('/')}>
                {t('global:membership:gotoDashboard') as string}
              </Button>
            </CenterColumn>
          </CenterColumn>
        </Container>
      ) : (
        <>
          <FlowContainer
            hideScrollBar={aboveLg}
            overflowY="scroll"
            maxHeight={['auto', 'auto', 'auto', '91vh']}
            borderRight={1}
            minHeight={707}
            py={20}
            pb={30}
            alignItems="center"
            flexGrow={1}
          >
            <CenterColumn flexDirection={['column']} gap={10} px={[1, 1, 2]} width={['100%', '100%', '100%', '100%', '90%', '73%']}>
              <RecordingConsent title={t('global:membership:bookYourSessions')} direction={direction} setMetaData={setMetadataForRecording} />
              <CreditStepper direction={direction} sessions={data?.sessions} creditsLeft={data?.credits || 0} totalCredits={data?.order?.credits || 4} />
            </CenterColumn>
            <CalcomBookingView
              onSuccess={() => {
                refetch()
                setBookingSuccess(true)
              }}
              subscription_id={subscription_id}
              metadata={metadataForRecording}
              link={bookingLink || (searchParams.get('link') as string)}
            />
          </FlowContainer>
          <SessionsList sessions={data?.sessions || []} />
        </>
      )}
      <MembershipInfoModal
        {...membershipInModalController}
        subscription={data}
        onProceedToPayment={() => {
          onOpen()
          membershipInModalController.onClose()
        }}
      />
      {open && (
        <PaymentPage
          // This needed to bypass ts error as the PaymentPage component is a jsx component not tsx
          openPaymentExpiresPopup={() => {}}
          webhookUrl={apiRoutes.subscription.webhookUrl}
          isOpen={open}
          closePopup={onClose}
          orderDetails={orderDetails}
          showTopLeftHeader={false}
          redirectUrl={`${process.env.REACT_APP_SITE_URL}/membership-booking?link=${bookingLink || (searchParams.get('link') as string)}&subscription_id=${subscription_id}}`}
        />
      )}
    </Container>
  )
}

type CreditStepperProps = {
  totalCredits?: number
  creditsLeft: number
  sessions: Array<Session> | undefined
  direction: Direction
}
const CreditStepper = ({ totalCredits = 4, creditsLeft, sessions, direction }: CreditStepperProps) => {
  const plannedSessions = useMemo(() => sessions?.filter((session) => session.get_status?.toLocaleLowerCase()?.includes('planned')), [sessions])
  const getSessionTime = (index: number) => {
    try {
      const session = plannedSessions?.[index]
      if (!session) return ''
      const startDate = new Date(session.start_time)
      const endDate = new Date(session.end_time)
      const formattedStartTime = format(startDate, 'MMM dd, yyyy • HH:mm')
      const formattedEndTime = format(endDate, 'HH:mm')
      return `${formattedStartTime} - ${formattedEndTime}`
    } catch (error) {
      return '-'
    }
  }
  const belowLg = useMediaQuery('(max-width: 1024px)')
  const totalSessionLength = useMemo(() => Number(plannedSessions?.length ?? 0) + creditsLeft, [plannedSessions, totalCredits])
  return (
    <CenterColumn pt={16} gap={8} width="100%">
      <CenterColumn justifyContent="space-between" mb={2}>
        <Text variant="heading4">{t('global:membership:yourSessionsTimeline')}</Text>
        <Text variant="light">
          {creditsLeft} {t('global:membership:sessionRemaining')}
        </Text>
      </CenterColumn>

      <Paper elevation={0} style={{ maxWidth: '100%', overflowX: 'scroll' }}>
        <Stepper
          sx={{
            '& .MuiStepConnector-line': {
              borderRight: direction === 'rtl' ? `1px solid ${theme.colors.black[600]}` : 'none',
              borderLeft: direction === 'ltr' ? `1px solid ${theme.colors.black[600]}` : 'none',
            },
            minWidth: totalSessionLength <= totalCredits ? 'auto' : '75vw',
          }}
          orientation={belowLg ? 'vertical' : 'horizontal'}
          variant="outlined"
          activeStep={Number(plannedSessions?.length)}
        >
          {[...Array(totalSessionLength)].map((_, index) => (
            <Step key={index} completed={index < Number(plannedSessions?.length)}>
              <StepLabel optional={<Text variant="light">{getSessionTime(index)}</Text>}>
                <CenterRow gap={'4px'}>
                  <Text px={1}>{t('global:session')}</Text> {index + 1}
                </CenterRow>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </CenterColumn>
  )
}

type SessionsListProps = {
  sessions: Array<Session>
}
const SessionsList = ({ sessions }: SessionsListProps) => {
  const [activeTab, setActiveTab] = useState<'upcoming' | 'completed' | 'canceled'>('upcoming')
  const aboveLg = useMediaQuery('(min-width: 1200px)')
  const filteredSessions = useMemo(() => {
    switch (activeTab) {
      case 'upcoming':
        return sessions.filter((session) => ['Planned', 'In progress'].includes(session.get_status))
      case 'completed':
        return sessions.filter((session) => session.get_status === 'Completed')
      case 'canceled':
        return sessions.filter((session) => session.get_status.toLowerCase().includes('cancel'))
    }
  }, [sessions, activeTab])

  return (
    <Flex
      hideScrollBar={aboveLg}
      maxHeight={['auto', 'auto', 'auto', '91vh']}
      overflowY="scroll"
      pb={40}
      flexDirection="column"
      px={8}
      gap={2}
      pt="20px"
      minWidth={350}
      bg="white"
      width={['100%', '100%', '100%', '100%', 'auto']}
    >
      <Tabs textColor="secondary" indicatorColor="secondary" value={activeTab} onChange={(_, value) => setActiveTab(value)} sx={{ mb: 3 }}>
        <Tab label={t('global:upcoming') as string} value="upcoming" />
        <Tab label={t('global:completed') as string} value="completed" />
        <Tab label={t('global:canceled') as string} value="canceled" />
      </Tabs>

      <CenterColumn>
        {filteredSessions.length ? (
          filteredSessions.map((session) => <SessionCard key={session.id} session={session} />)
        ) : (
          <Text textAlign={'center'} variant="light">
            {t('global:noSessionFound')}
          </Text>
        )}
      </CenterColumn>
    </Flex>
  )
}
const SessionCard = ({ session }: { session: Session }) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      mb: 2,
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 2,
      transition: 'all 0.2s',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        gap: 8,
      },
    }}
  >
    <CenterRow justifyContent="flex-end">
      <Box width={['max-content']} mb={'8px'}>
        <Chip value={t(`global:${session?.get_status?.replace(' ', '')}`) as string} textTransform="capitalize" type={returnSessionStatus(session?.get_status)} />
      </Box>
    </CenterRow>
    <Box display="flex" alignItems="center" gap={2} mb={2}>
      <Avatar src={session?.organizer?.profile_pic} alt={session?.organizer?.full_name} sx={{ width: 48, height: 48 }} />
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">
          {session?.organizer?.full_name}
        </Typography>
        <Text variant="body2" color="text.secondary">
          {t('global:membership:organizer')}
        </Text>
      </Box>
    </Box>

    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={1}>
        <Schedule color="secondary" />
        <Typography>
          {/* {`${convertTime(session?.start_time)} - ${convertTime(session?.end_time)}`} */}
          {format(new Date(session?.start_time), 'MMM dd, yyyy • HH:mm')} -{format(new Date(session?.end_time), 'HH:mm')}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Person color="secondary" />
        <Typography>{session?.client?.full_name}</Typography>
      </Box>

      <CenterRow>
        <JoinAction session={session} />
        {/* <Button width="100%" variant="secondary">
               
                {session?.zoom_meeting ? 'Join Meeting' : 'In-person Session'}
              </Button> */}
      </CenterRow>
    </Box>
  </Paper>
)

type JoinActionProps = {
  session: Session
}
const JoinAction = ({ session }: JoinActionProps) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  let status = session?.get_status
  let btnType
  let btnText
  let isDisabled
  let isPayment

  switch (status) {
    case 'Completed':
      break
    case 'Planned':
      btnType = 'violet'
      btnText = t('history_record:btns:join')
      break
    case 'In progress':
      btnType = 'violet'
      btnText = t('history_record:btns:join')
      break
    case 'Pending Payment':
      btnType = 'green'
      btnText = t('history_record:btns:pay')
      isPayment = true
      break
    case 'Canceled - Payment Canceled':
      break
    default:
      break
  }

  const isDateTomorrowOrAfter = checkIfDateIsTomorrowOrAfter(session?.start_time)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const handleClick = () => {
    const meetingId = session?.zoom_meeting_id
    const navigateToSession = () => {
      if (meetingId) {
        navigate(`/session?id=${session?.zoom_meeting_id}&isMembershipSession=true`)
      } else {
        notify(t('alertMessages:zoomMeetingNotFound'), true, false)
      }
    }
    switch (session?.get_status) {
      case 'Completed':
        break
      case 'Planned':
        dispatch(setIsSelectedOpenZoomModal(session?.zoom_meeting_id))
        navigateToSession()
        break
      case 'In progress':
        dispatch(setIsSelectedOpenZoomModal(session?.zoom_meeting_id))
        navigateToSession()
        break
      case 'Canceled':
        break
      default:
        break
    }
  }
  return btnType ? (
    <CenterRow mt={16} width="100%" justifyContent="flex-end">
      {isDateTomorrowOrAfter && (status === 'Planned' || status === 'In progress') && (
        <CustomTooltip
          boxProps={{
            width: '100%',
          }}
          isOpen={isTooltipOpen}
          setIsOpen={setIsTooltipOpen}
          title={`${t('history_record:tooltip')}`}
          placement="top"
        >
          <Button width="100%" onClick={() => setIsTooltipOpen(true)} disabled={isDateTomorrowOrAfter}>
            <VideoCall sx={{ color: 'white' }} />
            {btnText}
          </Button>
        </CustomTooltip>
      )}
      {(!isDateTomorrowOrAfter || status === 'Pending Payment') && (
        <Button width="100%" onClick={() => handleClick()}>
          <VideoCall sx={{ color: 'white' }} />
          {btnText}
        </Button>
      )}
    </CenterRow>
  ) : null
}
