import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'
import { Subscription } from 'utils/types/types'

export const membershipService = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscription: build.query<Subscription, number>({
      query: (id) => ({
        url: `${apiRoutes.subscription.get}/${id}/`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetSubscriptionQuery } = membershipService
