// Core
import { useSelector, useDispatch } from 'react-redux'
// Routes
import { useNavigate } from 'react-router-dom'
// Actions
import { settingsActions, profileActions } from '../../../actions'
// Utils
import { toFile } from '../../../utils'
// Styles
import Styles from './styles.module.scss'
// Icons
import { ReactComponent as BackIcon } from '../../../theme/assets/icons/arrow_left_icon.svg'
// Components
import { SaveButton, CreateAvatar } from '../../GeneralComponents'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { Button } from 'components/Core'

export const ChangeAvatar = ({ closeAvatar, url, user_id, userToUpdate, refetchUserData }) => {
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.profile.loadingForSettings)
  const error = useSelector((state) => state.general.error)
  const user = useSelector((state) => state.auth.user)
  const avatar = useSelector((state) => state.profile.avatar)

  const onFormSubmit = async (event) => {
    event.preventDefault()

    if (avatar) {
      const fileImg = await toFile(avatar, 'avatar.jpeg', 'image/jpeg')
      const formData = new FormData()

      formData.append('profile_pic', fileImg, fileImg.name)

      const data = {
        update: formData,
        url,
        user_id,
        refetchUserData,
      }

      dispatch(settingsActions.changeUserAvatar(data))
    }
  }

  const goBack = () => closeAvatar()

  const onDeleteAvatar = () => {
    if (user?.user?.user_type === 'parent') {
      const data = {
        id: Number(parentChild.id),
        token: user.token,
      }
      dispatch(profileActions.deleteParentChildAvatar(data))
    } else if (user?.user?.user_type === 'therapist') {
      const data = {
        token: user.token,
      }
      dispatch(settingsActions.deleteUserAvatar(data))
    }
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <BackIcon onClick={goBack} />
        <h6>Pick an avatar</h6>
      </div>
      <form onSubmit={(event) => onFormSubmit(event)}>
        <div className={Styles.popup_container}>
          <div className={`${Styles.avatar_wrapper} ClarityMask`}>
            <div className={Styles.avatar_title}>{error && <span className={Styles.error}>{error}</span>}</div>
            <CreateAvatar userToUpdate={userToUpdate} />
            <div className={Styles.avatar_message}>
              {'Upload the best version of yourself'}
              <br />
              {'Available photo formats: JPEG, JPG, PNG.'}
              <br />
              {'Max photo size: 5 MB'}
            </div>
          </div>
        </div>
      </form>
      <div className={Styles.btns}>
        {!userToUpdate ? (
          <div className={Styles.delete_btn} onClick={onDeleteAvatar}>
            Remove
          </div>
        ) : (
          <Button variant="secondary" p={8} minWidth="120px" onClick={closeAvatar}>
            Close
          </Button>
        )}
        <div className={Styles.btn_container}>
          {loading ? <Spinner /> : <SaveButton action={(event) => onFormSubmit(event)} title={'Save'} class={Styles.save_btn} isValid={!error && avatar} />}
        </div>
      </div>
    </div>
  )
}
