import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Typography, Box, CircularProgress, Avatar, Chip, Divider, Paper, Fade, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { useGetLanguage } from 'hooks/useGetLanguage'
import VideocamIcon from '@mui/icons-material/Videocam'
import ExploreIcon from '@mui/icons-material/Explore'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import CakeIcon from '@mui/icons-material/Cake'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language'
import { Button, CenterColumn, CenterRow, Flex, Text } from 'components/Core'
import { useGetBookOnBehalfMutation, useGetUserQuery } from 'defaultLayout/slice/service'
import { ArrowBack, Person } from '@mui/icons-material'
import { theme } from 'utils/theme'
import { Therapist } from 'utils/types/types'
import { CalcomBookingView } from 'components/ParentFlow/Therapy/NewParentOnboarding/CalcomBookingView'
import { FlowContainer } from 'components/ParentFlow/Therapy/NewParentOnboarding/FlowContainer'
import { RecordingConsent } from 'components/ParentFlow/Therapy/NewParentOnboarding/RecordingConsent/RecordingConsent'
import { CALCOM_ADVISOR_CHILD_LINK, SubscriptionType } from 'constants/index'
import { showToast } from 'utils/utils'

type OrganizerData = {
  full_name: string
  id: number | string | undefined
  email: string
  avatar: string
  link: string
  professional_qualities: string[]
  subscription_id?: string | number
  disableBooking?: boolean
}

// Booking type definition
interface BookingType {
  id: string
  title: string
  icon: React.ReactNode
}

// Profile type definition
export type ParentProfile = {
  age: number
  birth_date: string
  email: string
  full_name: string
  id: number
  languages: string[]
  onboarding_step: string
  type: string
}

export const BookOnBehalf = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()
  const { direction } = useGetLanguage()

  const [selectedBookingType, setSelectedBookingType] = useState<string | null>(null)
  const [selectedProfile, setSelectedProfile] = useState<number | null>(null)
  const { data, isLoading } = useGetUserQuery(id, { skip: !id })
  const [metadataForRecording, setMetadataForRecording] = useState<object>({})
  const [selectedOrganizer, setSelectedOrganizer] = useState<OrganizerData>()
  const [mutate, { data: response, isLoading: gettingResponse, reset }] = useGetBookOnBehalfMutation()

  // Booking types with MUI icons
  const bookingTypes: BookingType[] = [
    {
      id: 'free-consultation',
      title: 'Free Consultation',
      icon: <VideocamIcon fontSize="large" color="primary" />,
    },
    {
      id: 'discovery',
      title: 'Discovery Session',
      icon: <ExploreIcon fontSize="large" color="primary" />,
    },
    {
      id: 'therapy',
      title: 'Therapy Session',
      icon: <HealthAndSafetyIcon fontSize="large" color="primary" />,
    },
  ]

  const handleBookingTypeSelect = (typeId: string) => {
    setSelectedBookingType(typeId)
  }

  const handleProfileSelect = (profileId: number) => {
    setSelectedProfile(profileId)
  }

  const handleBack = () => {
    if (selectedOrganizer) {
      setSelectedOrganizer(undefined)
    }
    if (selectedProfile) {
      setSelectedProfile(null)
      reset()
    } else if (selectedBookingType) {
      setSelectedBookingType(null)
    } else {
      navigate('/admin_panel/admin/parents')
    }
  }

  // Function to get initials from full name
  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase()
      .substring(0, 2)
  }

  // Function to get profile type display name
  const getProfileTypeDisplay = (type: string) => {
    switch (type.toLowerCase()) {
      case 'child':
        return 'Child'
      case 'teen':
        return 'Teenager'
      case 'adult':
        return 'Adult'
      default:
        return type
    }
  }

  // Function to get color based on profile type
  const getProfileTypeColor = (type: string) => {
    switch (type.toLowerCase()) {
      case 'child':
        return theme.colors.typePurpure[300] // Green
      case 'teen':
        return '#2196F3' // Blue
      case 'adult':
        return '#9C27B0' // Purple
      default:
        return '#FF9800' // Orange
    }
  }
  const profile = useMemo(() => {
    return data?.profiles?.find((profile: ParentProfile) => profile.id === selectedProfile)
  }, [data?.profiles, selectedProfile])
  const organizers = useMemo<Array<OrganizerData>>(() => {
    if (response) {
      if (selectedBookingType === 'free-consultation') {
        const advisor = {
          full_name: response.advisor?.full_name,
          id: response.advisor?.id,
          email: response.advisor?.email,
          avatar: response.advisor?.profile_pic,
          link: CALCOM_ADVISOR_CHILD_LINK as string,
          professional_qualities: ['Advisor'],
        }
        setSelectedOrganizer(advisor)
        return [advisor]
      }
      return response.therapists?.reduce((acc: [OrganizerData], therapist: Therapist) => {
        acc.push({
          full_name: therapist?.user?.full_name,
          id: therapist?.user?.id,
          email: therapist?.user?.email,
          avatar: therapist?.user?.profile_pic,
          link: selectedBookingType === 'therapy' ? therapist?.regular_link : therapist?.discovery_link,
          professional_qualities: therapist?.professional_qualities,
          subscription_id: therapist?.subscription?.id,
          disableBooking: therapist.subscription.type === SubscriptionType.MEMBERSHIP,
        })
        return acc
      }, [])
    }
    return []
  }, [response, selectedBookingType])

  const title = useMemo(() => {
    if (selectedBookingType === 'free-consultation') {
      return 'Free Consultation'
    }
    if (selectedBookingType === 'discovery') {
      return 'Discovery Session'
    }
    if (selectedBookingType === 'therapy') {
      return 'Therapy Session'
    }
    return 'Book on Behalf'
  }, [selectedBookingType])

  useEffect(() => {
    if (selectedProfile && id) {
      mutate({
        user_id: id,
        profile_id: selectedProfile,
      })
    }
  }, [selectedProfile, id])
  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </LoadingContainer>
    )
  }

  return (
    <Container>
      <>
        <>
          <Fade in={true} timeout={800}>
            <Box width={['100%', '100%', '100%', '80%', '50%']}>
              <BackButtonWrapper>
                <Button onClick={handleBack} px={0} variant="ghost">
                  <ArrowBack />
                  Back
                </Button>
              </BackButtonWrapper>
              <CenterRow width="100%" gap={2}>
                <CenterRow gap={2}>
                  <Text maxWidth="fit-content" variant="heading3" fontWeight={600} color="black.700">
                    Book on Behalf of
                  </Text>

                  <Text maxWidth="fit-content" variant="heading2" fontWeight={700} color="typePurpure.300" className="animate-pulse">
                    {data?.full_name}
                  </Text>
                </CenterRow>
              </CenterRow>
              {profile && (
                <CenterRow width="100%">
                  <Chip label={`Profile - ${profile?.full_name}`} />
                </CenterRow>
              )}
            </Box>
          </Fade>
        </>
      </>
      {!selectedOrganizer && (
        <Flex bg={theme.colors.black[200]} p={4} width="100%" gap={4} height="100%">
          {!selectedBookingType ? (
            // Step 1: Select booking type
            <CenterColumn width="100%" gap={32}>
              <Text fontSize={[20]}>Select Booking Type</Text>

              <Flex justifyContent="center" flexWrap="wrap" gap={4}>
                {bookingTypes.map((type) => (
                  <CompactCard key={type.id} onClick={() => handleBookingTypeSelect(type.id)}>
                    <Box sx={{ mb: 1 }}>{type.icon}</Box>
                    <Typography variant="h6">{type.title}</Typography>
                  </CompactCard>
                ))}
              </Flex>
            </CenterColumn>
          ) : !selectedProfile ? (
            <CenterColumn width="100%" gap={4}>
              <Text fontSize={[20]}>Select Profile</Text>

              <Flex width="100%" justifyContent="center">
                <Flex width={['fit-content']} flexWrap="wrap" gap={4}>
                  {data?.profiles?.length ? (
                    data?.profiles?.map((profile: ParentProfile) => (
                      <ProfileCard key={profile.id} onClick={() => handleProfileSelect(profile.id)}>
                        <ProfileHeader typeColor={getProfileTypeColor(profile.type)}>
                          <ProfileAvatar>{getInitials(profile.full_name)}</ProfileAvatar>
                          <ProfileTypeChip typeColor={getProfileTypeColor(profile.type)}>{getProfileTypeDisplay(profile.type)}</ProfileTypeChip>
                        </ProfileHeader>

                        <ProfileContent>
                          <ProfileInfoGrid>
                            <ProfileInfoItem>
                              <Person fontSize="small" />
                              <ProfileInfoText>{profile.full_name}</ProfileInfoText>
                            </ProfileInfoItem>
                            <ProfileInfoItem>
                              <CakeIcon fontSize="small" />
                              <ProfileInfoText>Age: {profile.age}</ProfileInfoText>
                            </ProfileInfoItem>

                            {/* <ProfileInfoItem>
                            <EmailIcon fontSize="small" />
                            <ProfileInfoText>{profile.email || 'No email'}</ProfileInfoText>
                          </ProfileInfoItem> */}

                            {profile.languages && profile.languages.length > 0 && (
                              <ProfileInfoItem>
                                <LanguageIcon fontSize="small" />
                                <LanguageChips>
                                  {Array.from(new Set(profile.languages)).map((lang, index) => (
                                    <Chip key={index} label={lang} size="small" variant="outlined" sx={{ margin: '2px' }} />
                                  ))}
                                </LanguageChips>
                              </ProfileInfoItem>
                            )}
                          </ProfileInfoGrid>
                        </ProfileContent>
                      </ProfileCard>
                    ))
                  ) : (
                    <Text variant="light">No profiles found</Text>
                  )}
                </Flex>
              </Flex>
            </CenterColumn>
          ) : (
            // Step 3: Booking component
            <>
              {/* Here you would include your booking component */}
              {organizers?.length
                ? organizers?.map((organizer) => (
                    <CenterRow justifyContent="space-between" width={['100%', '100%', '100%', '80%', '50%']} p={16} borderRadius="8px" bg={theme.colors.black['100']}>
                      <CenterRow gap={3}>
                        <Avatar src={organizer.avatar} alt={organizer?.full_name} />
                        <Box>
                          <Text variant="body1" fontWeight={600}>
                            {organizer.full_name}
                          </Text>
                          <Text variant="light" color="text.secondary">
                            {organizer?.professional_qualities?.join(', ')}
                          </Text>
                        </Box>
                      </CenterRow>
                      <Tooltip title={organizer.disableBooking ? 'Cannot book on behalf of type membership' : ''}>
                        <Button
                          disabled={organizer.disableBooking}
                          onClick={() => {
                            setSelectedOrganizer(organizer)
                          }}
                        >
                          Book
                        </Button>
                      </Tooltip>
                    </CenterRow>
                  ))
                : !gettingResponse && <Text variant="light">Specialist is not assigned yet</Text>}
            </>
          )}
        </Flex>
      )}

      {selectedOrganizer && (
        <FlowContainer py={20} alignItems="center" width={['100%', '100%', '100%']}>
          <CenterRow width="100%" gap={10} px={[1, 1, 2]} maxWidth={['100%', '100%', '100%', '100%', '90%', '70%']}>
            <RecordingConsent
              sub_text="Disable recording for this call, by clicking on the checkbox below."
              main_text="You are going to book a call on behalf of this user, make sure to match parents previous consent."
              title={title}
              direction={direction}
              setMetaData={setMetadataForRecording}
            />
          </CenterRow>
          <CalcomBookingView
            metadata={metadataForRecording}
            link={selectedBookingType === 'free-consultation' ? CALCOM_ADVISOR_CHILD_LINK : selectedOrganizer?.link}
            db_email={data?.email}
            email={data?.email}
            name={data?.full_name}
            phone_number={data?.phone_number}
            profile_id={selectedProfile}
            subscription_id={selectedOrganizer?.subscription_id}
          />
        </FlowContainer>
      )}
    </Container>
  )
}

// Styled components
const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
  flex-direction: column;
  // align-items: center;
  display: flex;
  gap: 1.5rem;
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
`

// Smaller card with reduced hover effect for booking types
const CompactCard = styled(Card)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.1s, box-shadow 0.1s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`

// Enhanced profile card
const ProfileCard = styled(Card)`
  display: flex;
  min-width: 300px;
  max-height: 300px;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  height: 100%;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`

const ProfileHeader = styled.div<{ typeColor: string }>`
  background: linear-gradient(135deg, ${(props) => props.typeColor} 0%, rgba(255, 255, 255, 0.8) 100%);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const ProfileAvatar = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
`

const ProfileTypeChip = styled.div<{ typeColor: string }>`
  background-color: white;
  color: ${(props) => props.typeColor};
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.typeColor};
`

const ProfileContent = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
`

const ProfileInfoGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const ProfileInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #555;
`

const ProfileInfoText = styled(Typography)`
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LanguageChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`

const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
