// Core
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
// Styles
import Styles from './Styles.module.scss'
// MUI
import { CircularProgress, IconButton, Modal, Skeleton } from '@mui/material'
// Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
// Actions
import { profileActions } from '../../../../actions'
// Utils
import dayjs, { utc } from 'dayjs'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import ar from './locale/ar.json'
import en from './locale/en.json'
import { currencyFormatter } from '../../../../utils'
import { Box, CenterRow, Container, Flex, Text } from 'components/Core'
import { calComActions } from '../../../GeneralComponents/CalCom/slice'
import { HourlyPriceCard } from 'components/HourlyPriceCard'
import { ArrowLeft } from 'theme/assets/icons'
import { useGetSessionLengthPriceMutation } from '../service'

dayjs.extend(utc)

export const AcceptRecordingModal = ({ onClose, open, openCalCom, setMetadata, therapist_id, isPaidCall }) => {
  const dispatch = useDispatch()
  const [getSessionLengthPrice, { isLoading, isSuccess, data, isError, reset }] = useGetSessionLengthPriceMutation()

  const { t } = useTranslation()

  const [ipAddress, setIpAddress] = useState(null)
  const [openSessionLengthChoice, setOpenSessionLengthChoice] = useState(false)
  const [userOption, setUserOption] = useState(true)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
    i18n.changeLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'recording_modal', en)
    i18n.addResourceBundle('ar', 'recording_modal', ar)
  }, [])
  const handleSelectOption = (option) => {
    setUserOption(option)
    const dataForRequest = {
      setData: (ipAddress) => setIpAddress(ipAddress),
    }
    dispatch(profileActions.getIPAddress(dataForRequest))
  }

  useEffect(() => {
    if (ipAddress) {
      const metadata = {
        consent: userOption,
        user_agent: navigator.userAgent,
        ip: ipAddress,
        time: dayjs.utc().toISOString(),
      }

      setMetadata(metadata)
      if (userOption !== undefined) {
        if (isPaidCall) {
          setOpenSessionLengthChoice(true)
          if (therapist_id) getSessionLengthPrice({ therapist_id })
        } else {
          onClose()
          openCalCom(true)
        }
      }
    }
  }, [ipAddress, userOption, therapist_id])

  useEffect(() => {
    if ((!data?.data?.length && isSuccess) || isError) {
      setOpenSessionLengthChoice(false)
      onClose()
      openCalCom(true)
      reset()
    }
  }, [data?.data?.length, isError, isSuccess])
  return (
    <Modal
      onClose={() => {
        onClose()
        setOpenSessionLengthChoice(false)
      }}
      open={open}
    >
      <Box width={['95%', '90%', '80%', '80%', '70%', '55%', '50%']} gap={10} variant="modal-container" style={{ direction: selectedLanguage === 'ar' ? 'rtl' : 'ltr' }}>
        <div className={Styles.main_container__header}>
          {openSessionLengthChoice && (
            <IconButton
              onClick={() => {
                setUserOption(undefined)
                setOpenSessionLengthChoice(false)
              }}
            >
              <ArrowLeft />
            </IconButton>
          )}

          <p>{openSessionLengthChoice ? '' : t('recording_modal:header')}</p>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {openSessionLengthChoice ? (
          <Container width="100%" flexDirection="column">
            <Text variant="body1" fontSize={[16, 20, 23]} textAlign="center">
              {t('recording_modal:selectDuration')}
            </Text>

            <CenterRow justifyContent={['center', 'flex-start']} px={['2%', '0%', '5%', '5%', '0px']} flexWrap="wrap" gap="10px" pt={71} pb={80}>
              {isLoading ? (
                <CenterRow gap={10} flexDirection={['column']}>
                  <Text>{t('recording_modal:fetchingHourlyPrice')}</Text>
                  <CircularProgress />
                </CenterRow>
              ) : (
                data?.data?.map((item) => (
                  <HourlyPriceCard
                    onClick={() => {
                      dispatch(calComActions.setDuration(String(item.duration)))
                      onClose()
                      openCalCom(true)
                    }}
                    key={item.duration}
                    item={item}
                  />
                ))
              )}
            </CenterRow>
          </Container>
        ) : (
          <div className={Styles.main_container__content}>
            <p>{t('recording_modal:main_text')}</p>
            <span>{t('recording_modal:sub_text')}</span>
            <div className={`${Styles.main_container__content__btns} ${selectedLanguage === 'ar' && Styles.btns_ar}`}>
              <div onClick={() => handleSelectOption(false)}>
                <p>{t('recording_modal:btns:decline')}</p>
              </div>
              <div onClick={() => handleSelectOption(true)}>
                <span>{t('recording_modal:btns:accept')}</span>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}
