import { initializeApp } from 'firebase/app'
import { format } from 'date-fns'
import { confirmPasswordReset, getAuth, signInWithEmailAndPassword, verifyPasswordResetCode } from 'firebase/auth'
import { notify } from 'helpers'
import { Dispatch, SetStateAction } from 'react'
import { ChipTypes } from 'components/Core'
import { Session, TFN } from './types/types'
import { toast, ToastOptions } from 'react-toastify'
import { ToastIcon } from 'react-toastify/dist/types'

type Options = {
  date: string | undefined
  formatType?: string
}
export const dateFormatter = ({ date, formatType = 'PPpp' }: Options) => {
  try {
    if (!date) return null
    return format(new Date(date), formatType)
  } catch (error) {
    console.log(error)
    return null
  }
}

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const firebaseApp = initializeApp(firebaseConfig)

export const authInstance = getAuth(firebaseApp)

type RestProps = {
  actionCode: string
  continueUrl: string
  newPassword: string
  lang?: string
  onSuccess: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
}
export function handleResetPassword({ actionCode, newPassword, onSuccess, setLoading }: RestProps) {
  // Localize the UI to the selected language as determined by the lang
  // parameter.

  // Verify the password reset code is valid.
  verifyPasswordResetCode(authInstance, actionCode)
    .then((email) => {
      const accountEmail = email

      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.

      // Save the new password.
      confirmPasswordReset(authInstance, actionCode, newPassword)
        .then(async (resp) => {
          // Password reset has been confirmed and new password updated.
          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          await signInWithEmailAndPassword(authInstance, accountEmail, newPassword).then(() => {
            // TODO: If a continue URL is available, display a button which on
            // click redirects the user back to the app via continueUrl with
            // additional state determined from that URL's parameters.
            onSuccess?.()
          })
        })
        .catch((error) => {
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          console.log(error)
          notify(error.message)
        })
        .finally(() => {
          setLoading(false)
        })
    })
    .catch((error) => {
      // Invalid or expired action code. Ask user to try to reset the password again.
      notify('Session Invalid or expired. Please try to reset the password again.')
    })
    .finally(() => {
      setLoading(false)
    })
}
type CurrencyFormatterProps = {
  amount: number | undefined
  currency?: string
}
export const currencyFormatter = ({ amount = 0, currency = 'USD' }: CurrencyFormatterProps) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })
  return formatter.format(amount)
}

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY as string

export const encryptAnswer = (text: string) => {
  let encrypted = ''
  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i) ^ ENCRYPTION_KEY.charCodeAt(i % ENCRYPTION_KEY?.length)
    encrypted += String.fromCharCode(charCode)
  }
  return btoa(encrypted)
}

export const decryptAnswer = (encrypted: string) => {
  const decoded = atob(encrypted)
  let decrypted = ''
  for (let i = 0; i < decoded.length; i++) {
    const charCode = decoded.charCodeAt(i) ^ ENCRYPTION_KEY.charCodeAt(i % ENCRYPTION_KEY.length)
    decrypted += String.fromCharCode(charCode)
  }
  return decrypted
}

export const getVideoType = (url: string | undefined | null) => {
  if (!url || typeof url !== 'string') return 'video/mp4'

  const extension = url.split('.').pop()?.split('?')[0]?.toLowerCase()

  const videoTypes = {
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogg: 'video/ogg',
    mov: 'video/quicktime',
    m4v: 'video/mp4',
  }

  return videoTypes[extension as keyof typeof videoTypes] || 'video/mp4'
}

export const returnSessionStatus = (status: string): ChipTypes => {
  let style
  switch (true) {
    case status === 'completed':
      return 'default'
    case status === 'planned':
      return 'success'
    case status === 'Payment Refunded':
      return 'warning'
    case status === 'Pending Payment':
      return 'info'
    case status === 'In progress':
      return 'warning'
      break
    case status.includes('canceled'):
      return 'error'
    default:
      return 'default'
  }
}

export const returnJoinButtonActionsForParent = (status: string, t: TFN) => {
  let btnType
  let btnText
  let isPayment

  switch (status) {
    case 'Completed':
      break
    case 'Planned':
      btnType = 'violet'
      btnText = t('history_record:btns:join')
      break
    case 'In progress':
      btnType = 'violet'
      btnText = t('history_record:btns:join')
      break
    case 'Pending Payment':
      btnType = 'green'
      btnText = t('history_record:btns:pay')
      isPayment = true

      break
    case 'Canceled - Payment Canceled':
      break
    default:
      break
  }
}

export const filteredMembershipSessions = (sessions: Array<Session> | undefined) => {
  const filterUncancelledSessions = sessions?.filter((session) => !session.get_status?.toLocaleLowerCase().includes('cancel'))
  return filterUncancelledSessions
}

export const sortMembershipSessionByStatus = (sessions: Array<Session> | undefined) => {
  return [...(sessions ?? [])].sort((a, b) => {
    if (a.get_status === 'canceled' && b.get_status !== 'canceled') return 1
    if (a.get_status !== 'canceled' && b.get_status === 'canceled') return -1
    if (a.get_status === 'in_progress' && b.get_status !== 'in_progress') return -1
    if (a.get_status !== 'in_progress' && b.get_status === 'in_progress') return 1
    return 0
  })
}
export const sortMembershipSessionByDate = (sessions: Array<Session> | undefined) => {
  return [...(sessions ?? [])].sort((a, b) => {
    const dateA = new Date(a.start_time)
    const dateB = new Date(b.start_time)
    return dateA.getTime() - dateB.getTime()
  })
}
export const ceilingPrice = (price: number): number => {
  return Math.ceil(price * 10) / 10
}

export const calculateDiscountedPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100
}
interface ToastArgs {
  message: string
  type?: 'success' | 'error' | 'info' | 'warning'
  duration?: number
  position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left'
  theme?: 'light' | 'dark' | 'colored'
  icon?: ToastIcon
  closeButton?: boolean
}

export const showToast = ({ message, type = 'info', duration = 3000, position = 'top-right', theme = 'light', icon, closeButton = true }: ToastArgs) => {
  const toastOptions: ToastOptions = {
    position,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme,
    icon: icon as ToastIcon,
    closeButton,
  }

  switch (type) {
    case 'success':
      toast.success(message, toastOptions)
      break
    case 'error':
      toast.error(message, toastOptions)
      break
    case 'warning':
      toast.warning(message, toastOptions)
      break
    default:
      toast.info(message, toastOptions)
  }
}

export const getMimeType = (fileUrl: string) => {
  // Extract filename before the query parameters
  const baseUrl = fileUrl.split('?')[0]
  // Get extension from the actual filename
  const extension = baseUrl.split('.').pop()?.toLowerCase()

  const mimeTypes = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    svg: 'image/svg+xml',
    mp4: 'video/mp4',
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    avi: 'video/x-msvideo',
    mov: 'video/quicktime',
    m4v: 'video/mp4',
    mpg: 'video/mpeg',
    mpeg: 'video/mpeg',
    gif: 'image/gif',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }
  return mimeTypes[extension as keyof typeof mimeTypes] || 'application/octet-stream'
}

export const getScreeningTypeColor = (screeningType: string): ChipTypes => {
  switch (screeningType) {
    case 'discovery':
      return 'info'
    case 'membership':
      return 'secondary'
    case 'therapy':
      return 'success'
    default:
      return 'default'
  }
}

export const sessionStatusColor = (status: string) => {
  switch (status) {
    case 'Completed':
      return { background: '#F6F0FC', color: '#8450a0' }

    case 'Planned':
      return { background: '#F8FFEB', color: '#3B8400' }

    case 'Payment Refunded':
      return { background: '#FFFBE4', color: '#B19607' }

    case 'Pending Payment':
      return { background: '#FFFBE4', color: '#B19607' }

    case 'In progress':
      return { background: '#FFFBE4', color: '#B19607' }

    case 'Canceled - Payment Canceled':
      return { background: '#FFE4E4', color: '#E51616' }

    default:
      return { background: '#FFE4E4', color: '#E51616' }
  }
}
