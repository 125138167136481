//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close.svg'
//Components
import { Summary } from '../Summary'
//Core
import { useDispatch } from 'react-redux'
import { setOneApply } from '../../../reducers/advisorOnbordingSlice'
import { Box, CenterRow, Text } from 'components/Core'

export const SeeResultsPopup = ({ open, onClose, isAdult }) => {
  const dispatch = useDispatch()

  const handleCloseResultsPopup = () => {
    dispatch(setOneApply({}))
    onClose()
  }

  return (
    <Modal open={open} onClose={handleCloseResultsPopup}>
      <Box variant="modal-container">
        <CenterRow mb={2} justifyContent="space-between">
          <Text variant="heading3">Summary</Text>
          <CloseIcon className={Styles.header__icon} onClick={handleCloseResultsPopup} />
        </CenterRow>
        <Summary isModal={true} isAdult={isAdult} />
      </Box>
    </Modal>
  )
}
