import { APPLE_PAY_MERCHANT_DOMAIN, APPLE_PAY_MERCHANT_ID, paymentKey, paymnetPath } from 'constants/index'
import { useState } from 'react'

interface TapChargeResponse {
  id: string
  status: string
  amount: number
  currency: string
  threeDSecure: boolean
  redirect?: {
    url: string
  }
  transaction: {
    url: string
  }
}
type TapHookProps = {
  webhookUrl: string
  redirectUrl?: string
  orderDetails: {
    amount: number
    currency: string
    full_name: string
    phone_number: string
    email: string
    description: string
    order_id: string
    transaction_id: string
    created_at: string
    user_id: string
  }
}
export const useTapPayments = ({ webhookUrl, redirectUrl = '', orderDetails }: TapHookProps) => {
  const { amount, currency, full_name, email, description, order_id, transaction_id, user_id } = orderDetails ?? {}

  // const [loading, setLoading] = useState(false)
  // const [error, setError] = useState<string | null>(null)

  const handlePrepareApplePayCharge = (token: string) => {
    const chargeData = {
      amount,
      currency: 'USD',
      customer_initiated: true,
      threeDSecure: true,
      save_card: false,
      description,
      receipt: { email: false, sms: false },
      reference: {
        transaction: transaction_id,
        order: order_id,
        user_id,
      },
      customer: {
        first_name: full_name,
        email: email,
      },
      merchant: { id: APPLE_PAY_MERCHANT_ID, domain: APPLE_PAY_MERCHANT_DOMAIN },
      source: { id: token },
      post: { url: `${paymnetPath}/${webhookUrl}` },
      redirect: { url: redirectUrl },
    }

    return chargeData
  }

  return {
    handlePrepareApplePayCharge,
  }
}
