import { apiPath } from 'constants/index'
import { request } from './axios'

export const zoomApi = {
  getZoomInformation: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/zoom/api/meetings/${data.id}/join/`,
    })
  },
  createSessionNote: async (data) => {
    return request({
      method: 'POST',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/create/`,
      data: JSON.stringify(data.payload),
    })
  },
  getSessionNote: async (data) => {
    return request({
      method: 'GET',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/`,
    })
  },
  approveSessionNote: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/approve/`,
      data: JSON.stringify(data.payload),
    })
  },
  updateSessionNote: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/update/`,
      data: JSON.stringify(data.payload),
    })
  },
  submitSessionNote: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/submit/`,
      data: JSON.stringify(data.payload),
    })
  },
  discardEdditingSessionNote: async (data) => {
    return request({
      method: 'PATCH',
      url: `${apiPath}/zoom/api/meetings/${data.meetingId}/notes/discard/`,
      data: JSON.stringify(data.payload),
    })
  },
}
