import { Button, CenterColumn, CenterRow, Chip, Container, Flex, Text } from 'components/Core'
import { Card, CardActions, CardContent, CardHeader, Skeleton } from '@mui/material'
import { dateFormatter } from 'utils/utils'
import type { Patient } from 'utils/types/types'

import { useNavigate } from 'react-router-dom'
import { ChevronRight } from '@mui/icons-material'

type CommonProps = {
  showNotification: (profileName: string, status: 'accept' | 'decline') => void
  onClickEvents: {
    acceptApplication: (screeningId: number | undefined, profileName: string) => void
    declineApplication: (screeningId: number | undefined, profileName: string) => void
    joinMeeting: (meetingId: number | undefined) => void
  }
}
interface Props extends CommonProps {
  isFetching?: boolean
  patients: Patient[] | undefined
}
export const MyPatientsCardView = ({ patients, onClickEvents, showNotification, isFetching }: Props) => {
  const navigate = useNavigate()
  if (isFetching) {
    return (
      <Container flexDirection={['column']} gap={[16]}>
        {[1, 2, 3].map((item) => (
          <Card sx={{ width: '100%' }} key={item}>
            <CardHeader title={<Skeleton width={200} height={30} />} />
            <CardContent>
              <CenterColumn gap={16}>
                <Flex gap={'5px'}>
                  <Skeleton width={80} height={24} />
                  <Skeleton width={120} height={24} />
                </Flex>
                <Flex alignItems="center" flexWrap={['wrap']} gap={'5px'}>
                  <Skeleton width={80} height={24} />
                  <CenterRow gap={1} flexWrap="wrap">
                    <Skeleton width={80} height={24} />
                    <Skeleton width={80} height={24} />
                    <Skeleton width={80} height={24} />
                  </CenterRow>
                </Flex>
                <Flex gap={'5px'}>
                  <Skeleton width={100} height={24} />
                  <Skeleton width={150} height={24} />
                </Flex>
              </CenterColumn>
            </CardContent>
            <CardActions>
              <CenterRow width="100%" justifyContent="flex-end">
                <Skeleton width={120} height={36} />
              </CenterRow>
            </CardActions>
          </Card>
        ))}
      </Container>
    )
  }

  return (
    <Container flexDirection={['column']} gap={[16]}>
      {patients?.map((patient) => (
        <Card sx={{ width: '100%' }} key={patient?.id}>
          <CardHeader title={patient?.full_name} />
          <CardContent>
            <CenterColumn gap={16}>
              <Flex gap={'5px'}>
                <Text fontWeight="bold">Birthday:</Text>
                <Text>{patient?.birth_date ?? '--'}</Text>
              </Flex>
              <Flex alignItems="center" flexWrap={['wrap']} gap={'5px'}>
                <Text fontWeight="bold">Problems:</Text>
                <CenterRow gap={1} flexWrap="wrap">
                  {patient?.concerns?.map((concern, index) => (
                    <Chip fontSize={15} key={concern} value={concern} type="info" />
                  ))}
                  {/* {Array.isArray(patient?.concerns) && patient?.concerns?.length ? patient?.concerns?.join(', ') : '--'} */}
                </CenterRow>
              </Flex>
              <Flex gap={'5px'}>
                <Text fontWeight="bold">Meeting Time:</Text>
                <Text>
                  {dateFormatter({
                    date: patient?.calcom_session?.start_time,
                    formatType: 'Pp',
                  }) ?? '--'}
                </Text>
              </Flex>
            </CenterColumn>
          </CardContent>
          <CardActions>
            <CenterRow width="100%" justifyContent="flex-end">
              <Button
                onClick={() =>
                  navigate(`child/${patient?.id}?type=${patient?.type}`, {
                    state: {
                      profile_id: patient?.id,
                    },
                  })
                }
                fontSize={[16, 16, 20]}
                variant="ghost"
              >
                Go To Profile
                <ChevronRight />
              </Button>
            </CenterRow>
          </CardActions>
        </Card>
      ))}
    </Container>
  )
}
