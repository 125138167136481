/* eslint-disable no-unused-vars */
import { func, number, string } from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import Styles from './styles.module.scss'
import { FileAttachmentLoader } from './FileAttachmentLoader'
import { NewMessageBar } from './NewMessageBar'
import { Placeholder } from './Placeholder'
import { RecievedMessageInstance } from './RecievedMessageInstance'
import { SentMessageInstance } from './SentMessageInstance'
import WebSocketService from '../../../../websocket'
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { notify } from '../../../../helpers'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { Avatar, AvatarGroup, Stack } from '@mui/material'
import { Box, Flex, Text } from 'components/Core'
import { ChatNameDisplay } from './ChatNameDisplay'
import { Menu, IconButton } from '@mui/material'
import { useGetUnreadMessagePageNumberMutation } from '../Chat/service'
import { ArrowDownward } from '@mui/icons-material'
import { useGetPreSignedUrlMutation } from 'components/ChatSystem/service'
import { showToast } from 'utils/utils'
import axios from 'axios'

export const MessagesHistory = ({
  currentUserId,
  currentUserEmail,
  roomId,
  chatId,
  onNewMessageSend,
  onViewLastMessages,
  onChatDetailsOpenning,
  selectedChat,
  goBackToChatList,
  grouped = false,
  isGroupChat,
  showBanner,
  setShowBanner,
}) => {
  const user = useSelector((state) => state.auth.user)
  // Scroll to the bottom of the messages container
  const scrollableContainerRef = useRef(null)
  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight
    }
  }
  // WebSocket integration
  const [messageToAdd, setMessageToAdd] = useState({})
  const [messagesHistory, setMessagesHistory] = useState([])
  const [maxPages, setMaxPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [fetchedRoomId, setFetchedRoomId] = useState(undefined)
  const [webSocketInstance, setWebSocketInstance] = useState(null)
  const [groupedMessages, setGroupedMessages] = useState([])

  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false)
  const [isMessageSending, setIsMessageSending] = useState(false)
  const [futureMessageId, setFutureMessageId] = useState(undefined)

  const [fileToUpload, setFileToUpload] = useState([])
  const fileToUploadLocal = localStorage.getItem(`files-${roomId}`)
  const isMessageSendingLocal = localStorage.getItem(`isMessageSending-${roomId}`)
  const futureMessageIdLocal = localStorage.getItem(`futureMessageId-${roomId}`)
  useEffect(() => {
    setFileToUpload(JSON.parse(fileToUploadLocal ?? '[]'))
    if (isMessageSending) {
      setIsAttachmentLoading(true)
    }
    setFutureMessageId(futureMessageIdLocal)
  }, [fileToUploadLocal, roomId, isMessageSendingLocal, futureMessageIdLocal])

  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'chat_history', en)
    i18n.addResourceBundle('ar', 'chat_history', ar)
  }, [])

  useEffect(() => {
    if (user && roomId) {
      setWebSocketInstance(new WebSocketService('chat', roomId, user))
    }
  }, [roomId, JSON.stringify(user)])

  const setWebSocketCallbacks = (webSocketInstance) => {
    webSocketInstance?.setCallbacks(
      (listOfMessages) => {
        setMessagesHistory(messagesHistory.length < currentPage ? [...messagesHistory, listOfMessages.messages] : messagesHistory)
        setMaxPages(listOfMessages.max_pages)
      },
      (newMessage) => {
        setMessageToAdd(newMessage)
        onNewMessageSend()
      },
      (shareFile) => {
        // console.log(shareFile)
      },
      /**
       *
       * @param {import('utils/types/types').ViewedMessage} data
       */
      (data) => {
        setMessagesHistory((prev) => {
          const newMessages = prev?.[0]?.map((message) => {
            if (message.room_id === data.room_id && data.message_ids.includes(message.id) && isGroupChat) {
              const viewedBySet = new Set([...(message?.message_viewed_by ?? []), data.user_id])
              return {
                ...message,
                message_viewed_by: Array.from(viewedBySet),
              }
            } else if (!isGroupChat && message.room_id === data.room_id && data.message_ids.includes(message.id)) {
              return {
                ...message,
                viewed: true,
              }
            }
            return message
          })
          return [newMessages]
        })
      },
    )

    webSocketInstance?.setErrorCallback((error) => {
      notify(error, true)
      setIsAttachmentLoading(false)
      setIsMessageSending(false)
      setMessageToAdd({})
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (webSocketInstance && !webSocketInstance?.isConnected()) {
        console.log('Reconnecting')
        webSocketInstance?.connect()
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [webSocketInstance])

  const fetchData = (page) => {
    if (webSocketInstance && !webSocketInstance?.isConnected()) {
      webSocketInstance?.connect()
      const waitForSocketConnection = (callback) => {
        setTimeout(() => {
          if (webSocketInstance?.state() === 1) {
            callback()
          } else {
            waitForSocketConnection(callback)
          }
        }, 100)
      }

      waitForSocketConnection(() => {
        setWebSocketCallbacks(webSocketInstance)
        webSocketInstance?.fetchMessages(currentUserId, roomId, page)
      })
    } else if (webSocketInstance) {
      setWebSocketCallbacks(webSocketInstance)
      webSocketInstance?.fetchMessages(currentUserId, roomId, page)
    }
  }

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    if (messagesHistory.length < currentPage) {
      fetchData(currentPage)
      setFetchedRoomId(roomId)
      setFetching(true)
    } else if (fetchedRoomId !== roomId || roomId === undefined) {
      setMessagesHistory([])
      setFetchedRoomId(undefined)
      setCurrentPage(1)
    }
  }, [fetchedRoomId, roomId, chatId, currentPage, messagesHistory, fetching])

  const un_viewed_messages = useMemo(() => {
    const allMessages = messagesHistory?.flatMap((page) => page)
    const messagesIds = allMessages?.reduce((acc, curr) => {
      const isGroupRoom = curr?.is_group_chat
      if (isGroupRoom) {
        const userIsInViewed = curr?.message_viewed_by?.find((id) => id === currentUserId)
        if (!userIsInViewed && curr?.author_id !== currentUserId) {
          acc.push(curr?.id)
        }
      } else {
        if (!curr?.viewed && curr?.author_id !== currentUserId) {
          acc.push(curr?.id)
        }
      }
      return acc
    }, [])
    // Only call onViewLastMessages if there are actually unviewed messages

    return messagesIds?.filter((item) => Boolean(item)) ?? []
  }, [JSON.stringify(messageToAdd), JSON.stringify(messagesHistory), currentUserId])

  useEffect(() => {
    if (un_viewed_messages?.length > 0) {
      onViewLastMessages(un_viewed_messages)
    }
  }, [JSON.stringify(un_viewed_messages)])

  const clearAllLocalStorage = () => {
    localStorage.removeItem(`isMessageSending-${roomId}`)
    localStorage.removeItem(`futureMessageId-${roomId}`)
    localStorage.removeItem(`files-${roomId}`)
  }

  function fileToByteArray(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const arrayBuffer = event.target.result
        const byteArray = new Uint8Array(arrayBuffer)
        resolve(byteArray)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const [getPreSignedUrl] = useGetPreSignedUrlMutation()
  const MAX_RETRIES = 3
  const RETRY_DELAY = 1000 // 1 second

  const retryableRequest = async (signed, fileToUpload, messageObject, retryCount = 0) => {
    try {
      const response = await axios.request({
        method: 'PUT',
        url: signed.url,
        data: fileToUpload,
        headers: {
          'Content-Type': signed.file_type,
        },
      })
      // throw new Error('File failed')
      // console.log('File uploaded successfully:', response)
      setFileToUpload((prev) => {
        return prev.map((pre) => {
          if (pre.id === signed.id) {
            pre.progress = 100
          }
          return pre
        })
      })
      messageObject['content'] = signed.id

      await webSocketInstance?.shareFile(messageObject)
      setTimeout(() => {
        setFileToUpload((prev) => {
          const filtered = prev.filter((file) => file.id !== signed.id)
          const fileInfos = filtered.map((file) => ({
            name: file.name,
            type: file.type,
            id: file.id,
            size: file.size,
          }))
          localStorage.setItem(`files-${roomId}`, JSON.stringify(fileInfos))
          return filtered
        })
      }, 2500)
    } catch (error) {
      // console.log('retrying')
      if (retryCount < MAX_RETRIES) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
        return retryableRequest(signed, fileToUpload, messageObject, retryCount + 1)
      }
      // console.log('MAx reached')
      console.error('Error uploading file:', error)
      setFileToUpload((prev) => {
        const filtered = prev.filter((file) => file.id !== signed.id)
        const fileInfos = filtered.map((file) => ({
          name: file.name,
          type: file.type,
          id: file.id,
          size: file.size,
        }))
        localStorage.setItem(`files-${roomId}`, JSON.stringify(fileInfos))
        return filtered
      })
      throw error
    }
  }
  const handleNewMessageSend = async (data, files) => {
    setIsMessageSending(true)
    const messageObject = {
      from: currentUserId,
      roomId: roomId,
    }

    try {
      if (data) {
        messageObject['content'] = data
        await webSocketInstance?.newChatMessage(messageObject)
      }

      if (files.length) {
        setFileToUpload((prev) => {
          const fileInfos = [...prev, ...files].map((file) => ({
            name: file.name,
            type: file.type,
            id: file.id,
            size: file.size,
          }))
          localStorage.setItem(`files-${roomId}`, JSON.stringify(fileInfos))
          localStorage.setItem(`isMessageSending-${roomId}`, 'true')
          return [...prev, ...files]
        })
        scrollToBottom()
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          setFutureMessageId(messagesHistory[0][0]?.id + 1)
          localStorage.setItem(`futureMessageId-${roomId}`, messagesHistory[0][0]?.id + 1)
          setIsAttachmentLoading(true)
          // const byteArray = await fileToByteArray(file)

          // const fileObject = {
          //   filename: file.name,
          //   size: file.size,
          //   data: Array.from(byteArray),
          // }

          // messageObject['content'] = fileObject
          // await webSocketInstance?.shareFile(messageObject)
        }
      }
      const filesData = files?.map((file) => {
        return {
          file_name: file.name,
          file_type: file.type,
          file_id: file.id,
          file_size: file.size,
        }
      })
      const presSignedUrls = await getPreSignedUrl(filesData)
      if ('data' in presSignedUrls) {
        // map over the files and upload them to S3
        await Promise.all(
          presSignedUrls.data.map(async (signed) => {
            const fileToUpload = files.find((file) => file.id === signed.id)
            return retryableRequest(signed, fileToUpload, messageObject)
          }),
        )
      } else {
        showToast({
          type: 'error',
          message: 'Something went wrong',
        })
        clearAllLocalStorage()
      }
    } catch (e) {
      console.log(e)
      showToast({
        type: 'error',
        message: 'Something went wrong',
      })
      clearAllLocalStorage()
    }

    setTimeout(() => {
      scrollToBottom()
    }, 50)
  }

  // console.log(fileToUpload)
  useEffect(() => {
    const allMessages = messagesHistory?.flatMap((page) => page)
    setGroupedMessages([])
    if (allMessages?.length !== 0) {
      const messagesByDay = allMessages?.reduce((groupedMessages, msg) => {
        const messageDate = new Date(msg?.timestamp)
        const dateLabel = messageDate?.toLocaleDateString(undefined, { day: 'numeric', month: 'long' })

        if (!groupedMessages[dateLabel]) {
          groupedMessages[dateLabel] = []
        }

        groupedMessages[dateLabel].push(msg)

        return groupedMessages
      }, {})

      const groupedMessagesArray = Object.keys(messagesByDay)?.map((date) => {
        return {
          date: date,
          messages: messagesByDay?.[date]?.reverse(),
        }
      })

      setGroupedMessages(groupedMessagesArray)
    }
  }, [messagesHistory, currentPage])

  useEffect(() => {
    if (messagesHistory.length !== 0) {
      const includes = messagesHistory?.[0]?.find((message) => message.id === messageToAdd.id)
      if (includes === undefined && fetchedRoomId === messageToAdd?.room_id) {
        setMessagesHistory((prev) => {
          const newArray = [...prev]
          newArray[0] = [messageToAdd, ...newArray[0]]
          return newArray
        })
        setTimeout(() => {
          scrollToBottom()
        }, 100)
      }
      setIsAttachmentLoading(false)
      setIsMessageSending(false)
      setShowBanner(false)
    }
  }, [messageToAdd])

  const dataLength = messagesHistory.reduce((sum, page) => sum + page?.length, 0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [goToLastMessage, setGoToLastMessage] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const [getUnreadMessagePageNumber, { data: pageNumber }] = useGetUnreadMessagePageNumberMutation()

  useEffect(() => {
    if (messagesHistory?.length > 0) {
      const allMessages = messagesHistory?.flatMap((page) => page)
      const firstUnreadMessage = allMessages?.reverse().find((message) => {
        if (isGroupChat) {
          return !message.message_viewed_by?.includes(currentUserId) && message.author_id !== currentUserId
        }
        return !message.viewed && message.author_id !== currentUserId
      })

      setTimeout(() => {
        if (!firstUnreadMessage) {
          getUnreadMessagePageNumber({
            chat_id: chatId,
          })
        }

        if (firstUnreadMessage) {
          const element = document.getElementById(`message-${firstUnreadMessage.id}`)
          const unreadMessageExists = document.getElementById('unread-message')
          if (element) {
            if (!unreadMessageExists && showBanner) {
              const unreadMessage = document.createElement('div')
              unreadMessage.setAttribute('id', 'unread-message')
              unreadMessage.style.padding = '12px'
              unreadMessage.style.width = '100%'
              unreadMessage.innerText = t('chat_history:unreadMessages')
              unreadMessage.style.color = 'black'
              unreadMessage.style.textAlign = 'center'
              unreadMessage.style.borderRadius = '5px'
              unreadMessage.style.marginTop = '5px'
              unreadMessage.style.marginBottom = '5px'
              unreadMessage.style.backgroundColor = 'rgba(0, 0, 0, 0.1)'
              element.parentElement.insertBefore(unreadMessage, element)
              setGoToLastMessage(true)
            }
            setShowBanner(false)
            element?.scrollIntoView({ behavior: 'instant', block: 'end' })
          }
        }
      }, 100)
    }
  }, [messagesHistory, isGroupChat, currentUserId, showBanner])

  useEffect(() => {
    if (pageNumber) {
      setCurrentPage(pageNumber)
    }
  }, [pageNumber])

  return (
    <Flex
      className={Styles.messagesHistory}
      style={{
        paddingTop: '0px',
      }}
      justifyContent="space-between"
      flexDirection="column"
      width="100%"
    >
      <Flex p={[2]} bg="black.200" boxShadow="sm" zIndex={1} width="100%" gap={2} alignItems="center">
        <ArrowLeftIcon className={Styles.listVisibility} style={{ cursor: 'pointer' }} onClick={goBackToChatList} />

        <>
          <IconButton sx={{ borderRadius: 10 }} onClick={handleClick}>
            <AvatarGroup>
              {selectedChat?.participants?.map((participant) => (
                <Avatar sizes="small" key={participant.user_id} src={participant.profile_pic ?? 'https://placehold.co/400'} alt={participant.profile_name} />
              ))}
            </AvatarGroup>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <UserListPopOver selectedChat={selectedChat} />
          </Menu>
        </>
        <ChatNameDisplay selectedChat={selectedChat} user={user} />
      </Flex>
      <Flex flexGrow={1} className={Styles.messagesHistory__messagesBlock} ref={scrollableContainerRef} id="scrollableDiv">
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={maxPages > currentPage}
          scrollableTarget="scrollableDiv"
          x
          onScroll={(e) => {
            // if scroll position is at the bottom, hide goToLastMessage
            // Because it is inverted the count are in negative
            if (scrollableContainerRef.current?.scrollTop <= -20) {
              setGoToLastMessage(false)
            }
          }}
        >
          {
            dataLength !== 0 || groupedMessages.length !== 0 ? (
              groupedMessages?.map((group, groupIndex) => {
                return (
                  <div className={Styles.messagesHistory__messagesBlock__dayContainer} key={groupIndex}>
                    <div className={Styles.messagesHistory__messagesBlock__dayContainer__date}>
                      <p>{group.date}</p>
                    </div>
                    {group.messages?.map((message) => {
                      return (
                        <div key={message.id} className={Styles.messagesHistory__messagesBlock__dayContainer__messagesContainer}>
                          {message.author === currentUserEmail ? (
                            <SentMessageInstance
                              messageInfo={{
                                content: message.content,
                                message_viewed_by: message.message_viewed_by,
                                timestamp: message.timestamp,
                                attachment: message.attachment,
                                isViewed: message.viewed,
                              }}
                              key={message.id + currentUserEmail}
                              id={`message-${message.id}`}
                              isGroupChat={isGroupChat}
                              participants={selectedChat?.participants}
                            />
                          ) : (
                            <RecievedMessageInstance
                              id={`message-${message.id}`}
                              grouped={grouped}
                              messageInfo={{
                                content: message.content,
                                timestamp: message.timestamp,
                                attachment: message.attachment,
                                senderName: message.sender_name,
                                role: message.role,
                              }}
                              profileAvatar={message?.profile_pic}
                              onChatDetailsOpenning={onChatDetailsOpenning}
                            />
                          )}
                          {fileToUpload?.map((file) => {
                            return (
                              <div key={file.id}>
                                {message.id + 1 === parseInt(futureMessageId) && isAttachmentLoading && fileToUploadLocal && <FileAttachmentLoader file={file} />}
                              </div>
                            )
                          })}
                          {/* {message?.id + 1 === futureMessageId && isAttachmentLoading && <FileAttachmentLoader file={fileToUpload} />} */}
                        </div>
                      )
                    })}
                  </div>
                )
              })
            ) : (
              <Placeholder text={t('chat_history:no_messages')} />
            )

            // <>{isMessageSending && isAttachmentLoading ? <FileAttachmentLoader file={fileToUpload} /> : <Placeholder text={t('chat_history:no_messages')} />}</>
          }
        </InfiniteScroll>
        {goToLastMessage && (
          <IconButton
            sx={{
              position: 'absolute',
              bottom: '120px',
              right: '60px',
              zIndex: 10,
              // backgroundColor: 'rgba(0, 0, 0, 0.4)',
              // color: theme.colors.white,
              border: 1,
            }}
            color="secondary"
            onClick={() => {
              scrollToBottom()
              setGoToLastMessage(false)
            }}
          >
            <ArrowDownward />
          </IconButton>
        )}
      </Flex>

      <div className={Styles.messagesHistory__sendBtnBlock}>
        <NewMessageBar isMessageSending={isMessageSending || isAttachmentLoading} onSubmit={handleNewMessageSend} />
      </div>
    </Flex>
  )
}

MessagesHistory.propTypes = {
  currentUserId: number,
  currentUserEmail: string,
  roomId: string,
  chatId: number,
  onNewMessageSend: func,
  // onShowContactDetails: func,
  onViewLastMessages: func,
}

const UserListPopOver = ({ selectedChat }) => {
  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: 2,
        minWidth: 250,
        maxHeight: 300,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
      }}
    >
      <Text variant="h6" p={2} borderBottom="1px solid #eee">
        Chat Members
      </Text>

      <Stack spacing={1} p={2}>
        {selectedChat.participants.map((participant, index) => (
          <Flex
            key={index}
            alignItems="center"
            gap={2}
            p={1.5}
            sx={{
              borderRadius: 1,
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
                transform: 'translateX(4px)',
              },
            }}
          >
            <Avatar src={participant.profile_pic || 'https://placehold.co/400'} alt={participant.user_name} sx={{ width: 36, height: 36 }} />
            <Box>
              <Text fontSize="16px" fontWeight={500}>
                {participant.user_name}
              </Text>
              <Text variant="light">{participant.role}</Text>
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  )
}
