import { Box, Typography, Paper } from '@mui/material'
import { CreditCard, Timeline, Lock } from '@mui/icons-material'
import { Button, CenterColumn, Container, Text } from 'components/Core'

type Props = {
  handleRenewal: () => void
}
export const NoCreditsLeft = ({ handleRenewal }: Props) => {
  return (
    <Container height="91vh">
      <Paper
        elevation={1}
        sx={{
          p: 4,
          minWidth: 700,
          mx: 'auto',
          mt: 4,
          borderRadius: 3,
          // background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
          textAlign: 'center',
        }}
      >
        <Lock sx={{ fontSize: 60, color: 'secondary.main', mb: 2 }} />

        <CenterColumn gap={2}>
          <Text minWidth="100%" textAlign="center" variant="heading2" color="typePurpure.300" fontWeight="bold">
            You have finished booking all your sessions
          </Text>
          <Typography variant="body1" color="text.secondary" mb={4}>
            Continue your therapy journey by renewing your subscription to unlock more sessions
          </Typography>
        </CenterColumn>

        <Box display="flex" justifyContent="center" gap={3} my={4}>
          <Box textAlign="center">
            <Timeline sx={{ color: 'secondary.main', fontSize: 40 }} />
            <Typography variant="subtitle2">Continuous Progress</Typography>
          </Box>
          <Box textAlign="center">
            <CreditCard sx={{ color: 'secondary.main', fontSize: 40 }} />
            <Typography variant="subtitle2">Flexible Plans</Typography>
          </Box>
        </Box>

        <Container>
          <Button onClick={handleRenewal}>Renew Subscription</Button>
        </Container>
      </Paper>
    </Container>
  )
}
