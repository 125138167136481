// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

export const schemaValidProvideDiscount = ({ activeOption, sessionPrice }) => {
  return yupResolver(
    Yup.object().shape({
      discount_value_percentage:
        activeOption === 'percentage'
          ? Yup.number()
              .test('positive-number', 'The percentage value must greater than 0', (value) => {
                return value > 0
              })
              .max(100, `The percentage value shouldn't be more than 100`)
              .typeError('The percentage value must be a number')
          : Yup.string().notRequired(),
      discount_value_amount:
        activeOption === 'amount'
          ? Yup.number()
              .test('positive-number', 'The discount value must greater than 0', (value) => {
                return value > 0
              })
              .max(sessionPrice, `The amount value shouldn't be more than ${sessionPrice}`)
              .typeError('The amount value must be a number')
          : Yup.string().notRequired(),
    }),
  )
}
