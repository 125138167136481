import Styles from './Styles.module.scss'

import React, { useEffect, useState } from 'react'
import { ApplePayButton, abortApplePaySession } from '@tap-payments/apple-pay-button'
import { APPLE_PAY_MERCHANT_DOMAIN, APPLE_PAY_MERCHANT_ID, paymentKey } from 'constants/index'
import { CircularProgress, Modal, Typography } from '@mui/material'
import { CloseIcon } from 'theme/assets/icons'
import { PaymentPage } from '../TapGateway'
import { useOnPaymentSuccessMutation } from 'defaultLayout/slice/service'
import { apiRoutes } from 'apiRoutes'
import { useTapPayments } from 'hooks/useTapPayments'
import { Box, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import { notify } from 'helpers'
import { Check, Lock, Sync } from '@mui/icons-material'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useTranslation } from 'react-i18next'
import { CountdownComponent } from 'components/GeneralComponents'
export const MacOSPayment = ({
  open,
  onClose,
  orderDetails,
  isOpenPaymentPopup,
  showCountdown = false,
  showTopLeftHeader = false,
  setIsOpenPaymentPopup,
  openPaymentExpiresPopup,
  webhookUrl = '',
  redirectUrl = '',
}) => {
  const { amount, currency, full_name, email, created_at } = orderDetails
  // console.log('========>', created_at)
  // console.log(redirectUrl, webhookUrl, orderDetails)
  const [processCharge, { isLoading, isError, isSuccess, reset }] = useOnPaymentSuccessMutation()
  const { handlePrepareApplePayCharge } = useTapPayments({
    orderDetails,
    webhookUrl: webhookUrl ?? apiRoutes.payment.webhookUrl,
    redirectUrl,
  })
  const handleClosePopupTimeExpires = () => {
    onClose()
    openPaymentExpiresPopup(true)
  }
  const openTapPaymentModal = () => {
    setIsOpenPaymentPopup(true)
  }
  const { direction } = useGetLanguage()
  const { t } = useTranslation()

  useEffect(() => {
    if (isError) {
      notify(t('global:payment:failed'))
    }
  }, [isError])
  useEffect(() => {
    if (isSuccess) {
      notify(t('global:payment:success'), false)
      onClose(true)
      setIsOpenPaymentPopup(false)
      window.location.replace(redirectUrl)
      reset()
    }
  }, [isSuccess])
  return (
    <div className="App" allow="payment">
      <Modal
        sx={{
          direction,
        }}
        open={open}
        onClose={onClose}
      >
        {isLoading ? (
          <Box variant="modal-container">
            <Container flexDirection="column" gap={16} width="100%">
              <Typography variant="h5">{t('global:payment:processing.title')}</Typography>
              <CircularProgress size={60} thickness={4} />
              <CenterColumn gap={2}>
                <Text textAlign="center" variant="body1">
                  {t('global:payment:processing.subtitle')}
                </Text>
                <CenterRow gap={2}>
                  <Check sx={{ color: '#4CAF50' }} /> {t('global:payment:processing.steps.validating')}
                  <Sync className={Styles.rotating} sx={{ color: '#2196F3' }} /> {t('global:payment:processing.steps.validating')}
                  <Lock sx={{ color: '#FFC107' }} /> {t('global:payment:processing.steps.validating')}
                </CenterRow>
              </CenterColumn>
            </Container>
          </Box>
        ) : (
          <>
            {showTopLeftHeader && (
              <div className={Styles.countdown_conatiner}>
                {showCountdown && (
                  <CenterRow gap={10} m={2} flexWrap="wrap" bg="white" p={10} px={16} borderRadius={10} width="max-content">
                    <h5>{t('global:paymentExpireIn')}:</h5>
                    <CountdownComponent creationTimestamp={created_at} onComplete={handleClosePopupTimeExpires} />
                  </CenterRow>
                )}
              </div>
            )}
            {!isOpenPaymentPopup && (
              <Box variant="modal-container" className={Styles.main_container}>
                <div className={Styles.main_container__header}>
                  <h6>{t('global:payment:selectMethod')}</h6>
                  <CloseIcon onClick={onClose} className={Styles.icon} />
                </div>
                <ApplePayButton
                  debug={true}
                  scope="TapToken"
                  publicKey={paymentKey}
                  merchant={{
                    domain: APPLE_PAY_MERCHANT_DOMAIN,
                    id: APPLE_PAY_MERCHANT_ID,
                  }}
                  environment="production"
                  acceptance={{
                    supportedBrands: ['masterCard', 'visa'],
                  }}
                  features={{
                    supportsCouponCode: false,
                    // shippingContactFields: ['name', 'phone', 'email'],
                  }}
                  transaction={{
                    currency,
                    amount,
                  }}
                  customer={{
                    name: [
                      {
                        locale: 'en',
                        first: full_name,
                        last: full_name,
                      },
                    ],
                    contact: {
                      email,
                      // phone: {
                      //   number: phone_number,
                      // },
                    },
                  }}
                  interface={{
                    locale: 'en',
                    theme: 'dark',
                    type: 'buy',
                    edges: 'curved',
                  }}
                  onCancel={() => {
                    // it's called when the user cancels the payment
                    console.log('onCancel')
                  }}
                  onError={(error) => {
                    // it's called when there is an error with the payment
                    console.log('onError', error)
                  }}
                  onReady={() => {
                    // it's called when the apple pay button is ready to click
                    console.log('onReady')
                  }}
                  onSuccess={async (data, event) => {
                    // it's called when the payment is successful
                    console.log('onSuccess', data)
                    // event is the same as the event in the onpaymentauthorized event https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentauthorizedevent
                    console.log('apple pay event', event)
                    const chargeData = handlePrepareApplePayCharge(data?.id)
                    await processCharge(chargeData)
                  }}
                  // onMerchantValidation={(status) => {
                  //   // it's called when the merchant validation is done
                  //   console.log('onMerchantValidation', status)
                  // }}
                  // onPaymentMethodSelected={async (paymenMethod) => {
                  //   // it's a function that give you a chance to update the total
                  //   // amount and the line items based on the payment method
                  //   return {
                  //     newTotal: {
                  //       label: 'Merchant Name',
                  //       amount: '1.00',
                  //     },
                  //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentmethodupdate)
                  //   }
                  // }}
                  // onShippingMethodSelected={async (shippingMehod) => {
                  //   // it's a function that give you a chance to update the total
                  //   // amount and the line items based on the shipping method
                  //   return {
                  //     newTotal: {
                  //       label: 'Merchant Name',
                  //       amount: '1.00',
                  //     },
                  //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingmethodupdate)
                  //   }
                  // }}
                  // onShippingContactSelected={async (shippingContact) => {
                  //   // it's a function that give you a chance to update the total
                  //   // amount and the line items based on the shipping contact
                  //   return {
                  //     newTotal: {
                  //       label: 'Merchant Name',
                  //       amount: '1.00',
                  //     },
                  //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepayshippingmethodupdate)
                  //   }
                  // }}
                  // onCouponChanged={async (couponCode) => {
                  //   // it's a function that give you a chance to update the total
                  //   // amount and the line items based on the coupon code
                  //   return {
                  //     newTotal: {
                  //       label: 'Merchant Name',
                  //       amount: '1.00',
                  //     },
                  //     // you can pass rest of options regarding applepay documentation (https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentmethodupdate)
                  //   }
                  // }}
                />
                <p onClick={openTapPaymentModal}>{t('global:payment:otherMethod')}</p>
              </Box>
            )}
          </>
        )}
      </Modal>
    </div>
  )
}
