import { CenterColumn, CenterRow } from 'components/Core'
import { useGetConversionsQuery } from './service'
import { Card, CardContent, CardHeader, Paper, Tooltip, Typography, Skeleton, useTheme, Box } from '@mui/material'
import { DateRangePicker, type RangeKeyDict } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useState } from 'react'
import { useDisclosure } from 'hooks/useDisclosure'
import { DateRangePopover } from './DatePopover'
import { apiRoutes } from 'apiRoutes'
import { Info } from '@mui/icons-material'

export const Health = () => {
  const theme = useTheme()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const { data, isError, isFetching } = useGetConversionsQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
  })
  const { data: conversion, isFetching: fetchingConversion } = useGetConversionsQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
    url: apiRoutes.adminAnalytics.conversions,
  })
  const { data: distinctConversion, isFetching: fetchingDistinctConversion } = useGetConversionsQuery({
    end_date: endDate?.toISOString(),
    start_date: startDate?.toISOString(),
    url: apiRoutes.adminAnalytics.distinctConversions,
  })

  const handleSelect = (ranges: RangeKeyDict) => {
    setStartDate(ranges.selection.startDate!)
    setEndDate(ranges.selection.endDate!)
  }

  const renderMetricCard = (title: string, value: number | undefined, description: string, isLoading: boolean) => (
    <Card
      elevation={3}
      sx={{
        flex: 1,
        minWidth: '220px',
        maxWidth: '400px',
        borderRadius: '16px',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
        },
        background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
        border: '1px solid rgba(0,0,0,0.05)',
      }}
    >
      <CardHeader
        title={
          <CenterRow justifyContent="space-between">
            <Typography variant="subtitle1" color="text.secondary" fontWeight="medium">
              {title}
            </Typography>
            <Tooltip title={description}>
              <Info fontSize="small" sx={{ color: 'text.secondary', opacity: 0.6, cursor: 'pointer' }} />
            </Tooltip>
          </CenterRow>
        }
        sx={{ pb: 0 }}
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={60} sx={{ borderRadius: '8px' }} />
        ) : (
          <Typography fontSize={['24px', 30, 35, 40]} fontWeight="bold" color="text.primary">
            {formatNumber(value)}
          </Typography>
        )}
      </CardContent>
    </Card>
  )

  const renderSection = (title: string, isLoading: boolean, content: React.ReactNode) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        mb: 4,
        borderRadius: '16px',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(0,0,0,0.05)',
      }}
    >
      <Typography variant="h5" fontWeight="bold" mb={4} color="secondary.main">
        {title}
      </Typography>
      {content}
    </Paper>
  )

  return (
    <CenterColumn
      gap="24px"
      p={[2, 3, 4]}
      // pt={[3, 4, 5]}
    >
      <CenterRow width="100%" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          Metrics
        </Typography>
        <DateRangePopover
          handleClear={() => {
            setStartDate(undefined)
            setEndDate(undefined)
          }}
          endDate={endDate}
          startDate={startDate}
          handelSelect={handleSelect}
        />
      </CenterRow>

      {renderSection(
        'Health',
        isFetching,
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: '20px',
            mt: 2,
          }}
        >
          {renderMetricCard('New Sign Ups', data?.new_signups, 'Total number of new users who signed up within the specified period', isFetching)}
          {renderMetricCard('Advisory Calls', data?.advisory_calls, 'Total number of advisory calls booked within the specified period', isFetching)}
          {renderMetricCard('Discovery Calls', data?.discovery_calls, 'Total number of discovery calls booked within the specified period', isFetching)}
          {renderMetricCard('Therapy Sessions', data?.paid_consultations, 'Total number of paid therapy booked within the specified period', isFetching)}
        </Box>,
      )}

      {renderSection(
        'Cumulative conversions',
        fetchingConversion,
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: '20px',
            mt: 2,
          }}
        >
          {renderMetricCard('Number of Sign-ups', conversion?.new_signups, 'Total number of new users who signed up within the specified period', fetchingConversion)}
          {renderMetricCard('Number of Advisory Call', conversion?.advisory_calls, 'The cumulative total number of advisory calls booked by these users', fetchingConversion)}
          {renderMetricCard('Number of Discovery Call', conversion?.discovery_calls, 'The cumulative total number of discovery calls booked by these users', fetchingConversion)}
          {renderMetricCard('Number of Therapy Session', conversion?.paid_consultations, 'The cumulative total number of therapy calls booked by these users', fetchingConversion)}
        </Box>,
      )}
      {renderSection(
        'Individual conversions',
        isFetching,
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: '20px',
            mt: 2,
          }}
        >
          {renderMetricCard(
            'Number of Sign-ups',
            distinctConversion?.new_signups,
            'Total number of new users who signed up within the specified period',
            fetchingDistinctConversion,
          )}
          {renderMetricCard(
            'Number of users who booked advisory calls',
            distinctConversion?.advisory_calls,
            'The number of new users that booked their first advisory call',
            fetchingDistinctConversion,
          )}
          {renderMetricCard(
            'Number of users who booked discovery calls',
            distinctConversion?.discovery_calls,
            'The number of new users that booked their first discovery call',
            fetchingDistinctConversion,
          )}
          {renderMetricCard(
            'Number of users who booked therapy calls',
            distinctConversion?.paid_consultations,
            'The number of new users that booked their first therapy call',
            fetchingDistinctConversion,
          )}
        </Box>,
      )}
    </CenterColumn>
  )
}

const formatNumber = (number: number | undefined) => {
  if (!number) return 0
  return new Intl.NumberFormat('en-IN').format(number)
}
