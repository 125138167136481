import { ReactNode, useState } from 'react'
import { Box, Flex, Text, CenterColumn, Chip, CenterRow } from 'components/Core'
import { Avatar, IconButton, Modal, Card, CardContent, Badge, Accordion, AccordionSummary, AccordionDetails, Tooltip, Typography } from '@mui/material'
import { ExpandMore, Info, Event, Check, Close, Help } from '@mui/icons-material'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { statusColor, SubscriptionType } from 'constants/index'
import { Therapist } from 'utils/types/types'
import { dateFormatter, sessionStatusColor } from 'utils/utils'

const statusIcons = {
  waiting: <Help fontSize="small" />,
  accepted: <Check fontSize="small" />,
  declined: <Close fontSize="small" />,
}

type Props = {
  therapists: Array<Therapist>
}

export const AssignedTherapists = ({ therapists }: Props) => {
  const [reason, setReason] = useState('')
  const [selectedTherapist, setSelectedTherapist] = useState<Therapist>()
  const { open, onClose, onOpen } = useDisclosure()

  if (!therapists || therapists.length === 0) {
    return null
  }

  const handleShowReason = (therapist: Therapist, reason: string) => {
    setSelectedTherapist(therapist)
    setReason(reason)
    onOpen()
  }

  const attendedColor = (attended: string) => {
    if (attended === 'yes') {
      return 'green'
    } else if (attended === 'no') {
      return 'red'
    } else {
      return 'gray'
    }
  }

  return (
    <Box>
      <Flex alignItems="center" mb={4}>
        <Text fontSize="18px" fontWeight="600" color="purple.700">
          Assigned Therapists
        </Text>
        <Badge badgeContent={therapists.length} />
      </Flex>

      <Flex flexWrap="wrap" gap={4} justifyContent="flex-start">
        {therapists.map((therapist, index) => (
          <Card
            key={index}
            elevation={1}
            sx={{
              width: { xs: '100%', sm: '100%', md: '78%', lg: '45%' },
              borderRadius: '12px',
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            <CardContent sx={{ padding: '16px' }}>
              {/* Subscription Badge */}
              <Flex justifyContent="space-between" alignItems="center" mb={2}>
                <Chip
                  value={therapist?.subscription?.type ?? SubscriptionType.PAY_AS_YOU_GO}
                  textTransform="capitalize"
                  type={therapist?.subscription?.type === SubscriptionType.MEMBERSHIP ? 'default' : 'secondary'}
                  size="small"
                />
              </Flex>

              {/* Therapist Profile */}
              <Flex alignItems="center" gap={2} mb={3}>
                <Avatar
                  src={therapist?.user?.profile_pic}
                  sx={{
                    width: 56,
                    height: 56,
                    border: '2px solid',
                    borderColor: 'primary.light',
                  }}
                  // name={therapist?.user?.full_name}
                />
                <Box>
                  <Text fontWeight="600" fontSize="16px" color="purple.800">
                    {therapist?.user?.full_name}
                  </Text>
                  <Text fontSize="14px" color="gray.600">
                    {therapist?.experience} years of experience
                  </Text>
                </Box>
              </Flex>

              {/* Status Cards */}
              <CenterColumn gap={2}>
                <Flex gap={2} flexWrap="wrap">
                  <StatusCard
                    label="Therapist"
                    status={therapist?.approval_status?.therapist_status}
                    color={statusColor[therapist?.approval_status?.therapist_status as keyof typeof statusColor]}
                    onShowReason={() => {
                      handleShowReason(therapist, therapist?.approval_status?.decline_reason)
                    }}
                  />

                  <StatusCard
                    label="Parent"
                    status={therapist?.approval_status?.parent_status}
                    color={statusColor[therapist?.approval_status?.parent_status as keyof typeof statusColor]}
                    onShowReason={() => {
                      handleShowReason(therapist, therapist?.approval_status?.decline_reason)
                    }}
                  />
                </Flex>

                {/* <Divider sx={{ margin: '12px 0' }} /> */}
                <CenterColumn bg="black.100" p={2} borderRadius={10} mt={3} width="100%">
                  <CenterRow gap={2}>
                    <CenterColumn gap={1}>
                      <Text fontSize={[16, 16, 20]} fontWeight="700">
                        Discovery Call
                      </Text>
                      {therapist?.approval_status?.discovery_call_booked !== 'Not Booked' && therapist?.approval_status?.discovery_call_date && (
                        <CenterRow gap={2} textAlign="center">
                          <Event fontSize="small" sx={{ ...sessionStatusColor(therapist?.approval_status?.discovery_call_booked) }} />
                          <Text variant="light" fontWeight="medium">
                            {dateFormatter({ date: therapist?.approval_status?.discovery_call_date, formatType: 'Pp' })}
                          </Text>
                        </CenterRow>
                      )}
                    </CenterColumn>
                  </CenterRow>
                  <CenterRow mt={therapist?.approval_status?.discovery_call_booked ? 0 : 2} gap={2}>
                    <Text variant="light">Status:</Text>
                    <Chip
                      width="fit-content"
                      value={therapist?.approval_status?.discovery_call_booked ?? 'Not Booked'}
                      {...sessionStatusColor(therapist?.approval_status?.discovery_call_booked)}
                    />
                  </CenterRow>
                  <CenterRow gap={2}>
                    <Text variant="light">Parent Attended:</Text>
                    <Typography textTransform="capitalize" color={attendedColor(therapist?.approval_status?.discovery_feedback?.feedback)} variant="body2" fontWeight="medium">
                      {['yes', 'no'].includes(therapist?.approval_status?.discovery_feedback?.feedback) ? therapist?.approval_status?.discovery_feedback?.feedback : 'No data'}
                    </Typography>
                  </CenterRow>
                </CenterColumn>
                {/* Discovery Feedback */}
                {therapist?.approval_status?.discovery_feedback?.tell_us_more && (
                  <Accordion
                    elevation={0}
                    sx={{
                      '&:before': { display: 'none' },
                      backgroundColor: 'rgba(132, 80, 160, 0.04)',
                      borderRadius: '8px',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Text variant="body2" fontWeight="500" color="purple.700">
                        Therapist Feedback
                      </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Text
                        variant="body2"
                        color="gray.600"
                        backgroundColor="white"
                        padding="8px 12px"
                        borderRadius="6px"
                        maxHeight="120px"
                        overflowY="auto"
                        border="0.5px solid"
                        borderColor="black.300"
                      >
                        {therapist?.approval_status?.discovery_feedback?.tell_us_more || 'No additional information provided'}
                      </Text>
                    </AccordionDetails>
                  </Accordion>
                )}
              </CenterColumn>
            </CardContent>
          </Card>
        ))}
      </Flex>

      {/* Reason Modal */}
      <Modal open={open} onClose={onClose} aria-labelledby="decline-reason-modal" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box bg="white" p={4} width={['90%', '70%', '50%', '30%']} borderRadius="12px" boxShadow="normal">
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            <Box>
              <Text variant="heading3" fontWeight="600" color="purple.800">
                Decline Reason
              </Text>
              {selectedTherapist && (
                <Text variant="body2" color="gray.600">
                  {selectedTherapist.user?.full_name}
                </Text>
              )}
            </Box>
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          </Flex>

          <Box p={3} borderRadius="8px" bg="rgba(132, 80, 160, 0.04)" border="1px solid" borderColor="purple.200">
            <Text>{reason || 'No reason provided'}</Text>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

// Status Card Component
type StatusCardProps = {
  label: string
  status: keyof typeof statusIcons
  color: { backgroundColor: string; color: string }
  onShowReason?: () => void
  icon?: ReactNode
}
const StatusCard = ({ label, status, color, onShowReason, icon }: StatusCardProps) => {
  const isDeclined = status === 'declined'

  return (
    <Box borderRadius="8px" p={2} flexGrow={1} bg={color?.backgroundColor || 'gray.100'} border="1px solid" borderColor={color?.color || 'gray.300'} position="relative">
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Text fontSize="12px" color="gray.600" mb={1} textAlign="center">
          {label}
        </Text>

        <Flex alignItems="center" justifyContent="center">
          {icon || statusIcons[status] || <Info fontSize="small" />}
          <Text style={{ textTransform: 'capitalize' }} ml={1} fontWeight="500" color={color?.color || 'gray.700'}>
            {status}
          </Text>
        </Flex>

        {isDeclined && (
          <Tooltip title="View reason">
            <IconButton
              size="small"
              onClick={onShowReason}
              sx={{
                position: 'absolute',
                top: '4px',
                right: '4px',
                padding: '2px',
                backgroundColor: 'rgba(255,255,255,0.7)',
              }}
            >
              <Info fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    </Box>
  )
}
