import { rootServiceApi } from 'store/service'

const chatService = rootServiceApi.injectEndpoints({
  endpoints(build) {
    return {
      getUnreadMessagePageNumber: build.mutation<{ page_number: number }, { chat_id: number }>({
        query: ({ chat_id }) => ({
          url: `/chats/unread-message-page-number/${chat_id}/`,
          method: 'GET',
        }),
      }),
    }
  },
})

export const { useGetUnreadMessagePageNumberMutation } = chatService
