import { Alert, CircularProgress, Divider } from '@mui/material'
import { Box, Button, CenterRow, Container, Text, TextArea } from 'components/Core'
import { OutlinedCard } from 'components/OutlinedCard/OulinedCard'
import { useEffect, useState } from 'react'
import { ThumbsDown, ThumbsUp } from 'theme/assets/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from 'shardSelectors/seletors'
import { SuccessLottie } from 'components/SuccessLottie/SuccessLottie'
import { parentActions } from 'actions'
import { useFeedBackMutation, useGetFeedbackQuery } from 'components/FeedbackModal/service'

import { useSearchParams } from 'react-router-dom'
import { statusColor, userTypes } from 'constants/index'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { selectZoomLeaveUrl } from 'defaultLayout/slice/selectors'
import { t } from 'i18next'
import { useGetSurveyFeedbackQuery, usePostTherapistSurveyMutation } from './service'
import { useAuth } from 'hooks/useAuth'
import { Check } from '@mui/icons-material'

type ErrorType = { data: { message: string } }
type Feedback = { feedback: 'yes' | 'no' | undefined; tell_us_more?: string }

export const TherapistSurvey = () => {
  const [searchParams] = useSearchParams()

  const [showFeedbackForm, setShowFeedbackForm] = useState(false)
  const [uiError, setUiError] = useState<string>('')
  const [feedback, setFeedBack] = useState<Feedback>({ feedback: undefined, tell_us_more: '' })
  const meetingId = searchParams.get('id')
  const [submitFeedback, { isLoading, error, isSuccess, reset }] = useFeedBackMutation()
  const { user } = useAuth()
  const { direction } = useGetLanguage({ userType: user?.user_type })

  const singleFeedback = useGetFeedbackQuery({ meeting_id: meetingId }, { skip: !meetingId })

  const zoomLeaveUrl = useSelector(selectZoomLeaveUrl)

  useEffect(() => {
    // if (singleFeedback.data === true) {
    //   window.location.replace(zoomLeaveUrl)
    // }
    setTimeout(() => {
      setShowFeedbackForm(true)
    }, 2500)
  }, [singleFeedback.data, zoomLeaveUrl])

  // Zoom Meeting Id
  const handleGoBack = () => {
    window.location.replace(zoomLeaveUrl)
  }

  useEffect(() => {
    if (singleFeedback.data) {
      setFeedBack(singleFeedback.data)
    }
  }, [singleFeedback.data])
  const getButtonTheme = (feedback: string | undefined, yesButton: boolean = false) => {
    switch (true) {
      case feedback === 'yes' && yesButton:
        return { bg: '#28A745', color: 'white' }
      case feedback === 'no' && !yesButton:
        return { bg: '#DC3545', color: 'white' }
      default:
        if (yesButton) {
          return { color: '#28A745', bg: 'white', border: '1px solid #28A745' }
        } else {
          return { color: '#DC3545', bg: 'white', border: '1px solid #DC3545' }
        }
    }
  }
  return (
    <Container p={2} px={[3]} gap={2} bg="white" flexDirection="column" width="100%" direction={direction} minHeight="100%">
      {singleFeedback.isLoading || !showFeedbackForm ? (
        <CircularProgress sx={{ mt: 2 }} />
      ) : isSuccess ? (
        <CenterRow pb={[30]} flexDirection="column" gap={20}>
          <Box width={100} height={100}>
            <SuccessLottie />
          </Box>
          <Text fontSize={20}>{t('global:feedback:success')}</Text>

          <Button
            onClick={() => {
              handleGoBack()
            }}
          >
            {t('global:feedback:goBack')}
          </Button>
        </CenterRow>
      ) : (
        <Box>
          <Box mb={10} alignSelf="center">
            {error || (uiError && !feedback.feedback) ? (
              <Alert severity="error">{t(`global:feedback:errors:${(error as unknown as ErrorType)?.data?.message ?? uiError}`) as string}</Alert>
            ) : null}
          </Box>
          <Container gap={42} pb={30} flexDirection="column">
            <Text textAlign="center" variant="heading4">
              {t('global:discoveryCallTherapistSurvey:title')}
            </Text>
            <CenterRow flexWrap="wrap" width="100%" justifyContent="center" gap={30}>
              <Button minWidth={['232px']} {...getButtonTheme(feedback.feedback, true)} flexGrow={1} onClick={() => setFeedBack((prev) => ({ ...prev, feedback: 'yes' }))}>
                {feedback.feedback === 'yes' && <Check />}
                {t('global:discoveryCallTherapistSurvey:yes')}
              </Button>
              <Button minWidth={['232px']} {...getButtonTheme(feedback.feedback)} flexGrow={1} onClick={() => setFeedBack((prev) => ({ ...prev, feedback: 'no' }))}>
                {feedback.feedback === 'no' && <Check />} {t('global:discoveryCallTherapistSurvey:no')}
              </Button>
            </CenterRow>
            <Box width="100%">
              <CenterRow mb={10} gap={1}>
                <Text>{t('global:feedback:tellUsMore')}</Text>
                <Text color="black.500">({t('global:feedback:optional')})</Text>
              </CenterRow>
              <TextArea
                style={{ resize: 'none' }}
                height={119}
                maxHeight={119}
                placeholder={t('global:feedback:tellUsMore')}
                value={feedback.tell_us_more}
                onChange={(e) => setFeedBack((prev) => ({ ...prev, tell_us_more: e.target.value }))}
              />
            </Box>
            {/* )} */}
            <Button
              onClick={() => {
                setUiError('')
                if (!feedback.feedback) {
                  setUiError('selectFeedback')
                  return
                }
                submitFeedback({
                  body: { feedback: feedback.feedback, tell_us_more: feedback.tell_us_more, meeting_id: meetingId },
                })
              }}
              width={['100%']}
              borderRadius={['10px']}
              fontWeight="bold"
              variant="primary"
            >
              {isLoading ? <CircularProgress color="inherit" size={25} /> : singleFeedback.data ? t('global:update') : t('global:feedback:submit')}
            </Button>

            {/* <Button onClick={handleGoBack} fontSize={17} variant="ghost" color="textLightGray">
              {t('global:feedback:skipForNow')}
            </Button> */}
          </Container>
        </Box>
      )}
    </Container>
  )
}
