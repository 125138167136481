import { useLocation, useParams } from 'react-router-dom'
import { useEnrollCourseMutation, useGetCourseDetailQuery } from './service'
import { CourseDetail } from './CourseDetail'

export const ParentCourseDetailView = () => {
  const { courseId } = useParams()
  const { state } = useLocation()
  // const [orderDetails, setOrderDetail] = useState({})
  const { data: course, isLoading: fetchingCourse } = useGetCourseDetailQuery(courseId as string, { skip: !courseId })
  const [enroll, { isLoading, isSuccess, data }] = useEnrollCourseMutation()
  return <CourseDetail course={course} courseId={courseId} fetchingCourse={fetchingCourse} isEnrolling={isLoading} isSuccess={isSuccess} state={state} enroll={enroll} />
}
