// Core
// Routing
import { Routes, Route, Outlet, useLocation, useNavigate } from 'react-router-dom'
// Components
import { Main } from '../components'
// Pages
import {
  AdminChatPage,
  AdminParentProfilePage,
  AdvisorApplicationsPage,
  AdvisorAssignTherapistsPage,
  AdvisorChatPage,
  AdvisorParentsPage,
  AdvisorTherapistsPage,
  AdvisorsPage,
  Error404Page,
  ParentChatPage,
  TherapistChatPage,
  QuestionsPage,
  SummaryPage,
  TherapyPage,
  TherapistApplicationsPage,
  TherapistPatientsPage,
  ChildProfilePage,
  ParentsAdminPage,
  NotesPage,
  AdminMeetingsPage,
  SignInPage,
  ParentQuestionnaireForExistingAccount,
  AdvisorParentProfilePage,
} from '../pages'
import { AuthProvider } from 'context/AuthContext'
import { ZoomEmbedded } from 'components/ParentFlow/Therapy/ZoomPopup/ZoomEmbedded'
import { FeedbackForm } from 'pages/FeedbackForm/FeedbackForm'
import { Unauthorized } from 'pages/Unauthorized/Unauthorized'
import { ParentTreatmentPlan } from 'pages/ParentPages/ParentTreatmentPlan'
import { ParentTaskView } from 'pages/ParentPages/ParentTaskView'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import en from 'pages/ShardPage/ParentTaskAndTreatmentPlanLocale/en.json'
import ar from 'pages/ShardPage/ParentTaskAndTreatmentPlanLocale/ar.json'
import { deleteLocalStorage } from 'utils'
import { PARENT_ONBOARDING_COOKIES_NAME } from 'data/enums'
import { AdminAnalytics } from 'components/AdminPanel/AdminAnalytics/AdminAnalytics'
import { useAuth } from 'hooks/useAuth'
import { useLinkExistingUserWithPromoCodeMutation, useOpenNotificationMutation } from 'defaultLayout/slice/service'
import { useNotifications } from 'serviceWorker/useNotification'
import { Box, Button, CenterColumn, CenterRow, Text, Image } from 'components/Core'
import { usePWA } from 'serviceWorker/usePWA'
import { IconButton, Modal } from '@mui/material'
import { CloseIcon } from 'theme/assets/icons'
import { useContentBundle } from 'hooks/useContentBundle'
import modalEnglish from './locale/en.json'
import modalArabic from './locale/ar.json'
import { NewParentOnBoarding } from 'components/ParentFlow/Therapy/NewParentOnboarding/NewParentOnBoarding'
import { ChatPage } from 'pages/NewChatPage/NewChatPage'
import { Courses } from 'pages/Courses/Courses'
import { PaymentSuccessOrErrorPage } from 'pages/PaymentSuccess/PaymentPage'
import { CoursePage } from 'pages/Courses/CoursePage'
import { courseEnglish } from './locale/course_english'
import { courseArabic } from './locale/course_arabic'
import WebSocketService from 'websocket'
import { useDefaultLayoutSlice } from 'defaultLayout/slice'
import { ParentCourseDetailView } from 'pages/Courses/ParentCourseDetailView'
import { MembershipBooking } from 'pages/MembershipBooking/MembershipBooking'
import { useGetChannelsQuery } from 'pages/NewChatPage/service'
import { useSelector } from 'react-redux'
import { selectChatSelectedProfile } from 'defaultLayout/slice/selectors'
import { useIntercom } from 'hooks/useIntercom'
import { BookingTherapy } from 'pages/BookingTherapy/BookingTherapy'
import notificationSound from 'theme/assets/notification-sound.mp3'
import { SessionPaymentPage } from 'pages/PaymentSuccess/SessionPaymentPage'
import { Payment } from 'pages/Payment/Payment'
import { TherapistSurvey } from 'pages/TherapistSurvey/TherapistSurvey'
import { BookOnBehalf } from 'pages/BookOnBehalf/BookOnBehalf'
import { useGetUserPurchasedCoursesQuery } from 'pages/Courses/service'
import { parentActions } from 'actions'

export const Private = () => {
  console.log('Private')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [previousCount, setPreviousCount] = useState(Number(localStorage.getItem('lastNotificationCount') || '0'))
  const { enableNotifications } = useNotifications()
  const lastSelectedProfile = localStorage.getItem('lastSelectedProfile')
  const [webSocketInstance, setWebSocketInstance] = useState<WebSocketService>()
  const { dispatch, actions } = useDefaultLayoutSlice()
  const { user } = useAuth()
  const { i18n, t } = useTranslation()
  const [notificationOpened] = useOpenNotificationMutation()
  const selectedProfile = useSelector(selectChatSelectedProfile)
  const { data: userPurchasedCourses } = useGetUserPurchasedCoursesQuery()
  const { refetch: refetchChats } = useGetChannelsQuery(
    {
      profile_id: String(selectedProfile || lastSelectedProfile),
      userType: user.user_type,
    },
    {
      skip: !user.user_type || !(selectedProfile ?? lastSelectedProfile),
    },
  )

  const { pathname } = useLocation()
  useEffect(() => {
    i18n.addResourceBundle('en', 'treatmentPlanPlusTask', en)
    i18n.addResourceBundle('ar', 'treatmentPlanPlusTask', ar)
    deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)
  }, [])

  useEffect(() => {
    if (user?.notification_channels?.includes('web push')) {
      enableNotifications()
    }
  }, [user?.notification_channels])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        // if (event.data.type === 'PLAY_NOTIFICATION_SOUND') {
        //   const audio = new Audio(event.data.soundUrl)
        //   audio.volume = 0.2
        //   audio.pause()
        //   audio.currentTime = 0
        //   audio.play()
        //   audio.onended = () => {
        //     audio.pause()
        //     audio.currentTime = 0
        //   }
        // }
        if (event.data.type === 'GET_CURRENT_ROUTE') {
          event.ports[0].postMessage({
            type: 'CURRENT_ROUTE',
            route: window.location.href,
          })
        }
        if (event.data?.type === 'OPEN_NOTIFICATION' && event.data?.notificationState?.notificationId) {
          notificationOpened(event.data?.notificationState?.notificationId)
        }
      })

      // Client should respond with current route
    }
  }, [])

  const { handleInstallApp, isInstalled, open, handleDoNotShow, handleRemindMeLater } = usePWA()
  useContentBundle({
    arabicContent: modalArabic,
    englishContent: modalEnglish,
    key: 'private',
  })

  useContentBundle({
    arabicContent: courseArabic,
    englishContent: courseEnglish,
    key: 'course',
  })
  // useIntercom()

  /**
   * Check if user is logged in and connect to WebSocket
   */
  useEffect(() => {
    if (user.id) {
      const webSocket = new WebSocketService('notifications', '0', user)

      webSocket.connect().then(() => {
        // Register callback after connection
        webSocket.getUnreadCount((counts: { private_messages: number; group_messages: number; total: number }) => {
          dispatch(actions.setUnreadMessagesCount(counts))

          // Only play sound if counts increased from previous value
          if (counts.total > previousCount) {
            const audio = new Audio(notificationSound)
            audio.volume = 0.2
            audio.currentTime = 0
            audio.pause()
            audio.play()
            audio.onended = () => {
              audio.pause()
              audio.currentTime = 0
            }
          }

          // Update localStorage and state with new count
          localStorage.setItem('lastNotificationCount', String(counts))
          setPreviousCount(counts.total)

          refetchChats()
        })
      })

      setWebSocketInstance(webSocket)
    }
  }, [user.id])

  /**
   * Disconnect WebSocket when component unmounts
   */
  useEffect(() => {
    return () => {
      if (webSocketInstance) {
        webSocketInstance.disconnect()
      }
    }
  }, [])
  useEffect(() => {
    dispatch(parentActions.getTherapyInfo(setLoading as any) as any)
  }, [])
  const { allProfiles, assignedAdvisor } = useSelector((state: any) => state?.parent)
  useEffect(() => {
    if (userPurchasedCourses?.results?.length && user.user_type === 'parent' && !allProfiles?.length && !loading) {
      navigate(`/profile/parent/course/${userPurchasedCourses?.results?.[0]?.original_course}`)
    }
  }, [userPurchasedCourses?.results?.length, user.user_type, allProfiles?.length, loading])
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/membership-booking" element={<MembershipBooking />} />
          <Route path="/session" element={<ZoomEmbedded />} />
          <Route path="/feedback" element={<FeedbackForm />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/booking-therapy" element={<BookingTherapy />} />
          <Route path="/session-payment-page" element={<SessionPaymentPage />} />
          <Route path="/payment" element={<Payment />} />
          {/* <Route path="parent-task/:parentTaskId" element={<ParentTaskDetail />} /> */}
          <Route path="profile" element={<Outlet />}>
            {user?.user_type === 'parent' && (
              <Route path="parent" element={<Outlet />}>
                <Route path="therapy" element={<TherapyPage />} />
                <Route path="treatment-plan" element={<ParentTreatmentPlan />} />
                <Route path="parent-task" element={<ParentTaskView />} />
                <Route path="courses" element={<Courses />} />
                <Route path="course/:courseId" element={<ParentCourseDetailView />} />
                <Route path="course/start/:courseId" element={<CoursePage />} />

                <Route path="therapy/add_questionnaire" element={<ParentQuestionnaireForExistingAccount isAccountExists={true} />} />
                <Route path="therapy/:childId/notes" element={<NotesPage />} />
                <Route path="chat/:profileId" element={<ChatPage />} />
                <Route path="additional-info" element={<NewParentOnBoarding />} />
              </Route>
            )}
            {user?.user_type === 'therapist' && (
              <Route path="therapist" element={<Outlet />}>
                <Route path="applications" element={<TherapistApplicationsPage />} />
                <Route path="patients" element={<TherapistPatientsPage />} />
                <Route path="patients/child/:profileId" element={<ChildProfilePage />} />
                <Route path="patients/child/:profileId/notes" element={<NotesPage />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="discovery-call-survey" element={<TherapistSurvey />} />
              </Route>
            )}
          </Route>
          <Route path="admin_panel" element={<Outlet />}>
            {user?.user_type === 'advisor' && (
              <Route path="advisor" element={<Outlet />}>
                <Route path="applications" element={<AdvisorApplicationsPage />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="therapists" element={<AdvisorTherapistsPage />} />
                <Route path="profiles" element={<AdvisorParentsPage />} />
                <Route path="profiles/assign/:profileId" element={<AdvisorAssignTherapistsPage />} />
                <Route path="applications/:profileId" element={<AdvisorParentProfilePage />} />
                <Route path="applications/onboarding_children" element={<QuestionsPage />} />
                <Route path="applications/onboarding_children/summary" element={<SummaryPage />} />
              </Route>
            )}
            {user?.user_type === 'admin' && (
              <Route path="admin" element={<Outlet />}>
                <Route path="advisors" element={<AdvisorsPage />} />
                <Route path="therapists" element={<AdvisorTherapistsPage />} />
                <Route path="parents" element={<ParentsAdminPage />} />
                <Route path="parents/:childId" element={<AdminParentProfilePage />} />
                <Route path="parents/:childId/notes" element={<NotesPage />} />
                <Route path="chat" element={<ChatPage />} />
                <Route path="analytics" element={<AdminAnalytics />} />
                <Route path="meetings" element={<AdminMeetingsPage />} />
                <Route path="book-on-behalf/:id" element={<BookOnBehalf />} />
              </Route>
            )}
            {/* {user?.user?.user_type === 'supervisor' && <Route path="admin/parents" element={<ParentsPage />} />}
            {user?.user?.user_type === 'supervisor' && (
              <>
                <Route path="children/therapist_info" element={<ChildrenHistoryTherapistDetailsPage />} />
                <Route path="children/history" element={<ChildViewHistoryPage />} />
              </>
            )}
            {user?.user?.user_type === 'supervisor' && <Route path="advisors" element={<AdvisorsPage />} />} */}
          </Route>
        </Route>
        <Route path="*" element={<Error404Page />} />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/payment-response" element={<PaymentSuccessOrErrorPage />} />
      </Routes>
      <Modal open={open} onClose={handleRemindMeLater}>
        <CenterColumn gap={20} variant="modal-container">
          <CenterRow justifyContent="space-between" width="100%">
            <Text variant="heading3">{t('private:installApp')}</Text>
            <IconButton onClick={handleRemindMeLater}>
              <CloseIcon />
            </IconButton>
          </CenterRow>
          {isInstalled ? (
            <CenterColumn gap={[16]} width="100%" alignItems="center">
              <Text>{t('private:installationGuide')}</Text>
              <video
                loop
                autoPlay
                style={{
                  maxHeight: 300,

                  borderRadius: 10,
                }}
                src="/Install Guide.mp4"
              />
            </CenterColumn>
          ) : (
            <CenterColumn p={[10]} width="100%" gap={[10]} alignItems="center">
              <Box height={72}>
                <Image src="/logo72.png" />
              </Box>
              <Text textAlign={['center']} fontSize={[16, 20]}>
                {t('private:installationGuideDescription')}
              </Text>
              <Button mt={[3]} width={['100%', '100%', '60%', '50%', '30%']} onClick={handleInstallApp}>
                {t('private:install')}
              </Button>
              <Button variant="secondary" width={['100%', '100%', '60%', '50%', '30%']} onClick={handleRemindMeLater}>
                {t('private:remindMeLater')}
              </Button>
              <Button variant="ghost" width={['100%', '100%', '60%', '50%', '30%']} onClick={handleDoNotShow}>
                {t('private:doNotShowAgain')}
              </Button>
            </CenterColumn>
          )}
        </CenterColumn>
      </Modal>
    </AuthProvider>
  )
}
