import { Modal } from '@mui/material'
import { Box, Container } from 'components/Core'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'utils/types/types'
import { MembershipCard } from './MembershipCard'

interface MembershipInfoModalProps {
  open: boolean
  onClose: () => void
  onProceedToPayment: () => void
  subscription: Subscription | undefined
}

export const MembershipInfoModal = ({ open, onClose, onProceedToPayment, subscription }: MembershipInfoModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <Container height="100%">
        <MembershipCard onClose={onClose} onProceedToPayment={onProceedToPayment} subscription={subscription} />
      </Container>
    </Modal>
  )
}
