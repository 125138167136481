// Core
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Turnstile from 'react-turnstile'
import { useNavigate } from 'react-router-dom'
import { authActions } from '../../../actions'
import { clearError } from '../../../reducers/generalSlice'
import { setUser } from '../../../reducers/authSlice'
import Styles from './styles.module.scss'
import { TextField, TextFieldPassword, Loader } from '../../GeneralComponents'
import { Box, Button, CenterColumn, CenterRow, Text } from 'components/Core'
import { selectIsUserLoggingIn } from 'defaultLayout/slice/selectors'
import { AuthWithGoogle } from 'components/ParentFlow/Therapy/NewParentOnboarding/AuthWithGoogle'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { useContentBundle } from 'hooks/useContentBundle'
import en from './locale/en.json'
import ar from './locale/ar.json'
const schema = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t('login:emailIsRequired'))
      .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, t('login:enterValidEmailAddress')),
    password: Yup.string().required(t('login:enterPassword')),
  })

export const SignIn = () => {
  const { t } = useContentBundle({
    englishContent: en,
    arabicContent: ar,
    key: 'login',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { direction } = useGetLanguage()
  const loading = useSelector(selectIsUserLoggingIn)

  const error = useSelector((state) => state.general.error)
  const user = useSelector((state) => state.auth.user)

  const isLocalDevelopment = process.env.NODE_ENV === 'development'

  const [captcha, setCaptcha] = useState(isLocalDevelopment)

  const getCloudFlare = () => {
    return (
      <Turnstile
        sitekey={process.env.REACT_APP_SITE_KEY}
        onError={() => setCaptcha(false)}
        onVerify={(token) => token && setCaptcha(true)}
        theme={'light'}
        className={Styles.captcha}
      />
    )
  }

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema(t)),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [user?.email])

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const onFormSubmit = (data) => {
    Object.assign(data, { timeZone: userTimeZone })

    dispatch(authActions.loginUser(data))
  }

  const navigateToSignUp = () => {
    dispatch(clearError())
    navigate('/signup/client')
  }

  const navigateToForgotPass = () => {
    const email = getValues('email')
    dispatch(clearError())
    dispatch(setUser({ email }))
    navigate('/pass-recovery')
  }

  const handleAuthenticationAction = () => {
    const email = getValues('email')
    dispatch(setUser({ email }))
    dispatch(clearError())
    navigate('authentication-link')
  }

  return (
    <section
      style={{
        direction,
      }}
      className={Styles.signIn_form}
    >
      <div className={Styles.form_container}>
        {error && (
          <div className={Styles.signIn_error}>
            <span />
            <span>{error}</span>
          </div>
        )}
        <Box mb={16}>
          <Text color="typePurpure.300" textAlign="center" variant="heading2" maxWidth="100%">
            {t('login:logIn')}
          </Text>
        </Box>
        {/* <div className={Styles.signIn_link_block} style={{ marginBottom: 32, marginTop: 12 }}>
          <span className={Styles.link_text}>
            You can also sign in with{' '}
            <Button display="inline" type="button" variant="ghost" className={Styles.link} onClick={handleAuthenticationAction}>
              one-time authentication link
            </Button>
          </span>
        </div> */}
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit(onFormSubmit)()
          }}
        >
          <CenterColumn gap={8}>
            <CenterColumn gap={16} mt={10}>
              <AuthWithGoogle color="black" label={t('login:signInWithGoogle')} />
              <CenterRow justifyContent={['center']} width="100%">
                <Text color="textLightGray">{t('login:or')}</Text>
              </CenterRow>
            </CenterColumn>
            <CenterColumn>
              <TextField
                label={t('login:email')}
                requiredIcon={' *'}
                class={Styles.form_item}
                placeholder={'youremail@gmail.com'}
                error={errors?.email}
                register={register('email')}
                direction={'ltr'}
                type="email"
              />
              <TextFieldPassword
                label={t('login:password')}
                requiredIcon={' *'}
                class={Styles.password_item}
                placeholder={t('login:enterYourPassword')}
                error={errors?.password}
                register={register('password')}
                direction={direction}
              />
              <Button width={['fit-content']} type="button" justifySelf="flex-end" variant="ghost" mb="10px" fontWeight="500" onClick={navigateToForgotPass}>
                {t('login:forgotPassword')}
              </Button>
              {!isLocalDevelopment && getCloudFlare()}
              <Button borderRadius={10} type="submit" disabled={!isValid || !captcha} width="100%" height="50px">
                {loading ? <Loader /> : t('login:logIn')}
              </Button>
            </CenterColumn>
            <CenterColumn gap={8}>
              <Box gap="5px" className={Styles.clarification}>
                <span>{t('login:notAMember')}</span>
                <Box mr={2} className={Styles.account_btn} onClick={navigateToSignUp}>
                  {t('login:createAccount')}
                </Box>
              </Box>
            </CenterColumn>
          </CenterColumn>
        </form>
      </div>
    </section>
  )
}
