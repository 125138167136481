import { Skeleton, Stack } from '@mui/material'
import { apiRoutes } from 'apiRoutes'
import { Button, CenterColumn, CenterRow, Container, Text } from 'components/Core'
import { CountdownComponent } from 'components/GeneralComponents'
import { useGetLatestPendingOrderQuery } from 'defaultLayout/slice/service'
import { useAuth } from 'hooks/useAuth'
import { useDisclosure } from 'hooks/useDisclosure'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { t } from 'i18next'
import { MacOSPayment, PaymentPage } from 'pages/PaymentPages'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isSafariBrowser } from 'utils'
import { v4 as uuid4 } from 'uuid'
import { OrderDetail } from 'utils/types/types'

export const SessionPaymentPage = () => {
  const { direction } = useGetLanguage()

  const [searchParams] = useSearchParams()
  const [orderDetails, setOrderDetails] = useState<Partial<OrderDetail>>({})
  const therapist_id = searchParams.get('therapistId')
  const { user } = useAuth()
  const { data, isLoading } = useGetLatestPendingOrderQuery(
    { user_id: user?.id, therapist_id },
    {
      skip: !user?.id || !therapist_id,
    },
  )
  const regularPaymentController = useDisclosure()
  const safariPaymentController = useDisclosure()
  const startPayment = useCallback(() => {
    if (data?.id && user?.id) {
      setOrderDetails({
        ...data,
        full_name: user.full_name,
        phone_number: user?.phone_number,
        email: user.email,
        description: 'Session Payment',
        order_id: data?.id,
        transaction_id: uuid4(),
        user_id: user.id,
      })
      if (isSafariBrowser()) {
        safariPaymentController.onOpen()
      } else {
        regularPaymentController.onOpen()
      }
    }
  }, [data, user])
  useEffect(() => {
    startPayment()
  }, [startPayment])
  const navigate = useNavigate()
  return (
    <Container
      gap={24}
      direction={direction}
      width="100%"
      minHeight="90vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        background: '#F7FAFC',
        padding: '40px 20px',
      }}
    >
      {isLoading ? (
        <LoadingSkeleton />
      ) : !data?.id ? (
        <NoOrderFallback />
      ) : (
        <>
          <CenterRow gap={2}>
            <Text variant="body1" color="gray.500">
              {t('global:paymentExpireIn')}
            </Text>
            <CountdownComponent
              creationTimestamp={data.created_at}
              onComplete={() => {
                navigate('/')
              }}
            />
          </CenterRow>
          <Text
            maxWidth={600}
            textAlign="center"
            variant="heading2"
            style={{
              fontSize: '32px',
              fontWeight: 600,
              color: '#1A202C',
            }}
          >
            {t('global:sessionBookedSuccessfully')}
          </Text>

          <Button onClick={startPayment}>{t('global:completePayment')}</Button>
          <Button variant="ghost" onClick={() => navigate('/')}>
            {t('global:membership:backToDashboard')}
          </Button>

          {regularPaymentController.open && (
            <PaymentPage
              webhookUrl={apiRoutes.payment.webhookUrl}
              orderDetails={orderDetails}
              isOpen={regularPaymentController.open}
              closePopup={regularPaymentController.onClose}
              openPaymentExpiresPopup={() => {}}
              showTopLeftHeader={false}
              redirectUrl={process.env.REACT_APP_SITE_URL}
            />
          )}

          <MacOSPayment
            webhookUrl={apiRoutes.payment.webhookUrl}
            orderDetails={orderDetails}
            open={safariPaymentController.open}
            onClose={safariPaymentController.onClose}
            isOpenPaymentPopup={regularPaymentController.open}
            setIsOpenPaymentPopup={regularPaymentController.onOpen}
            redirectUrl={process.env.REACT_APP_SITE_URL}
            openPaymentExpiresPopup={() => {}}
          />
        </>
      )}
    </Container>
  )
}

const NoOrderFallback = () => {
  const navigate = useNavigate()
  return (
    <Stack spacing={3} width="100%" maxWidth={600} alignItems="center">
      <Text
        textAlign="center"
        variant="heading2"
        style={{
          fontSize: '24px',
          color: '#4A5568',
        }}
      >
        {t('global:noOrderFound')}
      </Text>
      <Button
        onClick={() => navigate('/')}
        style={{
          padding: '12px 24px',
        }}
      >
        {t('global:goBack')}
      </Button>
    </Stack>
  )
}

const LoadingSkeleton = () => (
  <Stack spacing={3} width="100%" maxWidth={600} alignItems="center">
    <Skeleton variant="rectangular" width="80%" height={40} />
    <Skeleton variant="rectangular" width="60%" height={50} />
  </Stack>
)
